import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import image_loader from "../assets/images/minoto-loading.gif";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";


function Redirect() {
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    async function postFingerPrint(result){
        const endpoint = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://api.minoto.com"
        const postUrl = endpoint+ `/v1/shared/history?language=`  + i18next.resolvedLanguage  ;
        await axios.post(postUrl,{fingerPrintId:result.visitorId,carPostId:query.get('id')})
    }

    let query = useQuery();

    const [data,setData] = useState(null);
    const [redirected,setRedirected] = useState(false);

    const getDealerInformations = async () => {
        const endpoint = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://api.minoto.com/"
        const url = endpoint + "/v1/shared/car-post/"+query.get('postNo')+`?language=`+i18next.resolvedLanguage
        const response = await axios.get(url)
        const data = await response.data.payload
        setData(data)
        // Get the visitor identifier
        FingerprintJS.load()
            .then(fp => fp.get())
            .then(result => {
                postFingerPrint(result)
            })

        setTimeout(()=>{
            window.location.replace(data.outsourcingUrl)
            setRedirected(true)
        },2000)
    }

    const redirectManual = () => {
        window.location.replace(data.outsourcingUrl)
        setRedirected(true)
    }

    useEffect (()=>{
        getDealerInformations()
    },[])




    return (
        <div style={{height:"90vh",display:'flex',alignItems:"center",justifyContent:'center',flexDirection:"column"}}>
            {

                <>

                    {
                        (!redirected && data) &&(
                           <>
                               <div style={{display:"flex",alignItems:"center",gap:"5rem"}}>
                                   <h1 style={{color:'#145aff'}}>{i18next.t("pages.redirect.text.0")}</h1><img width="50" src={image_loader} alt={i18next.t("pages.redirect.text.0")} style={{ display: 'inline' }} />
                               </div>
                               <div style={{marginTop:"3%"}}>
                              {/*     <img width="200" src={data.dealer.logo} alt="" style={{ display: 'inline' }} />*/}
                               </div>
                           </>
                        )
                    }
                    {
                        (data && redirected) && (
                            <>
                                <div style={{marginTop:"3%"}}>
                                    <img width="200" src={data.dealer.logo} alt="" style={{ display: 'inline' }} />
                                </div>
                                <div style={{marginTop:"3%",textAlign:"center"}}>
                                    <h2>
                                        {i18next.t("pages.redirect.text.1")} <p id="click-me" onClick={redirectManual} rel="noopener nofollow" style={{cursor:"pointer" ,color:'#145aff',textDecoration:"underline"}}> {i18next.t("pages.redirect.text.2")}</p>
                                    </h2>
                                </div>
                            </>
                        )
                    }
                </>
            }
        </div>
    )
}
export default withTranslation()(Redirect);
