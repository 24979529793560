import React from 'react';

//Partials
import Link from "components/partials/link";

//Deps
import CookieConsent from "react-cookie-consent";


// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";




class ConsentBar extends React.Component {
	render() {
		const { t } = this.props;
		return (
			<CookieConsent
				buttonText={t("partials.consentBar.button")}
				containerClasses="consentbar"
				contentClasses="consentbar-textwrap"
				buttonClasses="btn wide low"
				cookieName="minoto-consent"
				disableStyles={true}
				expires={150}
			>
				{t("partials.consentBar.text.0")}<strong>{t("partials.consentBar.text.1")}</strong>{t("partials.consentBar.text.2")}<strong>{t("partials.consentBar.text.3")}</strong>'{t("partials.consentBar.text.4")} <Link href="gdprPolicy" className="link" hash="cerez-politikasi">{t("partials.consentBar.text.5")}</Link> {t("partials.consentBar.text.6")}
			</CookieConsent>
		)
	}
}
export default (withTranslation()(ConsentBar))