import React from "react";
// Partials
import {FormInput, InputForm} from "../forms";
import Btn from "../btn";
//Functions
import {openModal} from "../../../functions/modals"
import {formatNumber} from "../../../functions/helpers";
import {storageSpace} from "../../../functions";
// Multi language

import "../../../i18n";

export class DetailCredit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            garantiInterest: "1.23",
            garantiInstallment: false,
            isbankInterest: "1.10",
            isbankInstallment: false,
            productPrice: this.props.product.price ? this.props.product.price : 500000,
            loading: false,
            error: false,
            amount: false,
            credit: this.props.product.initialCredit ? this.props.initialCredit : 500000,
            month: this.props.product.initialMonth ? this.props.product.initialMonth : 36,
        };
        this.calculateInstallments = this.calculateInstallments.bind(this);
        this.calculateMaxCredit = this.calculateMaxCredit.bind(this);
        this.calculateMaxTerm = this.calculateMaxTerm.bind(this);
    }


    calculateMaxTerm() {
        const price = this.props.product.price;
        const rateChangeLimit = 120000;
        // If product price lower than 120.000 TL maxTerm = 60  else maxTerm = 48
        let maxTerm;
        maxTerm = parseInt(price, 10) < rateChangeLimit ? 60 : 48;

        return maxTerm
    }

    calculateMaxCredit() {
        const price = this.props.product.price;
        // When price lower than 120.0000 TL multiply by .70 else .50
        const rateChangeLimit = 120000;
        let maxCreditForPrice;

        if (price < rateChangeLimit) {
            maxCreditForPrice = price * 0.70;
        } else {
            let topCredit = rateChangeLimit * 0.70;
            let subCredit = (parseInt(price, 10) - rateChangeLimit) * 0.50;

            maxCreditForPrice = topCredit + subCredit;
        }
        return maxCreditForPrice;
    }

    calculateInstallments() {
        this.setState({loading: true, error: false});
        // General rate limit for credit up top 120.000 tl rate 50% otherwise %70
        //let credit = document.getElementById('creditAmount').value.replace('.', '');
        /*   let month = document.getElementById('creditDuration').value;
           let maxCredit = this.calculateMaxCredit();
           let maxTerm = this.calculateMaxTerm();*/

        this.setState({
            loading: false, error: false,
            /* //credit: credit && null,
             month: month,
             maxCredit: maxCredit,
             maxTerm: maxTerm,*/
            //amount: formatNumber(Math.round(credit * 100) / 100, { showDecimals: false }),
        });
    }


    componentDidMount() {
        this.calculateInstallments();
    }

    render() {
        const {t} = this.props;
        const {carCredits, banksInterests} = this.props.product;
        const carInfo = null;
        const {month, credit} = this.state
        let vm = this;

        return (
            <>
                {carInfo && <div className="detail-info">
                    <div className="info-credit-calculation">
                        <h2>{t("partials.detail.detailCredit.title")}</h2>
                        <p>{t("partials.detail.detailCredit.subtitle")}</p>
                        <InputForm className="section contentpage-form grid-container"
                                   onSubmit={this.calculateInstallments}>
                            <div className="grid-row">
                                <div className="grid-col x5 m-x6">
                                    <FormInput
                                        id="creditAmount"
                                        placeholder={t("partials.detail.detailCredit.formItems.0.placeholder")}
                                        className="credit-price currency-after"
                                        value={parseInt(carCredits.initialCredit).toString()}
                                        validation={{
                                            required: t("partials.detail.detailCredit.formItems.0.validations.required"),
                                            minNum: [t("partials.detail.detailCredit.formItems.0.validations.minNum"), 5000],
                                            maxNum: [`${t("partials.detail.detailCredit.formItems.0.validations.maxNum")} ${formatNumber(Math.round(parseInt(carCredits.maxCreditForPrice) * 100) / 100, {showDecimals: false})} ${t("partials.detail.detailCredit.formItems.0.validations.maxNum2")}`, parseInt(carCredits.maxCreditForPrice)],
                                        }}
                                        name="credit_amount"
                                        mask="1++++++++++++++"
                                        disabled={vm.state.loading}
                                        formatNumber
                                        type="number"/>
                                </div>
                                <div className="grid-col x3 m-x6 no-padding">
                                    <FormInput
                                        id="creditDuration"
                                        placeholder={t("partials.detail.detailCredit.formItems.1.placeholder")}
                                        className="credit-price month-after"
                                        value="36"
                                        validation={{
                                            required: t("partials.detail.detailCredit.formItems.0.validations.required"),
                                            minNum: [t("partials.detail.detailCredit.formItems.0.validations.minNum"), 12],
                                            maxNum: [`${t("partials.detail.detailCredit.formItems.0.validations.maxNum")} ${carCredits.maxTerm} ${t("partials.detail.detailCredit.formItems.0.validations.maxNum2")}`, carCredits.maxTerm],
                                        }}
                                        name="credit_duration"
                                        mask="1+"
                                        disabled={vm.state.loading}
                                        formatNumber
                                        type="number"/>
                                </div>
                                <div className="grid-col x4 m-x12 center">
                                    <Btn
                                        type="submit"
                                        uppercase
                                        block
                                        disabled={vm.state.loading}
                                        status={this.state.submitting && 'loading'}
                                        //onClick={() => {  }}
                                        className="form-submitbtn">
                                        {t("partials.detail.detailCredit.formItems.button")}
                                    </Btn>
                                </div>
                            </div>
                        </InputForm>
                    </div>
                    <div className="info-credit-results">
                        <table className="table listprices-table">
                            <thead>
                            <tr>
                                <th>     {t("partials.detail.detailCredit.formItems.heads.0")}</th>
                                <th>
                                    <div
                                        className="tablePad">{t("partials.detail.detailCredit.formItems.heads.1")}</div>
                                </th>
                                <th>{t("partials.detail.detailCredit.formItems.heads.2")}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {banksInterests.map((bank, nth) => {
                                const interest = bank.rate / 100 * 1.2;

                                let installments = credit * (interest * Math.pow((1 + interest), month)) / (Math.pow(1 + interest, month) - 1);
                                installments = formatNumber(Math.round(installments * 100) / 100, {showDecimals: true});
                                return (
                                    <tr key={nth}>
                                        <td>{installments} TL</td>
                                        <td>
                                            <div className="tablePad">%{bank.rate.toString().replace('.', ',')}</div>
                                        </td>
                                        <td><img src={storageSpace('c_scale,q_auto:good,w_400/library', bank.image)}
                                                 alt="" width="121"/></td>
                                        <td>
                                            <Btn
                                                type="submit"
                                                uppercase
                                                block
                                                disabled={this.state.submitting}
                                                status={this.state.submitting && 'loading'}
                                                onClick={() => openModal('credit', {
                                                    advert: vm.props.product,
                                                    installment: installments,
                                                    interest: bank.rate,
                                                    month: month,
                                                    amount: credit,
                                                    type: bank.name,
                                                    banksInterest: bank,
                                                })}
                                                className="form-submitbtn">
                                                {vm.props.mobile ? (<i className="icon-new-tab"/>) :
                                                    <i className="icon-new-tab"></i>}
                                            </Btn>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </>
        )
    }
}
