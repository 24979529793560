import i18next from "i18next";

export  const routes =  {
	pages: {
		posts: {
			path:`/:locale(tr|de)?${i18next.t('routes:posts')}`,
			component: "Posts",
			exact: true,
			linkTitle: "routes.pages.posts.linkTitle",
			title: "routes.pages.posts.title",
			description: "routes.pages.posts.title",
			postTitle: false,
			meta: {
				keywords: "routes.pages.posts.meta.keywords"
			},
			GATitle: "routes.pages.posts.gaTitle",
			// Bu şekilde head kısmına custom meta da eklenebilir.
			/*head: [
				{
					key: "meta",
					content: false,
					props: {
						name: "description",
						content: "Minoto, Sıfır Otomobilin Yeni Adresi"
					}
				},
				{
					key: "description",
					content: "Minoto, Sıfır Otomobilin Yeni Adresi",
					props: {}
				}
			],*/
			hideSearchFromHeader: true,

		},
		search: {
			path:`/:locale(tr|de)?${i18next.t('routes:search')}`,
			component: "Search",
			exact: true,
			linkTitle: "routes.pages.search.linkTitle",
			title: "routes.pages.search.title",
			GATitle: "routes.pages.search.gaTitle",
			hideSiteMap: true,
		},
		/*searchBrand: {
			path: "/arama/:brand",
			component: "SearchBrand",
			exact: true,
			linkTitle: "Arama",
			title: "Arama",
			GATitle: "Marka Arama",
		},
		searchModel: {
			path: "/arama/:brand/:model",
			component: "SearchModel",
			exact: true,
			linkTitle: "Arama",
			title: "Arama",
			GATitle: "Marka Arama",
		},
		searchModelSub: {
			path: "/arama/:brand/:model/:modelsub",
			component: "SearchModelSub",
			exact: true,
			linkTitle: "Arama",
			title: "Arama",
			GATitle: "Marka Arama",
		},
		searchVersion: {
			path: "/arama/:brand/:model/:modelsub/:version",
			component: "SearchVersion",
			exact: true,
			linkTitle: "Arama",
			title: "Arama",
			GATitle: "Marka Arama",
		},*/
		/* sitemap: {
			path: "/sitemap",
			component: "Sitemap",
			exact: true,
			linkTitle: "Site Haritası",
			title: "Site Haritası",
			GATitle: "Site Haritası",
		},*/
		brands: {
			path:`/:locale(tr|de)?${i18next.t('routes:brands')}`,
			component: "Brands",
			exact: true,
			linkTitle: "routes.pages.brands.linkTitle",
			title: "routes.pages.brands.title",
			description: "routes.pages.brands.title",
			postTitle: false,
			meta: {
				keywords: "routes.pages.brands.meta.keywords"
			},
			GATitle: "routes.pages.brands.gaTitle",
		},
		brand: {
			path:`/:locale(tr|de)?${i18next.t('routes:brandComponent')}`,
			component: "Brand",
			exact: true,
			linkTitle: "routes.pages.brand.linkTitle",
			title: "routes.pages.brand.title",
			description: "routes.pages.brand.title",
			postTitle: false,
			GATitle: "routes.pages.brand.gaTitle",
		},
		dealer: {
			path:`/:locale(tr|de)?${i18next.t('routes:dealer')}`,
			component: "Dealer",
			exact: true,
			linkTitle: "routes.pages.dealer.linkTitle",
			title: "routes.pages.dealer.title",
			description: "routes.pages.dealer.title",
			postTitle: false,
			GATitle: "routes.pages.dealer.gaTitle",
		},
		dealers: {
			path:`/:locale(tr|de)?${i18next.t('routes:dealers')}`,
			component: "Dealers",
			exact: true,
			linkTitle: "routes.pages.dealers.linkTitle",
			title: "routes.pages.dealers.title",
			description: "routes.pages.dealers.title",
			GATitle: "routes.pages.dealers.gaTitle",
		},
		dealerApplication: {
			path:`/:locale(tr|de)?${i18next.t('routes:dealerApplication')}`,
			component: "DealerApplication",
			exact: true,
			linkTitle: "routes.pages.dealerApplication.linkTitle",
			title: "routes.pages.dealerApplication.title",
		},
		dealerApplicationStatus: {
			path:`/:locale(tr|de)?${i18next.t('routes:dealerApplicationStatus')}`,
			component: "DealerApplicationStatus",
			exact: true,
			linkTitle: "routes.pages.dealerApplicationStatus.linkTitle",
			title: "routes.pages.dealerApplicationStatus.title",
		},
		branch: {
			path:`/:locale(tr|de)?${i18next.t('routes:branch')}`,
			component: "Branch",
			exact: true,
			linkTitle: "routes.pages.branch.linkTitle",
			title: "routes.pages.branch.title",
			GATitle: "routes.pages.branch.gaTitle",
		},
		listPrices: {
			path:`/:locale(tr|de)?${i18next.t('routes:listPrices')}`,
			component: "ListPrices",
			exact: true,
			linkTitle: "routes.pages.listprices.linkTitle",
			title: "routes.pages.listprices.title",
			description: "routes.pages.listprices.description",
			postTitle: false,
			GATitle: "routes.pages.listprices.gaTitle",
		},
		listPricesDetail: {
			path:`/:locale(tr|de)?${i18next.t('routes:listPriesDetail')}`,
			component: "ListPrices",
			exact: true,
			linkTitle: "routes.pages.listpricesDetail.linkTitle",
			title: "routes.pages.listpricesDetail.title",
			description: "routes.pages.listpricesDetail.description",
			postTitle: false,
			GATitle: "routes.pages.listpricesDetail.gaTitle",
		},
		detail: {
			path:`/:locale(tr|de)?${i18next.t('routes:detail')}`,
			component: "Detail",
			exact: true,
			linkTitle: "routes.pages.detail.linkTitle",
			title: "routes.pages.detail.title",
			head: [
				{
					key: "meta",
					props: {
						property: "og:type",
						content: "product"
					}
				}
			],
			GATitle: "routes.pages.detail.gaTitle",
		},
		comparison: {
			path:`/:locale(tr|de)?${i18next.t('routes:comparison')}`,
			component: "Comparison",
			exact: true,
			linkTitle: "routes.pages.comparison.linkTitle",
			title: "routes.pages.comparison.title",
			GATitle: "routes.pages.comparison.gaTitle",
		},
		adCompare: {
			path:`/:locale(tr|de)?${i18next.t('routes:adCompare')}`,
			component: "AdCompare",
			exact: true,
			linkTitle: "routes.pages.adCompare.linkTitle",
			title: "routes.pages.adCompare.title",
			GATitle: "routes.pages.adCompare.gaTitle",
		},
		about: {
			path:`/:locale(tr|de)?${i18next.t('routes:about')}`,
			component: "About",
			exact: true,
			linkTitle: "routes.pages.about.linkTitle",
			title: "routes.pages.about.title",
			GATitle: "routes.pages.about.gaTitle",
		},
		account: {
			path:`/:locale(tr|de)?${i18next.t('routes:account')}`,
			component: "Account",
			exact: true,
			linkTitle: "routes.pages.account.linkTitle",
			title: "routes.pages.account.title",
			childRoutes: "account",
			GATitle: "routes.pages.account.gaTitle"
		},
		reservation: {
			path:`/:locale(tr|de)?${i18next.t('routes:reservation')}`,
			component: "Reservation",
			exact: true,
			linkTitle: "routes.pages.reservation.linkTitle",
			title: "routes.pages.reservation.title",
			childRoutes: "account",
			GATitle: "routes.pages.reservation.gaTitle"
		},
		faq: {
			path:`/:locale(tr|de)?${i18next.t('routes:faq')}`,
			component: "Faq",
			exact: true,
			linkTitle: "routes.pages.faq.linkTitle",
			title: "routes.pages.faq.title",
			GATitle: "routes.pages.faq.gaTitle"
		},
		otv: {
			path:`/:locale(tr|de)?${i18next.t('routes:otv')}`,
			component: "Otv",
			exact: true,
			linkTitle: "routes.pages.otv.linkTitle",
			title: "routes.pages.otv.title",
			GATitle: "routes.pages.otv.gaTitle"
		},
		privacy: {
			path:`/:locale(tr|de)?${i18next.t('routes:privacy')}`,
			component: "Privacy",
			exact: true,
			linkTitle: "routes.pages.privacy.linkTitle",
			title: "routes.pages.privacy.title",
			GATitle: "routes.pages.privacy.gaTitle"
		},
		gdprPolicy: {
			path:`/:locale(tr|de)?${i18next.t('routes:gdprPolicy')}`,
			component: "GdprPolicy",
			exact: true,
			linkTitle: "routes.pages.gdprPolicy.linkTitle",
			title: "routes.pages.gdprPolicy.title",
			GATitle: "routes.pages.gdprPolicy.gaTitle"
		},
		contact: {
			path:`/:locale(tr|de)?${i18next.t('routes:contact')}`,
			component: "Contact",
			exact: true,
			linkTitle: "routes.pages.contact.linkTitle",
			title: "routes.pages.contact.title",
			GATitle: "routes.pages.contact.gaTitle"
		},
		blogSearch: {
			path:`/:locale(tr|de)?${i18next.t('routes:blogSearch')}`,
			component: "Blog",
			exact: true,
			linkTitle: "routes.pages.blogSearch.linkTitle",
			title: "routes.pages.blogSearch.title",
		},
		blogRedirect: {
			path:`/:locale(tr|de)?${i18next.t('routes:blogRedirect')}`,
			component: "BlogRedirect",
			exact: true,
			linkTitle: "routes.pages.blogRedirect.linkTitle",
			title: "routes.pages.blogRedirect.title",
			GATitle: "routes.pages.blogRedirect.gaTitle"
		},
		blogDetail: {
			path:`/:locale(tr|de)?${i18next.t('routes:blogDetail')}`,
			component: "BlogDetail",
			exact: true,
			linkTitle: "routes.pages.blogDetail.linkTitle",
			title: "routes.pages.blogDetail.title",
			GATitle: "routes.pages.blogDetail.gaTitle"
		},
		blog: {
			path:`/:locale(tr|de)?${i18next.t('routes:blogComponent')}`,
			component: "Blog",
			exact: false,
			linkTitle:i18next.t("routes.pages.blog.linkTitle"),
			title: "routes.pages.blog.title",
			GATitle: "routes.pages.blog.gaTitle"
		},
		/*searchBrand: {
		dealerApplication: {
			path: "/bayi-basvuru-formu",
			component: "DealerApplication",
			exact: true,
			linkTitle: "Bayi Başvuru Formu",
			title: "Bayi Başvuru Formu",
			GATitle: "Bayi Başvuru Formu",
		},
		dealerApplicationStatus: {
			path: "/bayi-basvuru-durumu/:applicationNo",
			component: "DealerApplicationStatus",
			exact: true,
			linkTitle: "Bayi Başvuru Durumu",
			title: "Bayi Başvuru Durumu",
			GATitle: "Bayi Başvuru Durumu",
		},
		searchBrand: {
			path: "/:brand/:model?/:optdata1?/:optdata2?/:optdata3?",
			component: "SearchBrand",
			exact: false,
			linkTitle: "Arama",
			title: "Arama",
		},*/
		home: {
			path:`/:locale(tr|de)?${i18next.t('routes:home')}`,
			component: "Home",
			exact: true,
			linkTitle: "routes.pages.home.linkTitle",
			title: "routes.pages.home.title",
			postTitle: false,
			description: "routes.pages.home.title",
			meta: {
				keywords: "routes.pages.home.meta.keywords"
			},
			GATitle: "routes.pages.home.gaTitle",

			// Bu şekilde head kısmına custom meta da eklenebilir.
			/*head: [
				{
					key: "meta",
					content: false,
					props: {
						name: "description",
						content: "Minoto, Sıfır Otomobilin Yeni Adresi"
					}
				},
				{
					key: "description",
					content: "Minoto, Sıfır Otomobilin Yeni Adresi",
					props: {}
				}
			],*/
			hideSearchFromHeader: false,
		},
		notfound: {
			path: false,
			component: "NotFound",
			exact: false,
			linkTitle: "routes.pages.notfound.linkTitle",
			title: "routes.pages.notfound.title",
			GATitle: "routes.pages.notfound.gaTitle",
		}
	},
	account: {
		profile: {
			path:`/:locale(tr|de)?${i18next.t('routes:profile')}`,
			component: "Profile",
			exact: true,
			requiresLogin: true,
			linkTitle: "routes.pages.profile.linkTitle",
			title: "routes.pages.profile.title",
			GATitle: "routes.pages.profile.gaTitle",
		},
		notifications: {
			path:`/:locale(tr|de)?${i18next.t('routes:notifications')}`,
			component: "Notifications",
			exact: true,
			linkTitle: "routes.pages.notifications.linkTitle",
			title: "routes.pages.notifications.title",
			requiresLogin: true,
			GATitle: "routes.pages.notifications.gaTitle",
		},
		favorites: {
			path:`/:locale(tr|de)?${i18next.t('routes:favorites')}`,
			accountPath: i18next.t("routes.pages.favorites.accountPath"),
			component: "Favorites",
			exact: false,
			linkTitle: "routes.pages.favorites.linkTitle",
			title: "routes.pages.favorites.title",
			requiresLogin: true,
			GATitle: "routes.pages.favorites.gaTitle",
		},
		messages: {
			path:`/:locale(tr|de)?${i18next.t('routes:messages')}`,
			component: "Messages",
			exact: true,
			linkTitle: "routes.pages.messages.linkTitle",
			title: "routes.pages.messages.title",
			requiresLogin: true,
			GATitle: "routes.pages.messages.gaTitle",
		},
		messageDetail: {
			path:`/:locale(tr|de)?${i18next.t('routes:messageDetail')}`,
			component: "MessageConversation",
			exact: true,
			linkTitle: "routes.pages.messageDetail.linkTitle",
			title: "routes.pages.messageDetail.title",
			requiresLogin: true,
			GATitle: "routes.pages.messageDetail.gaTitle",
		},
		reservations: {
			path:`/:locale(tr|de)?${i18next.t('routes:reservations')}`,
			component: "Reservations",
			exact: true,
			linkTitle: "routes.pages.reservations.linkTitle",
			title: "routes.pages.reservations.title",
			requiresLogin: true,
			GATitle: "routes.pages.reservations.gaTitle",

		},
		login: {
			path:`/:locale(tr|de)?${i18next.t('routes:login')}`,
			component: "Login",
			exact: true,
			linkTitle: "routes.pages.login.linkTitle",
			title: "routes.pages.login.title",
			requiresLogin: false,
			GATitle: "routes.pages.login.gaTitle",
		},
		register: {
			path:`/:locale(tr|de)?${i18next.t('routes:register')}`,
			component: "Register",
			exact: true,
			linkTitle: "routes.pages.register.linkTitle",
			title: "routes.pages.register.title",
			requiresLogin: false,
			GATitle: "routes.pages.register.gaTitle",
		},
		recovery: {
			path:`/:locale(tr|de)?${i18next.t('routes:recovery')}`,
			component: "Recovery",
			exact: true,
			linkTitle: "routes.pages.recovery.linkTitle",
			title: "routes.pages.recovery.title",
			requiresLogin: false,
			GATitle: "routes.pages.recovery.gaTitle",
		},
		confirmEmail: {
			path:`/:locale(tr|de)?${i18next.t('routes:confirmEmail')}`,
			component: "ConfirmEmail",
			exact: true,
			linkTitle: "routes.pages.confirmEmail.linkTitle",
			title: "routes.pages.confirmEmail.title",
			requiresLogin: false,
			GATitle: "routes.pages.confirmEmail.gaTitle",
		}
	},
	reservation: {
		info: {
			path:`/:locale(tr|de)?${i18next.t('routes:info')}`,
			component: "Info",
			exact: false,
			linkTitle: "routes.pages.reservation.linkTitle",
			title: "routes.pages.reservation.title",
			GATitle: "routes.pages.reservation.gaTitle",
		},
		payment: {
			path:`/:locale(tr|de)?${i18next.t('routes:payment')}`,
			component: "Payment",
			exact: false,
			linkTitle: "routes.pages.payment.linkTitle",
			title: "routes.pages.payment.title",
			GATitle: "routes.pages.payment.gaTitle",
		},
		sum: {
			path:`/:locale(tr|de)?${i18next.t('routes:sum')}`,
			component: "Sum",
			exact: false,
			linkTitle: "routes.pages.sum.linkTitle",
			title: "routes.pages.sum.title",
			GATitle: "routes.pages.sum.gaTitle",
		},
		redirect:{
			path:`/:locale(tr|de)?${i18next.t('routes:redirect')}`,
			component:"redirect",
			exact: true,
			linkTitle: "routes.pages.redirect.linkTitle",
			title: "routes.pages.redirect.title",
			requiresLogin: false,
			GATitle: "routes.pages.redirect.gaTitle",
		}
	}
}
