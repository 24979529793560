import React from 'react'

// Sections
import Listing2 from 'components/sections/listing2.js'

// Partials

// Assets

export default class Search extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			endpoint: 'search/key',
		}
	}

	componentDidMount() {
		if (window.location.search === '' || window.location.search.length === 0) {
			this.setState({
				endpoint: 'filters'
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.search !== window.location.search) {

			if (window.location.search === '' || window.location.search.length === 0) {
				this.setState({
					endpoint: 'filters'
				})
			} else {
				this.setState({
					endpoint: 'search/key'
				})
			}
		}
	}

	render() {
		return (
			<main className="page search">
				{
					this.state.endpoint && <Listing2 className="search-listing" defaultOrder="date_desc" search={window.location.search} query={false} />
/*					<Listing className="search-listing" defaultOrder="date_desc" source="search/key" query={false} />*/
				}
			</main>
		)
	}
}
