import React from 'react';
import PopInfo from "./popinfo";
//Multi-Language
import {useTranslation} from "react-i18next";
import "../../i18n";

const ncapDescriptions = [
    "partials.ncapStars.descriptions.0",
    "partials.ncapStars.descriptions.1",
    "partials.ncapStars.descriptions.2",
    "partials.ncapStars.descriptions.3",
    "partials.ncapStars.descriptions.4",
];

export default function NcapStars(props) {
    const {ncap, info} = props;
    const {t} = useTranslation();
    return (
        <div className="info-ncap">
            <div className="ncap-stars" title={ncap + t("partials.ncapStars.title")}>
                <i className={"icon-star" + (ncap >= 1 ? ' active' : '')}/>
                <i className={"icon-star" + (ncap >= 2 ? ' active' : '')}/>
                <i className={"icon-star" + (ncap >= 3 ? ' active' : '')}/>
                <i className={"icon-star" + (ncap >= 4 ? ' active' : '')}/>
                <i className={"icon-star" + (ncap >= 5 ? ' active' : '')}/>
            </div>
            {info &&
                <PopInfo className="ncap-info" wide content={t(ncapDescriptions[ncap - 1])}>
                    <i className="icon-question"/>
                </PopInfo>
            }

        </div>

    );
}

NcapStars.defaultProps = {
    info: true,
}
