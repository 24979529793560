import React from 'react'
import Image from 'components/partials/image'
import Loader from 'components/partials/loader'
import ContentBox from 'components/partials/contentbox'

// Deps
import request from 'controllers/request'
import history from 'controllers/history'
import {redirect} from 'controllers/navigator'
import {serializeArray} from 'functions/helpers'
import axios from 'axios';

// Assets
import image_blog_bg from 'assets/images/blog-bg.svg'
import image_blog_bg_mobile from 'assets/images/blog-bg-mobile.svg'
import BlogPagination from "./blog-pagination";
// Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";
import i18n from "../i18n";
import {Link} from "react-router-dom";

class Blog extends React.Component {
	constructor(props) {

		super(props)

		this.state = {
			loading: true,
			categories: false,
			results: false,
			iterateArr: [0, 1, 2, 3, 4, 5],
			page: 1,
			onPage: 1,
			totalPage: 0,
			paginationLoop: 1
		}

		this.initialize = this.initialize.bind(this);
		this.makeSearch = this.makeSearch.bind(this);
		this.changePage = this.changePage.bind(this)
		this.listenerAbort = false;
		this.mounted = false;
	}
/*
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.pokemons !== this.state.pokemons) {
			console.log('pokemons state has changed.')
		}
	}

 */

	componentDidMount() {

		let vm = this;
		vm.mounted = true;
		vm.initialize(vm.state.page);
		vm.listenerAbort = history.listen(function (e) {
			//console.log(e)
			vm.initialize(vm.state.page,'' ,true);
		});
	}

	componentWillUnmount() {
		this.mounted = false;
		if (this.listenerAbort) {
			this.listenerAbort();
		}
	}

	async initialize(newPage, customCategory='', categoryChanged = false ) {
		const baseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://api.minoto.com"
		let vm = this;

		vm.setState({
			loading: true
		})

		if (vm.mounted) {
			//let category = window.location.pathname.split('/')[2];
			let category = ''
			if(!customCategory){
				category = decodeURI(history.location.search).split('=')[1];
			}else{
				if( i18next.resolvedLanguage === "tr" &&  customCategory=== i18next.t("blogFilter.1")){
					history.push(i18next.t("blogFilter.2"))
					category=undefined
				}
				else if (i18next.resolvedLanguage === "de" &&  customCategory===i18next.t("blogFilter.1")){
					history.push(i18next.t("blogFilter.2"))
					category=undefined
				}
				else{
					history.push(i18next.t("blogFilter.3")+customCategory)
					category= customCategory
				}
			}

			vm.setState({results: false});

			let params = {}
			let endpoint = "articles";


			if (category) {
				if (category === 'arama&key') {
					let searchParam = history.location.search.split('=')[2];
					params.search = vm.props.match;
					endpoint = `articles/search?search=${searchParam}`

				} else if (category === i18next.t("blogFilter.0")) {
					endpoint = `articles/recently`

				} else {
					params.category = category;
					endpoint = `${category}/articles`
				}
			}

			if (categoryChanged) {
				if(document.querySelector('.pagination')){
					for(let i =0;i<document.querySelector('.pagination').children.length;i++){
						if(i!==0){
							document.querySelector('.pagination').children[i].classList.remove('active')
						}
						if(vm.state.onPage>1){
							document.querySelector('.back-arrow').classList.add('disabled')
						}
						document.querySelector('.next-arrow').classList.remove('disabled')
					}
				}
				this.setState({
					page: 1,
					onPage: 1,
					paginationLoop: 1
				})
				const response = await axios.get(`${baseUrl}/v1/shared/${endpoint}?sayfa=1&language=` + i18next.resolvedLanguage  )

				const data = await response.data;
				if (data) {
					this.setState({
						results: data.payload,
						totalPage: data.totalpage,
						loading: false
					})
				}
			}
			else if(this.state.totalPage===0){
				this.setState({
					page: 1,
					onPage: 1,
					paginationLoop: 1
				})

				const response = await axios.get(`${baseUrl}/v1/shared/${endpoint}?sayfa=1&language=` + i18next.resolvedLanguage)
				/*const response = await axios.get(`${baseUrl}/v1/shared/${endpoint}?sayfa=1` +  '&language=' , {
					headers:{
						lang: i18next.language
					}
				} )*/
				const data = await response.data;
				if (data) {
					this.setState({
						results: data.payload,
						totalPage: data.totalpage,
						loading: false
					})
				}
			}
			else {
				request.get(endpoint + `?sayfa=${newPage}`, null, function (payload, status) {
					if (vm.mounted && payload) {
						vm.setState({
							results: payload,
							loading: false,
						});
					}
				});
			}
			request.get('articles/tags?language=' + i18next.resolvedLanguage, params, function (payload, status) {
				if (vm.mounted && payload) {
					vm.setState({
						categories: payload,
					});
				}
			});

	}}



	makeSearch(e) {
		let formData = serializeArray(e.target);
		redirect(i18n.t(`paths.blogComponent`), null, { kategori: 'arama', key: formData.search });
	}

	changePage = (newPage) => {
		let vm = this.state

		if(newPage>vm.onPage && (newPage%6)===1){
			this.setState({
				paginationLoop:vm.paginationLoop+1
			})
			this.setState({
				page:vm.page+6
			})
		}
		if(newPage<vm.onPage && (newPage%6)===0){
			this.setState({
				paginationLoop:vm.paginationLoop-1
			})
			this.setState({
				page:vm.page-6
			})
		}

		document.querySelector('.pagination').children[(newPage-1)%6].classList.add('active')
		for(let i =0;i<document.querySelector('.pagination').children.length;i++){
			if(i!==((newPage-1)%6)){
				document.querySelector('.pagination').children[i].classList.remove('active')
			}
		}

		if(newPage-1<1){
			document.querySelector('.back-arrow').classList.add('disabled')
		}else{
			document.querySelector('.back-arrow').classList.remove('disabled')
		}
		if(newPage+1>vm.totalPage){
			document.querySelector('.next-arrow').classList.add('disabled')
		}else{
			document.querySelector('.next-arrow').classList.remove('disabled')
		}
		this.setState({
			onPage:newPage
		})

		this.initialize(newPage)
	}

	render() {
		const { t } = this.props;
		let categories = this.state.categories;
		let results = this.state.results;
		return (
			<main className="page blog loader-container">
				<Loader loading={this.state.loading} strict />
				<section className="section blog-head">
					<div className="head-wrap wrapper narrow">
						<h1 className="head-title">{t("pages.blog.title")} <strong>{t("pages.blog.subtitle")}</strong></h1>
						<Image className="head-bg" src={image_blog_bg} mobile={image_blog_bg_mobile} alt={t("pages.blog.subtitle")} />

						{categories &&
							<div className="head-controls">
								<nav className="controls-nav">
									<div className="nav-item">
										<Link to={i18next.t(`routes:blogComponent`, { lng: i18next.resolvedLanguage })}  style={{cursor:'pointer'}} onClick={()=>this.initialize(this.state.page,i18next.t("blogFilter.1") ,true)}>
											<div>{t("pages.blog.titles.0")}</div>
										</Link>
									</div>
									<div className="nav-item" style={{borderLeft: '.2rem solid  #161d4c'}}>
										<Link to={i18next.t(`routes:blogComponent`, { lng: i18next.resolvedLanguage })+`?${i18n.t("blogFilter.4")}`} style={{cursor:'pointer'}}  onClick={()=>this.initialize(this.state.page,i18next.t("blogFilter.0"),true)}>
											<div>{t("pages.blog.titles.1")}</div>
										</Link>
									</div>
									{
									 categories ?  categories.map((category, nth)=> (
										 <>
											 {category.slug !== null &&
												 <div className="nav-item" key={nth} style={{borderLeft: '.2rem solid  #161d4c'}}>
													 <Link to={i18next.t(`routes:blogComponent`, { lng: i18next.resolvedLanguage }) +`?kategori=${category.slug}`} onClick={()=>this.initialize(this.state.page,category.slug,true)} >
														 <div>{category.title}</div>
													 </Link>
												 </div>
										 	}
										 </>

										))
										: null
									}

								</nav>

							</div>
						}
					</div>
				</section>

				{!this.state.loading &&
					<section className="section blog-results loader-container">
						<Loader loading={!results} strict/>
						<div className="wrapper narrow">
							{results &&
								<ul className="results-list">
									{results.map((result, nth) => (
										<li className="results-item" key={nth}>
											<ContentBox
												type="blogpost"
												pretitle={result.date}
												title={result.title}
												image={result.image}
												url="blogDetail"
												additionsOptional
												urlParams={{slug: result.slug}}
												wrap={result.title.length > 50 ? true : false}
											/>
										</li>
									))}
								</ul>
							}
							{results && results.length === 0 &&
								<h2 className="results-error">{t("pages.blog.resultsErrorText")}</h2>
							}
						</div>
					</section>
				}
				<BlogPagination
					iterateArr={this.state.iterateArr}
					page={this.state.page}
					totalPage={this.state.totalPage}
					changePage={this.changePage}
					onPage={this.state.onPage}
				/>

			</main>
		)
	}
}
export default (withTranslation()(Blog))
