import React from "react";
import {seoFriendlyUrl, turkishSort} from "../../functions";
import ListingBanner from "../partials/listing-banner";
import TagItem from '../partials/tag-item'
import ContentBox from "../partials/contentbox";
import {GA} from "../../controllers/ga";
import {addImpressionProduct} from "../../data/store.ga";
// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";
import i18n from "../../i18n";

class ListingResults extends React.Component {
    render() {
        const { t } = this.props;
        let vm = this;
   /*     let itemsAt = 0;*/
        let loading = false;
        let data = vm.props.data
        let results = null;
        if(data){
            results = data;
        }


        let productResultsCount = (results ? results.filter((result) => { return result.type === 'advert' }).length : 0);
        if (results && results.length && !loading) {
                return (
                    <React.Fragment>
                        {
                            (data.similar || data.totalResults === 0) && (
                                <React.Fragment>
                                    {
                                        loading === false && (<div className="results-error"><div className="error-message">{t("sections.listingResults.errMessage")} </div></div>)
                                    }
                                </React.Fragment>
                            )
                        }

                        <ul className="content-results">

                            {
                                data.type === 'dealer'
                                    ?
                                    (<React.Fragment>
                                        {

                                            results.sort(turkishSort).map((item, nth) => {
                                              /*  itemsAt += (item.size ? item.size : 1);*/
                                                let contents = [];
                                                switch (item.type) {
                                                    case 'banner':
                                                        let contentItem = <ListingBanner key={nth} mobile={vm.props.mobile} data={item} t={t} />
                                                        contents.push(contentItem);
                                                        break;
                                                    case 'tag':
                                                        let tagItem = <TagItem key={nth} data={item} />
                                                        contents.push(tagItem)
                                                        break;
                                                    default:
                                                        contents.push(
                                                            <li key={nth} className="results-item">
                                                                <ContentBox
                                                                    type="plain"
                                                                    className={((item.status === 2 || item.status === 3) ? 'inactive' : '')}
                                                                    title={item.title}
                                                                    subtitle={item.dealer}
                                                                    additionTitle={item.count + t("sections.listingResults.additionTitle")}
                                                                    image={item.image ? item.image : ''}
                                                                    labels={item.labels}
                                                                    faved={item.favorited}
                                                                    //favControls={'/dummy/data/fav/dealer/'+item.id}
                                                                    badge={(item.status !== 1 ? false : (item.status === 2 ? { text: t("sections.listingResults.text"), note: t("sections.listingResults.note") } : { text: t("sections.listingResults.text2"), type: 'error' }))}
                                                                    bottomNote={(item.currentViewers > 0 ? item.currentViewers + t("sections.listingResults.bottomNote") : false)}
                                                                    url={`${i18n.t(    "paths.dealers")}/${item.link}`}
                                                                />
                                                            </li>
                                                        )
                                                        break;
                                                }


                                                return contents;
                                            })
                                        }
                                    </React.Fragment>)
                                    :
                                    (<React.Fragment>
                                        {
                                            results.map((item, nth) => {
                                       /*         itemsAt += (item.size ? item.size : 1);*/
                                                let contents = [];
                                                switch (item.type) {
                                                    case 'banner':
                                                        let contentItem = <ListingBanner t={t} key={nth} mobile={vm.props.mobile} data={item} setAnyFilter={this.props.setAnyFilter} />
                                                        contents.push(contentItem);
                                                        break;
                                                    case 'tag':
                                                        let tagItem = <TagItem key={nth} data={item} />
                                                        contents.push(tagItem)
                                                        break;
                                                    default:
                                                        switch (data.type) {
                                                            case "brand":
                                                                contents.push(

                                                                <li key={nth} className="results-item">
                                                                        <ContentBox
                                                                            type="plain"
                                                                            className={((item.status === 2 || item.status === 3) ? 'inactive' : '')}
                                                                            title={item.title}
                                                                            labels={item.labels}
                                                                            additionTitle={item.count + t("sections.listingResults.additionTitle")}
                                                                            image={item.image}
                                                                            faved={item.favorited}
                                                                            //favControls={'/dummy/data/fav/dealer/'+item.id}
                                                                     /*      url={`markalar/${item.link}`}*/
                                                                             url={`${i18n.t("paths.brands")}/${item.link}`}

                                                                        />
                                                                    </li>
                                                                )
                                                                break;
                                                            default: //listing

                                                                contents.push(
                                                                    <li key={nth} className="results-item">
                                                                        <ContentBox
                                                                            className={((item.status === 2 || item.status === 3) ? 'inactive' : '')}
                                                                            item={item}
                                                                            title={item.title}
                                                                            subtitle={item.dealer}
                                                                            image={item.image}
                                                                            //image={storageSpace('c_scale,q_auto:best,w_360/car-posts', item.image)}
                                                                            price={item.price}
                                                                            labels={item.labels}
                                                                            productionPlace={item.productionPlace}
                                                                            faved={item.favorited}
                                                                            badge={(item.status === 1 ? false : (item.status === 2 ? { text: t("sections.listingResults.text"), note: t("sections.listingResults.note")} : { text: t("sections.listingResults.text2"), type: 'error' }))}
                                                                            bottomNote={(item.currentViewers > 0 ? item.currentViewers + t("sections.listingResults.bottomNote") : false)}
                                                                            url="detail"
                                                                            urlParams={{ dealer: seoFriendlyUrl(item.dealer), slug: item.slug.substring(0,item.slug.lastIndexOf('-M')), post: item.postNo }}
                                                                           /*< url={String(i18next.t(`routes:detail`, { lng: i18next.resolvedLanguage })).replace(/:slug|:handler|:post/gi, function(matched){
                                                                                return {
                                                                                    ":slug":seoFriendlyUrl(item.dealer),
                                                                                    ":handler":item.slug.substring(0,item.slug.lastIndexOf('-M')),
                                                                                    ":post":item.postNo
                                                                                }[matched];
                                                                            })} */
                                                                            onClick={() => {
                                                                                GA.send('productClick', {
                                                                                    product: item,
                                                                                    totalResults: results.length
                                                                                });

                                                                                if (vm.props.searchInput) {
                                                                                    GA.send('searchClick', {
                                                                                        product: item,
                                                                                        searchInput: vm.props.searchInput,
                                                                                    });
                                                                                }
                                                                            }}

                                                                            onDisplay={() => { addImpressionProduct(vm.props.GAGroup, item, productResultsCount); }}
                                                                        />
                                                                    </li>
                                                              /*      <li key={nth} className="results-item">

                                                                            <ContentBox
                                                                            className={((item.status === 2 || item.status === 3) ? 'inactive' : '')}
                                                                            item={item}
                                                                            title={item.title}
                                                                            subtitle={item.dealer}
                                                                            image={item.image}
                                                                            //image={storageSpace('c_scale,q_auto:best,w_360/car-posts', item.image)}
                                                                            price={item.price}
                                                                            labels={item.labels}
                                                                            productionPlace={item.productionPlace}
                                                                            faved={item.favorited}
                                                                            badge={(item.status === 1 ? false : (item.status === 2 ? { text: t("sections.listingResults.text"), note: t("sections.listingResults.note")} : { text: t("sections.listingResults.text2"), type: 'error' }))}
                                                                            bottomNote={(item.currentViewers > 0 ? item.currentViewers + t("sections.listingResults.bottomNote") : false)}
                                                                            url={`${i18n.t(    "paths.brands")}/${item.link}`}
                                                                            urlParams={{ dealer: seoFriendlyUrl(item.dealer), slug: item.slug.substring(0,item.slug.lastIndexOf('-M')), post: item.postNo }}
                                                                            onClick={() => {
                                                                                GA.send('productClick', {
                                                                                    product: item,
                                                                                    totalResults: results.length
                                                                                });

                                                                                if (vm.props.searchInput) {
                                                                                    GA.send('searchClick', {
                                                                                        product: item,
                                                                                        searchInput: vm.props.searchInput,
                                                                                    });
                                                                                }
                                                                            }}

                                                                            onDisplay={() => { addImpressionProduct(vm.props.GAGroup, item, productResultsCount); }}
                                                                        />
                                                                    </li>*/
                                                                );
                                                                break;
                                                        }
                                                        break;
                                                }


                                                return contents;
                                            })
                                        }
                                    </React.Fragment>)}
                        </ul>
                    </React.Fragment>
                )
        }
        else {
            return (
                <React.Fragment>
                    {
                        loading === false && (<div className="results-error"><div className="error-message"> {t("sections.listingResults.errMessage2")} </div></div>)
                    }
                </React.Fragment>
            )
        }
    }
}


export default (withTranslation()(ListingResults))

