import React from 'react'
// Sections
import Listing2 from 'components/sections/listing2.js'
import ListingFilters from 'components/sections/listing-filters.js'
// Partials
import Loader from 'components/partials/loader'
import Image from 'components/partials/image'
import Btn from 'components/partials/btn'
import FavBtn from 'components/partials/favbtn'
import Responsive from 'components/partials/responsive'
// Deps
import {connect} from "react-redux"
import extend from 'lodash/extend'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import {setDescription, setTitle} from 'controllers/head'
import request from 'controllers/request'
import {redirect} from 'controllers/navigator'
import {setDealerData} from 'data/store.ga'
import {GA} from 'controllers/ga'
import Breadcrumbs from "../components/partials/breadcrumbs";
import {set404} from "../controllers/navigator";
import axios from "axios";

// Assets

//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";

const mapDispatchToProps = dispatch => {
	return {
		setGaDealerData: (data) => dispatch(setDealerData(data)),
	}
}

class Dealer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dealerData: false,
			loading: true,
			searchText: '',
			listingQuery: false,
			listingData: false,
			expandBranches: false,
			filters:{}
		}

		this.initialize = this.initialize.bind(this);
		this.changeSearch = this.changeSearch.bind(this);
		this.getFilters = this.getFilters.bind(this);
		this.updateFilters = this.updateFilters.bind(this);
		this.setAnyFilter = this.setAnyFilter.bind(this);
		this.updateSearch = debounce(this.updateSearch.bind(this), 50);
	}

	componentDidMount() {
		this.initialize();
		this.getFilters();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevState.dealerData, this.state.dealerData) || prevState.searchText !== this.state.searchText) {
			this.updateSearch();
		}
	}

	initialize() {
		const { t } = this.props;
		let vm = this;
		request.get(`dealers/${vm.props.match.params.slug}`, { id: vm.props.match.params.slug }, function (payload) {
			if (payload.status !== '404') {
				if (payload) {
					vm.props.setGaDealerData(payload);
					vm.setState({
						dealerData: payload,
						listingQuery: { dealer: vm.props.match.params.slug },
					})

					setTitle(payload.title + t("pages.dealer.title") );
					setDescription(`${payload.title} ${t("pages.dealer.description.0")} ${payload.title} ${t("pages.dealer.description.1")}`);
				} else {
					redirect("notfound");
				}
			}else {
				set404()
			}
		});
	}

	changeSearch(e) {
		this.setState({ searchText: e });
	}

	updateSearch() {
		if (this.state.dealerData) {
			let query = { dealer: this.props.match.params.slug };

			if (this.state.searchText !== '') {
				query.search = this.state.searchText;
			}

			this.setState({ listingQuery: query });
		}
	}

	updateFilters(newQuery) {
		//let newQuery = clone(this.props.query);
		newQuery = extend({}, newQuery, {
			dealer: this.props.match.params.slug,
		});

		this.setState({
			listingQuery: newQuery
		})
	}

	async getFilters(){
		const baseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://api.minoto.com"
		const query = this.props.location.search
		const bayi = window.location.pathname.split('/')[2]
		try{
			const response = await axios.get(`${baseUrl}/v1/shared/item-filters?bayi=${bayi}&${query && query.slice(1)}&language=` + i18next.resolvedLanguage )
			const data = await response.data
			this.setState({filters:data})
		}
		catch(err){
			console.log(err)
		}
	}

	setAnyFilter = (filterObject) => {
		let filterString = '';
		Object.keys(filterObject).forEach(function (key, index) {
			if (index === 0) {
				filterString += `?${key}=${filterObject[key]}`
			} else {
				filterString += `&${key}=${filterObject[key]}`
			}
		});
		window.location.href=window.location.pathname+filterString
		return null;
	}

	render() {
		const { t } = this.props;
		let vm = this;
		let dealer = vm.state.dealerData;
		return (
			<main className="page dealer">
				<Loader loading={dealer === false} strict={true} />
				<div className="wrapper">
					{dealer &&
						<section className="section dealer-detail">
							<section className="detail-info">
								<div className="info-sum">
									<FavBtn className="sum-favbtn" faved={dealer.favorited} type="dealer" id={dealer.id} />
									<Image className="sum-avatar" bg src={dealer.avatar} />
									<h1 className="sum-title">
										{dealer.title}
									</h1>

									<span style={{ opacity: '0' }} className={"sum-workinghours " + (dealer.open ? 'open' : 'closed')}>
										{dealer.workingHours}
										<span>|</span>
										{(dealer.open ? t("pages.dealer.options.0") : t("pages.dealer.options.1"))}
									</span>
									{
										dealer.phone && (
											<div className="sum-controls">
												<Btn
													tag="a"
													icon="phone"
													primary low wide
													href={'tel:+9' + dealer.phone.replace(' ', '')}
													onClick={() => {
														GA.send('conversion', {
															action: 'callDealer',
														});
													}}>
													{dealer.phone}
												</Btn>
											</div>
										)
									}


									<div className="sum-numbers">
										<span className="numbers-elem">{dealer.branchCount} {t("pages.dealer.numberTexts.0")}</span>
										<span className="numbers-elem">{dealer.listingCount} {t("pages.dealer.numberTexts.1")}</span>
									</div>
								</div>

								{/*
							<InputForm className="info-search">
								<FormInput placeholder={dealer.title+' üzerinde ara'} value={vm.state.searchText} onChange={vm.changeSearch} />
								<button type="submit" className="search-submit"><i className="icon-search"></i></button>
							</InputForm>
							*/}
						{/*		{
									dealer.dealerType === 'dealer' && (
										<div className="info-branches">
											<div className="branches-head">
												<strong className="head-title">Şubeler / Lokasyonlar</strong>
											</div>

											<ul className="branches-list">
												{dealer.branches.sort(turkishSort).reduce((filtered, branch, nth) => {
													if (nth < branchExpandLimit || vm.state.expandBranches) {
														filtered.push(<BranchInfo data={branch} key={nth} dealer={vm.props.match.params.slug} />)
													}
													return filtered;
												}, [])}
											</ul>
											{dealer.branches.length > branchExpandLimit &&
												<button className="branches-extend" onClick={() => { vm.setState({ expandBranches: !vm.state.expandBranches }) }}>
													{!vm.state.expandBranches ?
														<span>+ Daha fazla göster</span> :
														<span>- Küçült</span>
													}
												</button>
											}
										</div>
									)
								}*/}

								{/*setAnyFilter={this.setAnyFilter}*/}
								{(vm.state.filters) &&
								<ListingFilters listing2Data={vm.state.filters} loading={!this.listingQuery} showMoreBrands={true} />
							}
							{/*	<ListingFilters />*/}
							</section>
							<div className="detail-right" >
								<Responsive type="only-web">
									<Image className="dealer-cover" bg src={'/assets/images/MinotoBayi.jpg'} />
								</Responsive>
								<Breadcrumbs className="top-breadcrumbs" data={[
									{
										"href":  i18next.t(`routes:/`, { lng: i18next.resolvedLanguage }),
										"title": t("pages.dealer.breadCrumbTitle.0")
									},
									{
										"href":"",
										"title": t("pages.dealer.breadCrumbTitle.1")
									},
									{
										"href": dealer.slug,
										"title": dealer.title
									},
								]} />
								{vm.state.listingQuery &&
									<Listing2
										className="dealer-listing"
										id="listing-here"
										//urlBinding={false}
										search={this.props.location.search}
										filters={false}
										source={`dealers/${vm.state.listingQuery.dealer}/car-posts`}
										showAds={false}
									/>
								}
							</div>
						</section>
					}
				</div>
			</main>

		)
	}
}


export default connect(null, mapDispatchToProps)(withTranslation()(Dealer))
