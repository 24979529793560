import React from 'react'

// Partials

// Deps
import Image from 'components/partials/image'

// Assets
import image_consent_banner from 'assets/images/modals/consent-banner.svg'

// Multi language
import "../../i18n";

export default class ConsentModal extends React.Component {
	render() {
		const { t } = this.props;
		let vm = this;
		return (
			<div className={vm.props.className}>
				{vm.props.closeBtn}

				<Image className="consent-banner" src={image_consent_banner} bg />
				<div className="modal-innercontent">
					<p className="confirm-question"> {t("modals.consent.title")}</p>

					<div className="confirm-opts">
						<button className="opts-opt btn primary big uppercase hollow" onClick={vm.props.onClose}>{t("modals.consent.options.0")}</button>
						<button className="opts-opt btn primary big uppercase" onClick={vm.props.onClose}>{t("modals.consent.options.1")}</button>
					</div>
				</div>
			</div>
		)
	}
}

ConsentModal.defaultProps = {
	className: "",
	containerClass: "modal-consent modal-confirm narrow",
	name: "consent",
	preventClose: true,
}
