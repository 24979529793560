import React from 'react'
//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";

class Faq extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <main className="page content">
                <section className="section contentpage">
                    <div className="contentpage-wrap wrapper narrow">
                        <div className="contentpage-content">
                            <h1 className="content-title">{t("pages.privacy.title")}</h1>
                            <div className="section contentpage-textarea wysiwyg">
                                <p className="indent">
                                    <strong>{i18next.resolvedLanguage==='tr' ?  t("pages.privacy.content.0.strong.0") : ''}</strong> {t("pages.privacy.content.0.text.0")}<strong>{t("pages.privacy.content.0.strong.1")}</strong>{t("pages.privacy.content.0.text.1")}<strong>&nbsp;{ i18next.resolvedLanguage==='tr' ? t("pages.privacy.content.0.strong.1"): ''}</strong>&nbsp; {t("pages.privacy.content.0.text.2")}
                                    &nbsp;
                                    <strong>{t("pages.privacy.content.0.strong.1")}</strong>&nbsp;{t("pages.privacy.content.0.text.3")}
                                </p>
                                <p className="indent">{t("pages.privacy.content.0.text.4")}  </p>
                                <p className="indent">
                                    {t("pages.privacy.content.0.text.5")}
                                    <strong>{i18next.resolvedLanguage==='tr' ? t("pages.privacy.content.0.strong.0")+' ;' : ''} </strong> {t("pages.privacy.content.0.text.6")}
                                    <strong> {t("pages.privacy.content.0.strong.0")} </strong> {t("pages.privacy.content.0.text.7")}
                                </p>

                                <h3 className="text-center">{t("pages.privacy.content.1.title")}</h3>
                                <ol>
                                    <li>{t("pages.privacy.content.1.listItems.0")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.1")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.2")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.3")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.4")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.5")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.6")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.7")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.8")}</li>
                                    <li>{t("pages.privacy.content.1.listItems.9")}</li>
                                </ol>

                                <h3 className="text-center">{t("pages.privacy.content.2.title")}</h3>
                                <ol>
                                    <li>{t("pages.privacy.content.2.listItems.0")}</li>
                                    <li>{t("pages.privacy.content.2.listItems.1")}</li>
                                    <li>{t("pages.privacy.content.2.listItems.2")}</li>
                                    <li>{t("pages.privacy.content.2.listItems.3")}</li>
                                    <li>{t("pages.privacy.content.2.listItems.4")}</li>
                                    <li>{t("pages.privacy.content.2.listItems.5")}</li>
                                    <li>{t("pages.privacy.content.2.listItems.6")}</li>
                                    <li>{t("pages.privacy.content.2.listItems.7")}</li>
                                    <li>{t("pages.privacy.content.2.listItems.8")}</li>
                                </ol>

                                <h3 className="text-center">{t("pages.privacy.content.3.title")}</h3>
                                <p className="indent"> {t("pages.privacy.content.3.listItems.0")}</p>
                                <p className="indent"> {t("pages.privacy.content.3.listItems.1")}</p>

                                <h3 className="text-center">{t("pages.privacy.content.4.title")}</h3>
                                <p className="indent"> {t("pages.privacy.content.4.listItems.0")}</p>
                                <p className="indent"> {t("pages.privacy.content.4.listItems.1")}</p>

                                <h3 className="text-center">{t("pages.privacy.content.5.title")}</h3>
                                <p className="indent"> {t("pages.privacy.content.5.listItems.0")}</p>
                                <p className="indent"> {t("pages.privacy.content.5.listItems.1")}</p>
                                <p className="indent"> {t("pages.privacy.content.5.listItems.2")}</p>

                                <h3 className="text-center">{t("pages.privacy.content.6.title")}</h3>
                                <p className="indent"> {t("pages.privacy.content.6.listItems.0")}</p>
                                <p className="indent"> {t("pages.privacy.content.6.listItems.1")}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        )
    }
}

export default (withTranslation()(Faq))
