import React from 'react'
// Partials
import Image from 'components/partials/image'
import Link from 'components/partials/link'
//import Btn from 'components/partials/btn'
import Responsive from 'components/partials/responsive'
import Btn from 'components/partials/btn.js'
import {FormInput, InputForm} from 'components/partials/forms'
// Functions
import {formatNumber} from 'functions/helpers'
// Deps
import {connect} from "react-redux"
import request from 'controllers/request'
import config from 'data/config'
// Assets
import image_icon_facebook from 'assets/images/icon/facebook.svg'
import image_icon_instagram from 'assets/images/icon/instagram.svg'
import image_icon_youtube from 'assets/images/icon/youtube.svg'
import image_logo_white from 'assets/images/logo-white.svg'
import image_icon_twitter from 'assets/images/icon/twitter2.svg'
import image_icon_linkedin from 'assets/images/icon/linkedin.svg'
// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";
import i18next from "i18next";

const mapStateToProps = state => {
	return { mobile: state.generic.mobile };
};

class Footer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			brands: false,
		}

		this.initialize = this.initialize.bind(this);
	}

	componentDidMount() {
		this.initialize();
	}

	initialize() {
		let vm = this;
		request.get('brands/footer?language=' + i18next.resolvedLanguage, {}, function (payload) {
			if (payload) {
				vm.setState({
					brands: payload
				});
			}
		});
	}

	render() {
		const { t } = this.props;
		let vm = this;
		let brands = false;
		if (vm.state.brands) {
			brands = [];
			let groupCount = (vm.props.mobile ? 2 : 6);
			let groupSize = Math.ceil(vm.state.brands.length / groupCount);

			for (let k = 0; k < groupCount; k++) {
				let group = vm.state.brands.slice(k * groupSize, (k * groupSize) + groupSize).map(function (brand, nth) {
					if (((k * groupSize) + nth) < vm.state.brands.length) {
						return brand;
					}
					else {
						return false;
					}
				});
				brands.push(group);
			}
		}

		return (
			<footer className="section footer">
				<div className="footer-brands">
				{vm.state.brands &&
					<div className="brands-wrap wrapper narrow">
						<nav className="brands-list">
							{brands.map((group, nth) => (
								<div className="list-group" key={nth}>
									{group.map((brand, nt) => (
										<div className="brands-item" key={nt}>
											<Link href={i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage })+`/${brand.url}`}>
												{brand.title} {brand.count && <span className="item-count">({formatNumber(brand.count)})</span>}
											</Link>
										</div>
									))}
								</div>
							))}
						</nav>
					</div>
				}
				</div>
				<div className="footer-bottom">
					<div className="footer-wrap wrapper narrow">
						<div className="footer-col">
							<a className="footer-logo" href={i18next.t(`routes:/`, { lng: i18next.resolvedLanguage })}>
								<Image alt={t("logoText")} src={image_logo_white} />
							</a>
						</div>
						<nav className="footer-col footer-nav">
							<Responsive>
								<ul className="nav-col">
									<li>
										<Link className="footer-link" href={i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage })}>{t("footerItems.0.title")}</Link>
									</li>
								</ul>
								<ul className="nav-col">
									<li>
										<Link className="footer-link" href={i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage })}>{t("footerItems.1.title")}</Link>
									</li>
									<li>
										<Link className="footer-link" href={i18next.t(`routes:about`, { lng: i18next.resolvedLanguage })}>{t("footerItems.3.title")}</Link>
									</li>
									<li>
										<Link className="footer-link" href={i18next.t(`routes:blogComponent`, { lng: i18next.resolvedLanguage })}>{t("footerItems.4.title")}</Link>
									</li>

								</ul>
							</Responsive>
							<Responsive>
								<ul className="nav-col">
									<li>
										<Link className="footer-link"  href={i18next.t(`routes:about`, { lng: i18next.resolvedLanguage })}>{t("footerItems.3.title")}</Link>
									</li>
									<li>
										<Link className="footer-link" href={i18next.t(`routes:blogComponent`, { lng: i18next.resolvedLanguage })}>{t("footerItems.4.title")}</Link>
									</li>

								</ul>
								<ul className="nav-col">
									<li>

										<Link href={i18next.t(`routes:contact`, { lng: i18next.resolvedLanguage })}>{i18next.t("footerItems.5.title")}</Link>
									</li>
									<li>

										<Link href={i18next.t(`routes:faq`, { lng: i18next.resolvedLanguage })}>{i18next.t("footerItems.6.title")}</Link>
									</li>
									<li>
										<Link href={i18next.t(`routes:privacy`, { lng: i18next.resolvedLanguage })}>{i18next.t("footerItems.7.title")}</Link>
									</li>
									<li>
										<Link href={i18next.t(`routes:gdprPolicy`, { lng: i18next.resolvedLanguage })}>{i18next.t("footerItems.8.title")}</Link>
									</li>
								</ul>
							</Responsive>
							<Responsive type="only-web">
								<ul className="nav-col">
									<li>
										<Link href={i18next.t(`routes:faq`, { lng: i18next.resolvedLanguage })}>{i18next.t("footerItems.6.title")}</Link>
									</li>
									<li>
										<Link href={i18next.t(`routes:privacy`, { lng: i18next.resolvedLanguage })}>{i18next.t("footerItems.7.title")}</Link>
									</li>
									<li>
										<Link href={i18next.t(`routes:gdprPolicy`, { lng: i18next.resolvedLanguage })}>{i18next.t("footerItems.8.title")}</Link>
									</li>
								</ul>
							</Responsive>
							<div className="nav-col nav-contact">
								<div className="contact-text">
									<p className="contact-title"></p>
									<p className="contact-phone"><a href={t("footerItems.email.link")} title={t("footerItems.email.title")} ><i className="icon-envelope"></i>{t("footerItems.email.title")}</a></p>
								</div>

								<ul className="contact-social">
									<li>
										<a href={config.social.instagramURL}   target={"_blank"} rel="noopener noreferrer" title={t("hamburgerMenu.socials.0.title")}>
											<Image src={image_icon_instagram} alt={t("hamburgerMenu.socials.0.alt")} />
										</a>
									</li>
									<li>
										<a href={config.social.youtubeURL}  target={"_blank"}  rel="noopener noreferrer" title={t("hamburgerMenu.socials.1.title")}>
											<Image src={image_icon_youtube} alt={t("hamburgerMenu.socials.1.alt")} />
										</a>
									</li>
									<li>
										<a href={config.social.facebookURL}   target={"_blank"} rel="noopener noreferrer" title={t("hamburgerMenu.socials.2.title")}>
											<Image src={image_icon_facebook} alt={t("hamburgerMenu.socials.2.alt")} />
										</a>
									</li>
									<li>
										<a href={config.social.twitterURL}  target={"_blank"}  rel="noopener noreferrer" title={t("hamburgerMenu.socials.3.title")}>
											<Image src={image_icon_twitter} alt={t("hamburgerMenu.socials.3.alt")} />
										</a>
									</li>
									<li>
										<a href={config.social.linkedinURL}   target={"_blank"} rel="noopener noreferrer" title={t("hamburgerMenu.socials.4.title")}>
											<Image src={image_icon_linkedin} alt={t("hamburgerMenu.socials.4.alt")} />
										</a>
									</li>
								</ul>
							</div>
						</nav>

						{vm.props.mobile &&
							<FooterSubscription t={t} />
						}

						<div className="footer-col">
							{!vm.props.mobile &&
								<React.Fragment>
									<h3 className="app-title" >{t("footerItems.titles.4.title")}</h3>
									<FooterSubscription t={t} />
								</React.Fragment>

							}
							{false &&
								<div className="footer-app" style={{ opacity: '0' }}>
									<h3 className="app-title">{t("footerItems.titles.4.title")}</h3>
									<Btn tag="a" className="app-link" white {...(vm.props.mobile ? { low: true } : {})} href={t("hamburgerMenu.app.0.link")}  rel="noopener noreferrer" icon={t("hamburgerMenu.app.0.icon")}>	{t("hamburgerMenu.app.0.title")}</Btn>
									<Btn tag="a" className="app-link" white {...(vm.props.mobile ? { low: true } : {})} href={t("hamburgerMenu.app.1.link")}   rel="noopener noreferrer" icon={t("hamburgerMenu.app.1.icon")}>	{t("hamburgerMenu.app.1.title")}</Btn>
								</div>
							}

						</div>
					</div>
					<div className="footer-copyright footer-wrap wrapper narrow">
						<div className="copyright-disclaimer footer-col">
						{t("copyrightText")}
						</div>
						<div className="copyright-designby footer-col">

						</div>
					</div>
				</div>
				<button onClick={() => {document.body.scrollTop = 0;document.documentElement.scrollTop = 0;}} className="footer-jump-to" title={t("goToTopTitle")}>↑</button>
			</footer>
		)
	}
}


export default connect(mapStateToProps)(withTranslation()(Footer))


class FooterSubscription extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			submitting: false,
			complete: false,
		}

		this.submit = this.submit.bind(this);

		this.form = React.createRef();
	}

	submit(e) {

		let vm = this;
		vm.setState({ submitting: true });

		let record = {
			email: e.target.elements.email.value
		};

		request.post('mail/newsletter', record, function (payload) {
			if (payload.success) {
				vm.setState({ complete: true, submitting: false });
				if (vm.form.current) {
					vm.form.current.reset();
				}
			}
		}, { excludeApiPath: false });
	}

	render() {
		const { t } = this.props;
		let vm = this;
		return (
			<InputForm className="footer-subscription" onSubmit={vm.submit} ref={vm.form}>
				<label>
					<FormInput
						className="subscription-input"
						disabled={vm.state.submitting}
						placeholder={t("footerItems.9.title")}
						validation={{
							required: t("footerItems.9.validation"),
							email: true
						}}
						name="email"
						hideAsterisk
						type="text"/>

				</label>
				<div className="subscription-submitwrap">
					{vm.state.complete ?
						<button type="button" onClick={() => { vm.setState({ complete: false }) }} className="submitwrap-complete"><i className="icon-check"></i></button>
						:
						<React.Fragment>
							{vm.state.submitting ?
								<i className="submitwrap-loader icon-spinner"></i>
								:
								<button type="submit" className="submitwrap-submit" aria-label="submit icon"><i
									className="icon-angle-right"/></button>
							}
						</React.Fragment>
					}
				</div>
				{
					vm.state.complete && (
						<React.Fragment>
							<br />
							{t("footerItems.10.title")}
						</React.Fragment>
					)
				}
			</InputForm>
		)
	}
}



