import React from 'react'

import Link from 'components/partials/link'
// Partials
import { CollapseGroup } from 'components/partials/collapse'
//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";


class Faq extends React.Component {
	render() {
		const { t } = this.props;
		return (
			<main className="page content">
				<section className="section contentpage">
					<div className="contentpage-wrap wrapper narrow">
						<div className="contentpage-content">
							<h1 className="content-title">{t("pages.faq.title")}</h1>
							<ul className="section contentpage-faq">
								<li className="faq-item">
									<CollapseGroup label={t("pages.faq.collapseCards.0.question")}>
										<div className="wysiwyg">
											<p>{t("pages.faq.collapseCards.0.answer")}</p>
										</div>
									</CollapseGroup>
								</li>
								<li className="faq-item">
									<CollapseGroup label={t("pages.faq.collapseCards.1.question")}>
										<div className="wysiwyg">
											<p>{t("pages.faq.collapseCards.1.answer")}</p>
										</div>
									</CollapseGroup>
								</li>
								<li className="faq-item">
									<CollapseGroup label={t("pages.faq.collapseCards.2.question")}>
										<div className="wysiwyg">
											<p>{t("pages.faq.collapseCards.2.answer")}</p>
										</div>
									</CollapseGroup>
								</li>
								<li className="faq-item">
									<CollapseGroup label={t("pages.faq.collapseCards.3.question")}>
										<div className="wysiwyg">
											<p>{t("pages.faq.collapseCards.3.answer")}</p>
										</div>
									</CollapseGroup>
								</li>
								{/*<li className="faq-item">
									<CollapseGroup label="Minoto'da teklif verilir mi?">
										<div className="wysiwyg">
											<p>Minoto'dan istediğiniz araç için bayiye teklifte bulunabilirsiniz. Ancak bu teklifin iletilebilmesi için, bayinin minimum teklif tutarının üzerinde bir teklif vermeniz gerekir.</p>
										</div>
									</CollapseGroup>
								</li>
								<li className="faq-item">
									<CollapseGroup label="Minoto'daki araçlar 2.el mi?">
										<div className="wysiwyg">
											<p>Minoto'da sergilenen araçların hepsi "0 km"dir ve hepsi yetkili satıcıların stoğundadır.</p>
										</div>
									</CollapseGroup>
							</li>*/}
								<li className="faq-item">
									<CollapseGroup label={t("pages.faq.collapseCards.4.question")}>
										<div className="wysiwyg">
											<p>	{t("pages.faq.collapseCards.4.answer")}
												<Link href={i18next.t(`routes:dealerApplication`, { lng: i18next.resolvedLanguage })}
													  style={{ color: '#145aff', textDecoration: 'underline' }}>
												{t("pages.faq.collapseCards.4.answer2")}</Link>{t("pages.faq.collapseCards.4.answer3")}</p>
										</div>
									</CollapseGroup>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</main>

		)
	}
}
export default (withTranslation()(Faq))
