import React from 'react'

// Partials
import Link from 'components/partials/link'
// Multi language
import { withTranslation } from 'react-i18next';
import "../../../i18n";


 class ReservationNav extends React.Component {
	render() {
		const { t } = this.props;
		return (
			<nav className="section content-nav">
				<Link className={"nav-item"+ (this.props.section === 'sum' ? ' disabled' : '')} navLink href="reservation.info" params={{id: this.props.reservationID}}>
					<i className="item-icon icon-handshake"></i>
					<div className="item-text" disabled={this.props.section === 'sum'}>
						<strong>{t("partials.reservation.nav.text.0.0")} </strong>
						<span>{t("partials.reservation.nav.text.0.1")} </span>
					</div>
				</Link>
				<Link className={"nav-item" + (this.props.section === 'sum' ? ' disabled' : '')} navLink href="reservation.payment" params={{id: this.props.reservationID}} disabled={this.props.section === 'sum'}>
					<i className="item-icon icon-wallet"></i>
					<div className="item-text">
						<strong>{t("partials.reservation.nav.text.1.0")} </strong>
						<span>{t("partials.reservation.nav.text.1.1")} </span>
					</div>
				</Link>
				<Link className={this.props.section==='sum' ? 'nav-item  sum active': 'nav-item disabled sum'} navLink href="reservation.sum" params={{id: this.props.reservationID}} disabled>
					<i className="item-icon icon-check-round"></i>
					<div className="item-text">
						<strong>{t("partials.reservation.nav.text.2.0")} </strong>
						<span>{t("partials.reservation.nav.text.2.1")} </span>
					</div>
				</Link>
			</nav>
		)
	}
}
ReservationNav.defaultProps = {
	section: 'info',
	reservationID : false,
}
export default (withTranslation()(ReservationNav))