import React from 'react'

// Sections
import Listing from 'components/sections/listing.js'

//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";

class Posts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }

    }

    render() {
        const { t } = this.props;
        return (
            <main className="page home">
                <React.Fragment>
                    <Listing className="home-listing"
                        source="filters"
                        defaultOrder="random"
                        query={false}
                        scrollOnFilterChange
                        title={t("pages.posts.title")}
                    />
                </React.Fragment>
            </main>

        )
    }
}
export default (withTranslation()(Posts))