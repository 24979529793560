import React from "react";
//Sections
import Listing from '../../sections/listing'
import i18next from "i18next";

export class DetailRelated extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <>
                <Listing t={t}
                    className="related-listing"
                    urlBinding={false}
                    filters={false}
                    topSection={false}
                    GAGroup={i18next.t("partials.detail.detailRelated.title")}
                    source={`car-posts/detail/${this.props.postId}/similar`}
                    //source="/dummy/data/detail-related.json"
                    query={this.props.postId} size={5} showAds={false}/>

            </>
        )
    }
}
