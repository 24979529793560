import React from "react";
// Partials
import Image from '../../partials/image'
import Btn from '../../partials/btn'
import PopInfo from '../../partials/popinfo.js'
import Collapse from '../../partials/collapse.js'
import Link from '../../partials/link.js'
import PriceTag from '../../partials/price-tag'
import {DetailCredit} from '../../partials/detail'
// Deps
import {openModal} from "../../../functions/modals"

// Assets
import image_avatar from '../../../assets/images/defaults/avatar.svg';

// Multi language

import "../../../i18n";
import i18next from "i18next";



const ncapDescriptions = [
    "partials.detail.detailInfo.ncapDescriptions.0",
    "partials.detail.detailInfo.ncapDescriptions.1",
    "partials.detail.detailInfo.ncapDescriptions.2",
    "partials.detail.detailInfo.ncapDescriptions.3",
    "partials.detail.detailInfo.ncapDescriptions.4",
];

export class DetailInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showCosts: false,
            showOtvModule: false,
            showDealers: false,
            selectedBranch: false,
        }
        this.setVehicleToReservation = this.setVehicleToReservation.bind(this)
    }

    setVehicleToReservation(product){
        if(this.props.user){
            const {setVehicleToReservation} = this.props;
            setVehicleToReservation(product);
            this.props.history.push(`/rezervasyon/${this.props.product.postNo}`)
        }
        else{
            openModal('login')
        }
    }

    render() {
        const { t } = this.props;
        let vm = this;
        let product = vm.props.product;
        let selectedBranch = vm.state.selectedBranch;

        return (
            <div className="detail-info">
                <h1 className="info-title">{product.title}</h1>
                {(product.mainFeatures && product.mainFeatures.length) &&
                <ul className="info-mainfeatures">
                    {product.mainFeatures.map((feature, nth) => (
                        <li key={nth}>
								<span title={feature.title + ': ' + feature.label}>
									{feature.icon && <i className={"icon-" + feature.icon}/>}
                                    <label>{feature.label}</label>
								</span>
                        </li>
                    ))}
                </ul>
                }
                {(product.highlights && vm.props.mobile) &&
                <ul className="info-highlights">
                    {product.highlights.map((highlight, nth) => (
                        <React.Fragment key={nth}>
                            {
                                highlight.label && (
                                    <li key={nth} title={highlight.title}>{(highlight.image ?
                                            <Image alt={highlight.title} src={`/dummy/images/${highlight.image}`} />
                                            :
                                            <span>{highlight.label}</span>
                                    )}</li>
                                )
                            }
                        </React.Fragment>
                    ))}
                </ul>
                }
                {product.ncap &&
                    <div className="info-ncap">
                        <strong title={t("partials.detail.detailInfo.titles.1")}>{t("partials.detail.detailInfo.titles.0")}</strong>
                        <div className="ncap-stars" title={product.ncap + t("partials.ncapStars.title")}>
                            <i className={"icon-star" + (product.ncap >= 1 ? ' active' : '')}/>
                            <i className={"icon-star" + (product.ncap >= 2 ? ' active' : '')}/>
                            <i className={"icon-star" + (product.ncap >= 3 ? ' active' : '')}/>
                            <i className={"icon-star" + (product.ncap >= 4 ? ' active' : '')}/>
                            <i className={"icon-star" + (product.ncap >= 5 ? ' active' : '')}/>
                        </div>
                        <PopInfo className="ncap-info" wide content={t(ncapDescriptions[product.ncap - 1])}>
                            <i className="icon-question"/>
                        </PopInfo>
                    </div>
                }

                <div className="info-price">
                    <strong className="price-current">
                        {<PriceTag price={product.price} />}
                    </strong>
                    {(product.listingPrice && product.price > 0 && (product.listingPrice > product.price)) &&
                    <div className={"price-listing" + ((product.listingPrice > product.price) ? ' higher' : '')}>
                        <strong>Liste Fiyatı:</strong> <span style={{ textDecoration: parseInt(product.listingPrice, 10) > parseInt(product.price, 10) ? 'line-through' : 'none' }}>
								<PriceTag stroke={parseInt(product.listingPrice, 10) > parseInt(product.price, 10)} price={parseInt(product.listingPrice, 10) > parseInt(product.price, 10) ? product.listingPrice : product.price} /></span>
                    </div>
                    }
                </div>

              {/*  {(product.price > 0 && product.costs.expenses.length) &&
                <div className="info-costs">
                    <button className="costs-sum" type="button" onClick={() => { vm.setState({ showCosts: !vm.state.showCosts }) }}><strong>Bu aracın yıllık kullanım maliyeti:</strong> <PriceTag price={product.costs.total} /></button>
                    <Collapse className="costs-wrap" open={vm.state.showCosts}>
                        <ul className="costs-list">
                            {product.costs.expenses.map((cost, nth) => (
                                <React.Fragment key={nth}>
                                    {
                                        (cost.cost > 0) && (
                                            <React.Fragment>
                                                <li className="list-cost" key={nth}>
                                                    <strong>
                                                        {cost.title}
                                                        {nth === 1 &&
                                                        <PopInfo className="cost-info" content="İlk defa araç sahibi olanlar için öngörülen ortalama fiyattır" nowrap>
                                                            <i className="icon-question"></i>
                                                        </PopInfo>
                                                        }
                                                    </strong>
                                                    <span className="cost-num"><PriceTag price={cost.cost} /></span>
                                                </li>

                                            </React.Fragment>
                                        )

                                    }
                                </React.Fragment>
                            ))}
                            <li className="list-cost total">
                                <strong>
                                    Toplam
                                    <PopInfo className="cost-info" content="İstanbul Sanayi Odası'ndan alınmıştır" nowrap>
									<i className="icon-question"></i>
										</PopInfo>
                                </strong>
                                <span className="cost-num"><PriceTag price={product.costs.total} /></span>
                            </li>
                        </ul>
                    </Collapse>
                </div>
                }*/}
               {/* <div className="info-controls">
                    <div className="controls-buttons">
                        {(product.status === 1 && product.isReservable === true) &&
                        <Btn className="controls-button reservate" primary hollow uppercase
                             onClick={() => this.setVehicleToReservation(product)}>
                            Rezerve Et
                        </Btn>
                        }
                        {(product.status === 1 && product.isReservable === false) &&
                        <Btn className="controls-button reservate" primary hollow uppercase
                             note="Bu aracı çok yakında rezerve edebileceksiniz."
                             onClick={() => this.setVehicleToReservation(product)} disabled>
                            Rezerve Et
                        </Btn>
                        }
                        {product.status === 2 &&
                            <Btn className="controls-button reservate" primary hollow uppercase
                                 note="Bu araç rezerve edilmiştir."
                                 disabled
                                 >
                                Rezerve Edilmiştir
                            </Btn>
                        }

                        {product.status === 3 &&
                        <Btn className="controls-button reservate" primary hollow uppercase
                             note="Bu araç satılmıştır.Rezerve edilemez."
                             disabled
                        >
                            Satıldı
                        </Btn>
                        }

                        <Btn className="controls-button reservate" primary hollow uppercase note="Bu aracı çok yakında rezerve edebileceksiniz." disabled={true}>
                            Rezerve Et
                        </Btn>
                        {
                            ((vm.props.mobile && product.dealer.phone) ?
                                    <a
                                        className="controls-phone"
                                        onClick={()=>{
                                            GA.send('conversion', {
                                                action: 'callDealer',
                                            });
                                        }}
                                        href={"tel:+9" + product.dealer.phone.replace(' ', '')}>
                                        <i className="icon-phone-nude"></i>
                                    </a>
                                    :
                                    <React.Fragment>
                                        {
                                            ((vm.props.mobile && product.dealerPhone) &&
                                                <a
                                                    className="controls-phone"
                                                    onClick={()=>{
                                                        GA.send('conversion', {
                                                            action: 'callDealer',
                                                        });
                                                    }}
                                                    href={"tel:+9" + product.dealerPhone.replace(' ', '')}>
                                                    <i className="icon-phone-nude"></i>
                                                </a>
                                            )
                                        }
                                    </React.Fragment>
                            )

                        }
                        {(product.bidThreadId)
                            ?
                            <Btn className="controls-button bid" note="Bu araç için daha önce teklif verdiniz." primary uppercase tag="a" href={`/hesabim/mesajlarim/mesaj/${product.bidThreadId}`}>Tekliflerim</Btn>
                            :
                            <Btn disabled={(product.status === 3 || product.price === null)} className="controls-button bid" onClick={() => openModal('bid', { advert: product })} primary uppercase note={product.status === 3 ? 'Bu araç "satıldı".' : (product.price === null ? 'Fiyat için bayi ile iletişime geçebilirsiniz' : 'Bu araç için teklif verebilirsiniz.')}>
                                {product.status === 3 ? 'SATILDI' : 'Teklif Ver'}
                            </Btn>}

                    </div>

                    {(product.reserved && product.reservationEndDate) &&
                    <span className="controls-note">{product.reservationEndDate}'a kadar rezerve edilmiştir.</span>
                    }
                </div>*/}

                {
                    !product.dealer.list ?
                        (
                            <React.Fragment>
                                {
                                    product.dealer &&
                                    <div className="info-dealer">
                                        <div className="dealer-head">
                                            <Image className="dealer-image" bg
                                                   src={product.dealer.logo} />
                                            <div className="head-content">
                                                <Link href={i18next.t(`routes:dealers`, { lng: i18next.resolvedLanguage })+`/${product.dealer.dealerUrl}`}>

                                                    <strong className="dealer-title">
														<span className="dealer-badge">
															<PopInfo nowrap upsideDown content={i18next.t("partials.detail.detailInfo.titles.3")} noMarginLeft>
																<i className="badge-bg icon-ribbon"></i><i className="badge-icon icon-check"></i>
															</PopInfo>
														</span>

                                                        {product.dealer.title}
                                                    </strong>
                                                </Link>
                                                <p className="dealer-info">

                                                    <span className="info-location">{i18next.t("partials.detail.detailInfo.location")}</span>
                                                     {/*  <span className="info-location">{product.dealer.location} ilan bilgilerinin güncel hali bu sitededir</span>
                                                        {(product.dealer.workingHours !== null) && (
                                                        <span className={"info-workinghours " + (product.dealer.open ? 'open' : 'closed')}>
															{product.dealer.workingHours}
                                                            {product.dealer.workingHours && <span>|</span>}

                                                            {(product.dealer.open ? 'Şu an açık' : 'Şu an kapalı')}
														</span>
                                                    )}*/}

                                                </p>
                                            </div>
                                        </div>
                                        <div className="dealer-controls">
                             {/*               <Btn uppercase block onClick={this.props.toggleRedirectClicked}>İlana Git</Btn>*/}


                                            <Link href={i18next.t(`routes:redirect`, { lng: i18next.resolvedLanguage })+`?postNo=${this.props.product.postNo}&id=${this.props.product.id}`}   style={{width:"100%"}}>
                                                <Btn uppercase block >{i18next.t("partials.detail.detailInfo.button")}</Btn>
                                            </Link>



                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {
                                    product.dealer.list && (
                                        <div className="info-dealers" style={{ marginTop: '1rem' }}>
                                            <button className="dealers-sum" type="button" onClick={() => { vm.setState({ showDealers: !vm.state.showDealers }) }}><strong>{t("partials.detail.detailInfo.titles.2")} ({product.dealer.list.length}):</strong> </button>
                                            <Collapse className="dealers-wrap" open={vm.state.showDealers}>
                                                <ul className="dealers-list">
                                                    {product.dealer.list.map((dealerLoc, nth) => (
                                                        <React.Fragment key={nth}>
                                                            {
                                                                (dealerLoc) && (
                                                                    <React.Fragment>
                                                                        <li className="list-cost" key={nth} style={{ paddingTop: nth === 0 ? '' : '0' }}>
                                                                            <div className="info-dealers" style={{ width: '100%', padding: '0' }}>
                                                                                <button onClick={() => { vm.setState({ selectedBranch: dealerLoc, showDealers: !vm.state.showDealers }) }}>{dealerLoc.title}</button>
                                                                            </div>
                                                                        </li>

                                                                    </React.Fragment>
                                                                )

                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                            </Collapse>
                                            <React.Fragment>
                                                {
                                                    selectedBranch && (
                                                        <div className="info-dealer">
                                                            <div className="dealer-head">
                                                                <Image className="dealer-image" bg src={product.dealer.image} />
                                                                <div className="head-content">
                                                                    <Link href={i18next.t(`routes:dealers`, { lng: i18next.resolvedLanguage })+`/${product.dealer.dealerUrl}`}>
                                                                        {selectedBranch.validated ?
                                                                            <span className="dealer-badge"><i className="badge-bg icon-ribbon"></i><i className="badge-icon icon-check" ></i></span>
                                                                            :
                                                                            false
                                                                        }
                                                                        <strong className="dealer-title">{selectedBranch.title}</strong>
                                                                    </Link>
                                                                    <p className="dealer-info">
                                                                        <span className="info-location">{t("partials.detail.detailInfo.location")}</span>
                                                                       {/* <span className="info-location">{selectedBranch.location}</span>
                                                                        <span className={"info-workinghours " + (selectedBranch.open ? 'open' : 'closed')}>
																			{selectedBranch.workingHours}
                                                                            <span>|</span>
                                                                            {(selectedBranch.open ? 'Şu an açık' : 'Şu an kapalı')}
																		</span>*/}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {selectedBranch.rep &&
                                                            <div className="dealer-rep">
                                                                <Image src={selectedBranch.rep.image ? selectedBranch.rep.image : image_avatar} className="rep-image" />
                                                                <strong className="rep-title">{selectedBranch.rep.name}</strong>
                                                                <span className="rep-role">{selectedBranch.rep.role}</span>
                                                            </div>
                                                            }
                                                            <a href={`/redirect?postNo=${this.props.product.postNo}&id=${this.props.product.id}`}  style={{width:"100%"}}>
                                                                <Btn uppercase block >{i18next.t("partials.detail.detailInfo.button")}</Btn>
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            </React.Fragment>
                                        </div>
                                    )

                                }
                            </React.Fragment>
                        )
                }
                {(!vm.props.mobile && product.carCredits )&& <DetailCredit t={t} product={product} mobile={vm.props.mobile} />}

            </div>
        )
    }
}
