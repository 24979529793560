import React from "react";
import {Link} from 'react-router-dom'


// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";
import i18next from "i18next";

class TagItem extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <li className={"results-item"}>
                <div className="banner-tag">
                    <div className="tags-wrapper">
                        <div key={t("partials.tagItem.key")} className="tag-wrapper">
                            <Link to={i18next.t(`routes:search`, { lng: i18next.resolvedLanguage })}>{t("partials.tagItem.title")}</Link>
                        </div>
                        {Object.keys(this.props.data.tags).map((key,nth)=>(
                            <div key={nth} className="tag-wrapper">
                                <Link to={i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage })+`/${key}`}>{this.props.data.tags[key]}</Link>
                            </div>
                        ))}
                    </div>
                </div>
            </li>

        )
    }
}
export default (withTranslation()(TagItem))
