import React from 'react'


// Partials
import Link from 'components/partials/link'
import Image from 'components/partials/image'
import Btn from 'components/partials/btn'

// Deps
import { openModal } from 'functions/modals'
import { storageSpace } from "functions/helpers"
import config from "data/config"

import YoutubeWrapper from "./youtube-wrapper";

// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";
import i18next from "i18next";



// Banners
class InstagramBanner extends React.Component {

	render() {


		return (

		<a className="banner-instagram"  rel="noopener noreferrer" target={"_blank"} href={config.social.instagramURL}>

				<div className="instagram-text">
					<strong>{i18next.t("partials.listingBanner.ınstagramBanner.0")}</strong>{i18next.t("partials.listingBanner.ınstagramBanner.1")}<br />

					{i18next.t("partials.listingBanner.ınstagramBanner.2")}<br />
					{i18next.t("partials.listingBanner.ınstagramBanner.3")}
				</div>


			</a>
		)
	}
}

class BlogBanner extends React.Component {

	render() {


		return (

			<a className="banner-blog" href={i18next.t(`routes:blogComponent`, { lng: i18next.resolvedLanguage })}>

				<div className="blog-text">
					<strong>{i18next.t("partials.listingBanner.blogBanner.0")}</strong>{i18next.t("partials.listingBanner.blogBanner.1")}<br />
					{i18next.t("partials.listingBanner.blogBanner.2")}
				</div>

			</a>
		)
	}
}

class YoutubeBanner extends React.Component {

	render() {

		return (
			<div onClick={()=>setTimeout(this.props.setAnyFilter({yakit:i18next.resolvedLanguage==='tr' ? 'elektrik' : 'elektro'}),50)} className="electric-img" style={{backgroundImage:'/assets/images/ElectricCar.jpg'}}>
				<div className="electric-text">
					<strong>{i18next.t("partials.listingBanner.youtubeBanner.0")}</strong><br />
					{i18next.t("partials.listingBanner.youtubeBanner.1")}
				</div>

			</div>
		)
	}
}

class HrBanner extends React.Component {
	render() {

		return (
			<div className="banner-hr">
				<div className="hr-content">
					<div className="inner-triangle"></div>
					<h3 className="content-title">
						{i18next.t("partials.listingBanner.hrBanner.0")}
					</h3>
					<p className="content-description">
						{i18next.t("partials.listingBanner.hrBanner.1")} <br />
						<strong>{i18next.t("partials.listingBanner.hrBanner.2")}</strong> {i18next.t("partials.listingBanner.hrBanner.3")}
					</p>

					<Btn href="mailto:merhaba@minoto.com?subject=Minoto’da Çalışmak İstiyorum"  rel="noopener noreferrer" className="content-cta" tag="a" uppercase wide>{i18next.t("partials.listingBanner.button")}</Btn>
				</div>
			</div>
		)
	}
}

class IframeBanner extends React.Component {
	render() {

		return (
			<div className="banner-iframe">
				<YoutubeWrapper videoId={this.props.data.url}/>
			</div >
	)
	}
}


const componentRegistry = {
	"instagram": InstagramBanner,
	"youtube": YoutubeBanner,
	"hr": HrBanner,
	"iframe": IframeBanner,
	"blog": BlogBanner
}

class ListingBanner extends React.Component {
	render() {


		let data = this.props.data;
		let elem = false;

		if (data.component && componentRegistry.hasOwnProperty(data.component)) {
			let El = componentRegistry[data.component];
			elem = <El mobile={this.props.mobile} data={this.props.data} setAnyFilter={this.props.setAnyFilter}/>

		}
		else if (data.image) {
			let Item = 'div';
			let props = {
				className: "item-banner"
			};

			if (data.url) {
				switch (data.action) {
					case "youtube":
						Item = 'button';
						props.onClick = () => { openModal('youtube', { url: data.url }) }
						break;
					case "externalLink":
						Item = 'a';
						props.herf = data.url;
						break;
					default:
						Item = Link;
						props.href = data.url;
						break;
				}
			}

			elem = (<Item {...props}><Image className="banner-image" src={storageSpace('promo-videos', data.image)} alt={data.title} bg={!this.props.mobile} /></Item>);

		}
		//console.log(elem)

		return (elem) ? (
			<li className={"results-item"}>
				{elem}
			</li>
		) : false;
	}
}
export default (withTranslation()(ListingBanner))
