import React from 'react'

// Sections
import Listing2 from 'components/sections/listing2.js'
import Redirect from 'pages/redirect'

// Partials
import Image from 'components/partials/image.js'
import SearchBar from 'components/partials/searchbar.js'
import request from 'controllers/request'
import history from 'controllers/history'
import {openModal} from "../functions";
//Deps

//Multi language
import {withTranslation} from 'react-i18next';
import "../i18n";
import i18next from "i18next";

const matchParams = [
    'brand',
    'model',
    'optdata1',
    'optdata2',
    'optdata3'
]

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bannerData: false,
            loading: true,
        }

        this.initialize = this.initialize.bind(this);
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.initialize();
        setTimeout(() => {
            openModal('image')
        }, 5000)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scroll(0, 0);
            this.initialize();
        }
    }

    initialize() {
        let vm = this;

        /*if (history.location.pathname === '/') {*/
        if (history.location.pathname) {

            request.get('homepage-slide?language=' + i18next.resolvedLanguage, null, function (payload) {
                vm.setState({
                    bannerData: payload,
                })
            });
        } else {
            vm.setState({
                bannerData: false,
            })
        }

    }

    render() {
        const {t} = this.props;
        let banner = this.state.bannerData;

        let route = "";
        for (let k = 0; k < matchParams.length; k++) {
            if (this.props.match.params[matchParams[k]]) {
                route += '/' + this.props.match.params[matchParams[k]];
            } else {
                break;
            }
        }

        return (

            <main className="page home">

                <React.Fragment>

                    {(banner && ( !window.location.pathname.includes(i18next.t("routes.pages.redirect.path"))  )) && (
                        <section className="section home-intro">
                            <div className="intro-content">
                                <h1 className={`intro-title ${banner.text_color === 'white' ? 'forceWhite' : ''}`}
                                    style={{opacity: '1'}}>
                                    {
                                        banner.title.charAt(0) === '[' ? (
                                            <React.Fragment>
												<span className="colored">
													{banner.title.substring(banner.title.lastIndexOf("[") + 1, banner.title.lastIndexOf("]"))}
												</span>{' '}
                                                {
                                                    banner.title.substring(banner.title.lastIndexOf("]") + 2, banner.title.length)
                                                }
                                            </React.Fragment>
                                        ) : banner.title}

                                    <strong className="subtitle">{banner.label}</strong>
                                </h1>
                                <SearchBar className="intro-search"/>
                            </div>


                            {i18next.resolvedLanguage === "tr" ?
                                <Image
                                    className="intro-bg"
                                    bg
                                    //src={this.props.location.hash.substr(1) === 'volvo' ? image_home_banner : image_home_banner2}
                                    //mobile={this.props.location.hash.substr(1) === 'volvo' ? image_home_banner_mobile : image_home_banner_mobile2}
                                    src='/assets/images/BannerLow.jpg'
                                    mobile='/assets/images/Mobil_YükseklikFarklı.jpg'
                                />
                                : <Image
                                    className="intro-bg"
                                    bg
                                    //src={this.props.location.hash.substr(1) === 'volvo' ? image_home_banner : image_home_banner2}
                                    //mobile={this.props.location.hash.substr(1) === 'volvo' ? image_home_banner_mobile : image_home_banner_mobile2}
                                    src='/assets/images/minoto-banner-de.jpg'
                                    mobile='/assets/images/minoto-banner-de.jpg'
                                />
                        }

                            {i18next.resolvedLanguage === "tr" ?
                            <Image
                                className="altin-orumcek"
                                src="/assets/images/AltınÖrümcek.png"
                            />
                            :    <Image
                                    className="altin-orumcek"
                                    src=""
                                /> }

                        </section>
                    )}

                    {
                        window.location.pathname === '' ||   window.location.pathname === 'de/'   ? (

                            <Listing2 className="search-listing"
                                      source="filters"
                                      defaultOrder="random"
                                      query={false}
                                      scrollOnFilterChange
                                      title={t("pages.home.listingTitle")}
                                      showMoreBrands={true}
                            />


                        ) : window.location.pathname.includes(`${i18next.t("routes.pages.redirect.path")}`) ?
                            <Redirect/>
                           :(
                                <Listing2
                                    className="search-listing"
                                    // -- bu eski source={`car-posts/filter${route}`}
                                    // -- bu gülşahın yarın oluşturacağı url source={`car-data/filters${route}`}
                                    source={`filters${route}`}
                                    query={false}
                                    //key="search-brand-deep"
                                />
                            )
                    }

                </React.Fragment>

            </main>
        )
    }
}

export default (withTranslation()(Home))
