import React from 'react'

// Partials
import { InputForm, FormInput } from 'components/partials/forms'
import Btn from 'components/partials/btn'

// Deps
import { openModal } from 'functions/modals'
import { redirect } from 'controllers/navigator'
import { serializeArray } from "functions/helpers";
import request from 'controllers/request'

// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";
class RecoveryForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			submitting: false,
			complete: false,
			email: false,
			message: false,
		}

		this.submit = this.submit.bind(this);
		this.reset = this.reset.bind(this);
	}

	submit(e) {
		let vm = this;

		let formData = serializeArray(e.target);

		this.setState({
			submitting: true,
			email: formData.email,
		});

		request.get('users/forgot-password/'+formData.email, {}, function(payload){
			if(payload && payload.success){
				vm.setState({
					submitting: false,
					complete: true,
				});
			}
			else{
				vm.setState({
					submitting: false,
					message: (payload && payload.message) ? payload.message : false,
				});
			}
		});
	}

	reset() {
		this.setState({
			submitting:false,
			complete:false,
			email: false
		});
	}

	goToLogin() {
		if(this.props.type === "self"){
			redirect('account.login');
		}
		else {
			openModal('login')
		}
	}

	render() {
		const { t } = this.props;
		let vm = this;
		if(vm.state.complete){
			return (
				<div className={"section loginform type-" + vm.props.type}>
					<h2 className="loginform-title">{t("partials.formRecovery.title")}</h2>

					<div className="loginform-info wysiwyg">
						<p><strong>{vm.state.email} </strong>{t("partials.formRecovery.text.0")}</p>
						<p><strong> {t("partials.formRecovery.text.1")}</strong></p>
						<ul>
							<li> {t("partials.formRecovery.text.2")}</li>
							<li> {t("partials.formRecovery.text.3")}</li>
							<li>{t("partials.formRecovery.text.4")}</li>
						</ul>
					</div>
					<div className="loginform-nav center">
						<button type="button" className="nav-btn" onClick={this.reset}><i className="icon-arrow-left"></i> {t("partials.formRecovery.button")}</button>
					</div>
				</div>
			)
		}
		else {
			return (
				<div className={"section loginform type-" + vm.props.type}>
					<h2 className="loginform-title">{t("partials.formRecovery.form.title")}</h2>

					<InputForm className="loginform-form" onSubmit={vm.submit}>
						{vm.state.message &&
							<div className={"loginform-message " + (vm.state.success ? 'success' : 'error')}>
								<span>{vm.state.message}</span>
							</div>
						}
						<FormInput
							name="email"
							type="email"
							label={t("partials.formRecovery.form.formItems.0.placeholder")}
							disabled={vm.state.submitting}
							validation={{required: t("partials.formRecovery.form.formItems.0.validations.0"), email: true}}
							className="form-field" />
						<Btn
							className="form-field"
							block uppercase light
							loading={vm.state.submitting}
							disabled={vm.state.submitting}
							type="submit">
							{t("partials.formRecovery.button2")}
						</Btn>
					</InputForm>
					<div className="loginform-nav center">
						<button type="button" className="nav-btn" onClick={() => { vm.goToLogin() }}><i className="icon-arrow-left"></i> {t("partials.formRecovery.button")}</button>
					</div>
				</div>
			)
		}
	}
}

RecoveryForm.defaultProps = {
	type: "self"
}
export default (withTranslation()(RecoveryForm))