import React from 'react'
// Partials
import Image from '../components/partials/image.js'
// Deps
import {connect} from "react-redux";
//Functions
import {addVehicleToCompare, deleteVehicleFromCompare, setVehicleToReservation} from "../actions";
//Components
import Link from '../components/partials/link.js'
import PriceTag from "../components/partials/price-tag";
// Assets
import image_car_default from '../assets/images/defaults/car.svg'
import DealerInfoCard from "../components/partials/dealer-info-card";
import NcapStars from "../components/partials/ncap-stars";
// Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from 'i18next'

class AdCompare extends React.Component {
    constructor(props) {
        super(props);

        this.setVehicleToReservation = this.setVehicleToReservation.bind(this)
    }


    setVehicleToReservation(product) {
        const {setVehicleToReservation} = this.props;
        setVehicleToReservation(product);
        this.props.history.push(`/rezervasyon/${product.postNo}`)
    }

    render() {
        const { t } = this.props;
        const compareList = this.props.compareList.data;
        return <main className="page  comparison">
            {compareList.length>1 ?(
                <section className="section comparison  minoto-ui">
                    <div className="wrapper ad-compare">
                        <h1 className="comparison-title">{t("pages.adCompare.title")}</h1>
                        <div className="comparison-tablewrap">
                            <table className="comparison-table ad-compare-table ">
                                <tbody>
                                {/*Table header start*/}
                                <tr className='ad-compare-table__header'>
                                    {compareList.map((car, nth) => (
                                        <td className="picker" key={nth}>
                                            <div className='ad-compare-table__header__labelArea'>
                                                <div>
                                                    <ul className="topinfo-highlights">
                                                        {car.highlights.map((highlight, nth) => (
                                                            <React.Fragment key={nth}>
                                                                {
                                                                    highlight.label && (
                                                                        <li key={nth} title={highlight.title}>{(highlight.image ?
                                                                                <Image alt={highlight.title} src={`/dummy/images/${highlight.image}`}/>
                                                                                :
                                                                                <span>{highlight.label}</span>
                                                                        )}</li>
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        ))}                                                    </ul>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={() => this.props.deleteVehicleFromCompare(car.id)}>{t("pages.adCompare.button")} ⓧ
                                                    </button>
                                                </div>

                                            </div>
                                            <div className='ad-compare-table__header__titleArea'>
                                                {/* todo: Link to product detail page */}
                                                <Link href={car.slug} className='ad-compare-table__header__titleArea-title'>
                                                    <h1>
                                                        {car.title}
                                                    </h1>
                                                </Link>

                                                <Link href={"dealer"}
                                                      params={{slug: car.dealer.url}}>
                                                    {car.dealer.title}
                                                </Link>
                                                <div>
                                                    <h4>{t("pages.adCompare.subtitle")}</h4> <h4>{car.postNo}</h4>
                                                </div>
                                            </div>
                                            <div className="table-carpicker">
                                                <div className="carpicker-imagewrap">
                                                    <Image className="carpicker-image" bg
                                                           src={((car && car.image) ? car.image : image_car_default)}/>
                                                </div>
                                            </div>
                                            <div className='ad-compare-table__header__priceArea'>
                                                <strong className="price-current">
                                                    {car.price ? <PriceTag price={car.price} /> : <h2> {t("pages.adCompare.tableTitle")}</h2>}
                                                </strong>
                                               {/* <div className='ad-compare-table__header__priceArea__btnContainer'>
                                                    {car.status === 1 &&
                                                    <Btn className="controls-button reservate" primary hollow uppercase
                                                         note="Bu aracı çok yakında rezerve edebileceksiniz."
                                                         onClick={() => this.setVehicleToReservation(car)} disabled>
                                                        Reserve Et
                                                    </Btn>
                                                    }
                                                    {car.status === 2 &&
                                                    <Btn className="controls-button reservate" primary hollow uppercase
                                                         note="Bu araç rezerve edilmiştir."
                                                         disabled
                                                    >
                                                        Araç Satılmıştır
                                                    </Btn>
                                                    }

                                                    {car.status === 3 &&
                                                    <Btn className="controls-button reservate" primary hollow uppercase
                                                         note="Bu araç satılmıştır.Rezerve edilemez."
                                                         disabled
                                                    >
                                                        Satıldı
                                                    </Btn>
                                                    }
                                                    {(car.bidThreadId)
                                                        ?
                                                        <Btn className="controls-button bid"
                                                             note="Bu araç için daha önce teklif verdiniz." primary
                                                             uppercase tag="a"
                                                             href={`/hesabim/mesajlarim/mesaj/${car.bidThreadId}`}>Tekliflerim</Btn>
                                                        :
                                                        <Btn disabled={(car.status === 3 || car.price === null)}
                                                             className="controls-button bid"
                                                             onClick={() => openModal('bid', {advert: car})} primary
                                                             uppercase
                                                             note={car.status === 3 ? 'Bu araç "satıldı".' : (car.price === null ? 'Fiyat için bayi ile iletişime geçebilirsiniz' : 'Bu araç için teklif verebilirsiniz.')}>
                                                            {car.status === 3 ? 'SATILDI' : 'Teklif Ver'}
                                                        </Btn>}
                                                </div>*/}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                {/*Table header End*/}
                                <tr className='ad-compare-table-techSpec'>
                                    {compareList.map((car, nth) =>
                                        <td>
                                        <React.Fragment key={nth}>
                                            {car.ncap &&
                                            <React.Fragment>
                                                <span>{t("pages.adCompare.tBody.0")}</span>
                                                <NcapStars ncap={car.ncap} info={false}/>
                                            </React.Fragment>
                                            }
                                        </React.Fragment>
                                    </td>)}
                                </tr>
                                <tr className='bg-gray ad-compare-table-techSpec'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                    <span>{t("pages.adCompare.tBody.1")}</span>
                                                {car.technicalSpecs[0].specs[0].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                    <span>{t("pages.adCompare.tBody.2")}</span>
                                                {car.technicalSpecs[0].specs[1].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec bg-gray'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.3")}</span>
                                                {car.technicalSpecs[2].specs[0].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec '>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.4")}</span>
                                                {car.technicalSpecs[0].specs[7].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec bg-gray'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.5")}</span>
                                                {car.technicalSpecs[0].specs[5].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                {/* todo: Ask production date  */}
                                {/*<tr>*/}
                                {/*    <th>Üretim Yılı (İlk / Son)</th>*/}
                                {/*    {this.state.cars.map((car, nth) => (*/}
                                {/*        <CarData car={car} key={nth} nth={nth} fragment="engine" />*/}
                                {/*    ))}*/}
                                {/*</tr>*/}
                                <tr className='ad-compare-table-techSpec'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                    <span>{t("pages.adCompare.tBody.6")}</span>
                                                {car.technicalSpecs[1].specs[6].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec bg-gray'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                    <span>{t("pages.adCompare.tBody.7")}</span>
                                                {car.technicalSpecs[1].specs[2].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>

                                <tr className='ad-compare-table-techSpec'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.8")}</span>
                                                {car.technicalSpecs[0].specs[4].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec bg-gray'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                    <span>{t("pages.adCompare.tBody.9")}</span>
                                                {car.technicalSpecs[0].specs[2].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.10")}</span>
                                                {car.technicalSpecs[1].specs[4].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec bg-gray'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.11")}</span>
                                                {car.technicalSpecs[2].specs[1].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.12")}</span>
                                                {car.technicalSpecs[2].specs[2].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec bg-gray'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.13")}</span>
                                                {car.technicalSpecs[2].specs[3].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.14")}</span>
                                                {car.technicalSpecs[2].specs[4].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr className='ad-compare-table-techSpec bg-gray'>
                                    {compareList.map((car, nth) => (
                                        <React.Fragment>
                                            <td key={nth}>
                                                <span>{t("pages.adCompare.tBody.15")}</span>
                                                {car.technicalSpecs[2].specs[5].content}
                                            </td>
                                        </React.Fragment>
                                    ))}
                                </tr>
                               {/* <tr className='ad-compare-table__creditCalculator'>
                                    {compareList.map((car, nth) => (
                                        <td key={nth}>
                                            <CreditCalculator product={car}/>
                                        </td>
                                    ))}
                                </tr>*/}
                                <tr className='ad-compare-table__contactCard'>
                                    {compareList.map((car, nth) => (
                                        <td>
                                            <DealerInfoCard product={car}/>
                                        </td>
                                    ))}
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            ) :
                window.location.replace(i18next.t(`routes:/`, { lng: i18next.resolvedLanguage }))
            }
        </main>
    }
}


const mapStateToProps = ({generic, user, compareList, reservation}) => {
    return {mobile: generic.mobile, user: user.user, compareList, reservation};
};

const mapDispatchToProps = dispatch => {
    return {
        addVehicleToCompare: (data) => dispatch(addVehicleToCompare(data)),
        deleteVehicleFromCompare: (data) => dispatch(deleteVehicleFromCompare(data)),
        setVehicleToReservation: (data) => dispatch(setVehicleToReservation(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdCompare))
