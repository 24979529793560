import React from 'react';

// Partials
import {FormInput} from 'components/partials/forms'
// Sections
import ListingResults from "./listing-results";
// Deps
/*import history from 'controllers/history'*/
import {connect} from "react-redux";
import {setFilterQuery, setFiltersExpansion, setListingData, setListingQuery} from 'data/store.listing';
import image_loader from 'assets/images/minoto-loading.gif'
import axios from "axios";
import {useEffect, useState} from "react";
import ListingFilters from "./listing-filters";
import { useParams} from 'react-router-dom'
import i18next from "i18next";

import { useHistory } from "react-router-dom";

const mapStateToProps = state => {
    return {
        mobile: state.generic.mobile,
        listingData: state.listing.listingData,
        filterData: state.listing.filterData,
        filterQuery: state.listing.filterQuery,
        listingQuery: state.listing.listingQuery,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        expandFilters: () => dispatch(setFiltersExpansion(true)),
        setListingQuery: (query) => dispatch(setListingQuery(query)),
        setFilterQuery: (query) => dispatch(setFilterQuery(query)),
        setListingData: (data) => dispatch(setListingData(data)),
    }
}
const orderOptions = [
    { value: 'random', label: i18next.t("sections.listing.orderOptions.0")   },
    { value: 'price_desc', label:i18next.t("sections.listing.orderOptions.1")   },
    { value: 'price_asc', label: i18next.t("sections.listing.orderOptions.2")   },
    { value: 'date_desc', label: i18next.t("sections.listing.orderOptions.3")  },
    { value: 'date_asc', label: i18next.t("sections.listing.orderOptions.4")  }
]
const orderObject =
    { normal: i18next.t("sections.listing.orderOptions.0") ,
        price_desc: i18next.t("sections.listing.orderOptions.1")  ,
        price_asc: i18next.t("sections.listing.orderOptions.2") ,
        date_desc: i18next.t("sections.listing.orderOptions.3") ,
        date_asc: i18next.t("sections.listing.orderOptions.4")
    }


function Listing2(props) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const [page,setPage] = useState(1);
    const [totalPage,setTotalPage] = useState(0);
    const [listLoading,setListLoading]= useState(false)
    const [orderVal,setOrderVal] = useState(null)
    const [carData,setCarData] = useState(null)
    const [bannerData,setBannerData] = useState(null)
    const [fireMe,setFireMe] = useState(false)
    const [,setTotalResults] = useState([])
    const [pagesData, setPagesData] = useState([])
    const [selectedFilterString,setSelectedFilterString] = useState('')
    const [holdingFilters,setHoldingFilters] = useState('');
    const [brandHolder,setBrandHolder] = useState('');
    const paramObject = useParams();
    let history = useHistory();
    useEffect(()=>{
        let {brand, model} = paramObject
        if(model) {
            brand+="&model="+model
        }
        setBrandHolder(brand)
        if(window.location.pathname.includes(`${i18next.t("routes.pages.brands.mark")}`)){
            let brand = props.source.substring(props.source.indexOf('/')+1)
            setHoldingFilters([])
            setBrandHolder(brand)
            setSelectedFilterString('');
            getCars(1, orderVal, "", brand)
        }
        else if(window.location.pathname.includes(`${i18next.t("routes.pages.dealers.dealers")}`)){
            let bayi = props.source.split('/')[1]
            setSelectedFilterString(`&bayi=${bayi}`)
            setHoldingFilters([])
            getCars(1, orderVal, `&bayi=${bayi}`, "")
        }
        else if(brand){
            setSelectedFilterString('');
            if(window.location.search){
                changePage(1,orderVal,window.location.search.replace('?','&'),brand)
            }else{
                changePage(1,orderVal,"",brand)
            }
        }else {
            if(pagesData.length>0){
                //window.location.href='/'
            }
            getBanners()
            getCars(1, orderVal, '', '',)
        }

        return()=> {
            setPagesData([])
            setHoldingFilters([])
            setSelectedFilterString("")
            setBrandHolder("")
            setPage(1)
            source.cancel();
        };
    }, [paramObject])

    useEffect(()=>{
        if(page === 1 && (!paramObject.brand) && (!history.location.search)) {
            initData(carData,bannerData)
        }
    },[carData,bannerData])

    useEffect(()=>{
        if(totalPage>=1 && document.querySelector('.footer-brands')){
            //set observer
            let options = {
                root:null,
                rootMargins:"0px",
                threshold:.2
            }
            const observer = new IntersectionObserver(handleIntersect, options)
            observer.observe(document.querySelector('.footer-brands'))
            setListLoading(false)
        }
    },[totalPage])

    const handleIntersect = (entries)=>{
        if(entries[0].isIntersecting){
            setFireMe(prev=>!prev)
        }
    }

    useEffect(()=>{
        if(totalPage){
            if(!listLoading){
                setPage(prev=> prev + 1)
            }
        }
    },[fireMe])

    useEffect(()=>{
        if(page!==1 && totalPage>=page){
            changePage(page, orderVal, '', brandHolder ? brandHolder : '',true)
        }
    },[page])

    const getBanners= async (banner) => {
        setListLoading(true)
        const endpoint = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://api.minoto.com"
        /*  const url = endpoint+"/v1/shared/banners"*/

        const url = endpoint+"/v1/shared/banners?language="+ i18next.resolvedLanguage
        try{
            const response = await axios.get(url,{ cancelToken:source.token })
            const data = await response.data
            setBannerData(data)
        }catch(err){
            if (axios.isCancel(err)) {
              //  console.log('successfully aborted');
            } else {
                console.log(err)
            }
        }
    }

    const getSelectedFilters = (searchParam, data) =>{
        let filterArr = searchParam.substring(1).split('&');
        let filters = {}
        filterArr.map(filter=>{
            filters[filter.split('=')[0]] = filter.split('=')[1]
        })
        data.filters = data.filters.map(filter=>{
            if(filter.display==='range'){
              filter.opts.map(opt=>{
                  if(filters[opt.name]){
                      opt.value=filters[opt.name]
                  }
                  return opt
              })
            }
            return filter
        })
    }

    const getCars = async (newPage,orderValue,filter,brand,justPageChange=false) => {

        let brandString = ""
        if(brand){
            brandString = brand;
        }

        // filter var props.search var props.search arama için
        // başı arama olan rotalar props.search'e ulaşabiliyor
        const endpoint = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://api.minoto.com"
        if(!listLoading){
            setListLoading(true);
            let searchParam=history.location.search ;
            if(!justPageChange && !window.location.pathname.includes(`${i18next.t("routes.pages.dealers.dealers")}`) && !window.location.pathname.includes(`${i18next.t("routes.pages.brands.mark")}`)){

                if(brandString && searchParam ){
                    try{
                        const response = await axios.get(`${endpoint}/v1/shared/item-filters?${brandString && `marka=${brandString}`}${(searchParam&&brandString) ? `&${searchParam.slice(1)}&language=${i18next.resolvedLanguage}`  : (searchParam) ? `${searchParam.slice(1)}&language=${i18next.resolvedLanguage}` :'' }     `,{ cancelToken:source.token })
                        const data = await response.data
                        setHoldingFilters(data)
                    }
                    catch(err){
                        if(axios.isCancel(err)){
                            // console.log('succesfully aborted')
                        }else{
                            console.log(err)
                        }
                    }
                }else {
                    try{
                        const response = await axios.get(`${endpoint}/v1/shared/item-filters?language=${i18next.resolvedLanguage}`)
                        const data = await response.data
                        setHoldingFilters(data)
                        getSelectedFilters(searchParam, data)
                    }
                    catch(err){
                        if(axios.isCancel(err)){
                            // console.log('succesfully aborted')
                        }else{
                            console.log(err)
                        }
                    }

                }

            }


            if(!filter && newPage!==1){
                filter=selectedFilterString
            }

            let url = '';
            if(searchParam){
                if (!orderValue || orderValue === 'normal') {
                    url =  endpoint+`/v1/shared/items?siralama=random&sayfa=${page}&${brandString && `marka=${brandString}&`}${searchParam.slice(1)}${filter && filter}&language=` + i18next.resolvedLanguage
                }else{
                    url = endpoint+`/v1/shared/items?siralama=${orderValue}&sayfa=${page}&${brandString && `marka=${brandString}&`}${searchParam.slice(1)}${filter && filter}&language=` + i18next.resolvedLanguage
                }
            }else{
                if (!orderValue || orderValue === 'normal') {
                    if (filter) {
                        url = endpoint+`/v1/shared/items?siralama=random&${brandString && `marka=${brandString}&`}sayfa=${newPage}${filter}&language=` + i18next.resolvedLanguage
                    } else {
                        url = endpoint+`/v1/shared/items?siralama=random&${brandString && `marka=${brandString}&`}sayfa=${newPage}&language=`+ i18next.resolvedLanguage+  ( window.location.pathname === '/' ||  window.location.pathname === '/de'   ? "&type=home" : "")
                    }
                } else {
                    if(filter){
                        url = endpoint+`/v1/shared/items?siralama=${orderValue}&${brandString && `marka=${brandString}&`}sayfa=${newPage}${filter}&language=`+ i18next.resolvedLanguage
                    } else{
                        url = endpoint+`/v1/shared/items?siralama=${orderValue}&${brandString && `marka=${brandString}&`}sayfa=${newPage}&language=`+ i18next.resolvedLanguage
                    }
                }
            }

            try{
                const response = await axios.get(url,{ cancelToken:source.token })
                const data = await response.data

                if (data.data && data.data.redirect) {
                    setTimeout(function () { window.location.href = data.data.link; }, 30);
                    return;
                }

                if(newPage!==1 || brand || searchParam || filter.includes('bayi')){
                    if(brand || searchParam || filter.includes('bayi')){
                        setTotalPage(data.totalpage)
                    }
                    setTotalResults(prev=>[...prev,data.results])
                    setPagesData(prev=>[...prev,[...data.results]])
                    setListLoading(false)
                }

                setCarData(data)
            }catch(err){
                if(axios.isCancel(err)){
                  //  console.log('succesfully aborted')
                }else{
                    console.log(err)
                }
            }
        }
    }

    const initData = (carData,bannerData) =>{
        if(carData && bannerData && pagesData.length<1){
            let newResults = carData.results;
            if(newResults.length>15){
                newResults = newResults.splice(0,11);
            }
            let bannerDataResults = bannerData.results;
            let deleteIndex = 0 ;
            if(selectedFilterString.includes('elektrik')){
                for(let i=0;i<bannerDataResults.length;i++){
                    if(bannerDataResults[i].component==='youtube'){
                        deleteIndex=i;
                    }
                }
                bannerDataResults.splice(deleteIndex,1)
            }
            setTotalPage(carData.totalpage)
            const holderHoldingFilters = holdingFilters;
            holderHoldingFilters.totalResults = carData.totalResults;
            setHoldingFilters(holderHoldingFilters)
            let randomPlaces = [];
            let gecerli = true;
            let i =0;
            while(randomPlaces.length<5) {
                let randomPlace = Math.floor(Math.random() * 3) + (randomPlaces.length * 3);
                if (randomPlace > 15) randomPlace = 15
                if (!randomPlaces.includes(randomPlace)) {
                    for (let j = 0; j <= randomPlaces.length; j++) {
                        if (randomPlaces[j] - 1 === randomPlace || randomPlaces[j] + 1 === randomPlace) {
                            gecerli = false;
                            break;
                        }
                    }
                    if (gecerli) {
                        randomPlaces.push(randomPlace)
                        newResults.splice(randomPlace, 0, bannerDataResults[i]);
                        i += 1;
                    }
                    gecerli = true;
                }
            }
            setTotalResults(prev=>[...prev,newResults])
            let arr = [[...newResults]]
            setPagesData(arr)
            setListLoading(false)
        }
    }

    const changePage = (newPage,orderValue='normal',filterString=null,brand='',justPageChange=false) => {
        getCars(newPage, orderValue, filterString,brand,justPageChange)
    }

    const setAnyFilter = (filterObject) => {
        if(paramObject.brand){
            let filterString = '';
            Object.keys(filterObject).forEach(function (key, index) {
                if (index === 0) {
                    filterString += `?${key}=${filterObject[key]}`
                } else {
                    filterString += `&${key}=${filterObject[key]}`
                }
            });
            setPage(1)
            setPagesData([])
            setSelectedFilterString(filterString)
            //getBanners('banner')
            i18next.resolvedLanguage === "tr" ?   window.location.href = `/${brandHolder}` + filterString : history.push( `/${brandHolder}${filterString}`)  ;
            //changePage(1,orderVal,filterString,brandHolder ? brandHolder : '',)
            return null;
        }else {
            let search = window.location.search;
            let searchTerm = '';
            if (Object.keys(filterObject).length < 1) {
                window.location.replace(i18next.t(`routes:search`, { lng: i18next.resolvedLanguage }));
               /* if(window.location.pathname!=='/' && window.location.pathname!=='/de'  && window.location.pathname!=='/de/'){
                    i18next.resolvedLanguage === "tr" ?   window.location.href = "/arama"  :   history.push(`/arama`);
                }*/
                return;
            }
            if (!search) {
                for (let key in filterObject) {
                    if(searchTerm==='')
                        searchTerm += `?${key}=${filterObject[key]}`
                    else
                        searchTerm += `&${key}=${filterObject[key]}`
                }

                window.location.replace(i18next.t(`routes:search`, { lng: i18next.resolvedLanguage })+searchTerm);

                //i18next.resolvedLanguage === "tr" ?   window.location.href = "/arama" + searchTerm :  history.push(`/arama` +searchTerm);

                return;

            } else {
                Object.keys(filterObject).forEach(function (key, index) {
                    if (index === 0) {
                        searchTerm += `?${key}=${filterObject[key]}`
                    } else {
                        searchTerm += `&${key}=${filterObject[key]}`

                    }
                });

                window.location.replace(i18next.t(`routes:search`, { lng: i18next.resolvedLanguage })+searchTerm);

                //i18next.resolvedLanguage === "tr" ?   window.location.href = "/arama" + searchTerm :  history.push(`/arama` + searchTerm );

                // todo :  console.log(window.location.search + " burada (else) almancada çalışmıyor anasayfaya atıyor history push sorunu oluyor > yukarıdaki satır -- almanca markalar sayfasında da ilk seçimde de filtre çalışmıyor.")

                return;

            }
        }
    }

    useEffect(()=>{

        //console.log(orderVal)
    },[orderVal])

    const onFormChange = (order) =>{
        if(orderVal!==order && order){
            setOrderVal(order)
            setPagesData([])
            setPage(1)
            changePage(1, order, selectedFilterString,brandHolder ? brandHolder : '')
        }
    }


    return(
        <React.Fragment>
            {(holdingFilters && pagesData.length>0) ?
                <section className={`section listing loader-container search-listing size-4 ${(!window.location.pathname.includes(`${i18next.t("routes.pages.brands.path2")}`) && !window.location.pathname.includes(`${i18next.t("routes.pages.dealers.dealers")}`)) && 'has-filters-brand '}`}>

                    {
                        (!window.location.pathname.includes(`${i18next.t("routes.pages.brands.path2")}`)  && !window.location.pathname.includes(`${i18next.t("routes.pages.dealers.dealers")}`)) &&
                        <>  <ListingFilters history={history} listing2Data={holdingFilters} setAnyFilter={setAnyFilter} loading={listLoading} mobile={props.mobile} showMoreBrands={true} /></>
                    }


                    {/*<ListingFilters2 />*/}

                    <div className="listing-content type-listing">
                        <aside className="content-top">

                            {/*  <Loader loading={!carData} strict={true} />*/}

                            {props.mobile &&
                                <button className="top-filterstrigger" type="button" onClick={props.expandFilters}>
                                    <i className="icon-filter"></i> {i18next.t("sections.listing.filter")}
                                    {/*        {(props.bannerData.filters && activeFilters > 0) &&
                            <span> ({activeFilters})</span>*/}
                                </button>
                            }


                            { /*  {!vm.props.mobile && vm.props.topSection &&
                        <ActiveFilters data={vm.props.listingData} onFilterRemove={vm.removeFilter} />
                         }*/}

                            <FormInput
                                type="select"
                                placeholder={i18next.t("sections.listing.placeHolder")}
                                isSearchable={false}
                                value={orderObject[orderVal]}
                                onChange={(order) => onFormChange(order)}
                                options={orderOptions}
                                className="top-order"/>

                        </aside>

                        {pagesData.map((page,i)=>(
                            <ListingResults
                                key={i}
                                data={page}
                                setAnyFilter={setAnyFilter}
                            />
                        ))}

                        {listLoading &&
                            <div style={{display: 'flex', justifyContent: "center"}}>
                                <img width="50" src={image_loader} alt={i18next.t("loading")}/>
                            </div>
                        }

                    </div>
                </section>
                :
                <section className={`section listing has-filters loader-container search-listing size-4`}>
                    {(window.location.pathname === i18next.t(`routes:/`, { lng: i18next.resolvedLanguage }) || window.location.pathname === i18next.t(`routes:search`, { lng: i18next.resolvedLanguage })) &&
                        <aside className="section listing-filters" style={{paddingTop:"1rem"}}>
                        <img src="/assets/images/mock-filters.png" alt=""/>
                    </aside>}
                    <div className="listing-content type-listing" style={{width:'100%'}}>
                        <ul className="content-results">
                            {
                                [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((value,index)=>(
                                    <div key={index} className="results-item">
                                        <img src="/assets/images/mock-carpost.png" alt=""/>
                                    </div>
                                ))
                            }
                        </ul>
                    </div>
                </section>
            }
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Listing2);
