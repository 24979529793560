import React from 'react'
// Sections
import SubscriptionBar from 'components/sections/subscriptionbar'
// Partials
import Breadcrumbs from 'components/partials/breadcrumbs'
import Loader from 'components/partials/loader'
import FavBtn from 'components/partials/favbtn'
import ContentBox from 'components/partials/contentbox'
import {DetailGallery} from "../components/partials/detail/DetailGallery";
import {DetailLVP} from "../components/partials/detail/DetailLvp";
import {DetailExtras, DetailInfo, DetailRelated, DetailTopInfo} from 'components/partials/detail'
import Redirect from './redirect'

// Deps
import {set404} from 'controllers/navigator'
import {openModal} from "functions/modals"
import {connect} from "react-redux"
import request from 'controllers/request'
import {setDescription, setHead, setTitle} from 'controllers/head'
import {setDealerData, setProductData} from 'data/store.ga'
import {GA} from 'controllers/ga'
//Functions
import {CompareListService} from '../functions'
//Redux
import {addVehicleToCompare, setVehicleToReservation} from "../actions";
import {addVehicleToLVP} from "../actions/LvpActions";
import YoutubeWrapper from "../components/partials/youtube-wrapper";


// Assets

//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";

class Detail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productData: false,
			loading: true,
			galleryFullScreen: false,
			redirectClicked:false,
			productNo:''
		};

		this.initialize = this.initialize.bind(this);
		this.setFullScreen = this.setFullScreen.bind(this);
		this.mounted = false;
	}

	componentDidMount() {
		this.mounted = true;
		this.initialize();

	}

	componentWillMount() {
		this.setState({
			externalId: this.props.match.params.post.substring(this.props.match.params.post.lastIndexOf('M'))
		});
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		const vm = this;
		let user = this.props.user;
		if (prevProps.user === false && user !== false) {
			this.initialize()
		}

		if (prevProps.match.params.post !== this.props.match.params.post) {
			window.scroll(0, 0);
			this.setState({
				productData: false,
			});
		}

		if (prevState.productData !== false && this.state.productData === false) {
			this.setState({
				externalId: this.props.match.params.post.substring(this.props.match.params.post.lastIndexOf('M'))
			},function () {
				vm.initialize();
			});

		}
	}

	setCompareList(){
		const product = this.state.productData;
		const storedList = this.props.compareList.data;

		const _compareListService = new CompareListService();

		if (!_compareListService.isExist(product,storedList)){
			this.props.addVehicleToCompare(product);
		}
		openModal('compare',{history:this.props.history});

	}

	initialize() {
		const { t } = this.props;
		let vm = this;
		const {externalId} = vm.state;

		if (vm.state.productData === false) {
			request.get(`car-post/${externalId}?language=`  + i18next.resolvedLanguage, {email: this.props.user.email}, function (payload, status) {
				//request.get('/dummy/data/detail.json', { id: vm.props.match.params.id }, function (payload, status) {
				if(payload.status !== '404'){
					if (payload) {
						vm.setState({
							productData: payload
						});
						vm.props.setGaProductData(payload);
						vm.props.setGaDealerData(payload.dealer);
						const _compareListService = new CompareListService();

						if (!_compareListService.isExist(payload, vm.props.lvpList.data)) {
							vm.props.addVehicleToLvp(payload);
						}
						GA.send('productView', payload);
						setTitle(`${payload.title} - ${payload.dealer.title}`);
						setDescription(` ${t("pages.detail.payload.0")} ${payload.title} ${t("pages.detail.payload.1")}${payload.dealer.title} ${t("pages.detail.payload.2")} ${payload.title} ${t("pages.detail.payload.3")}`);

						if (payload.image) {
							setHead([{
								key: "meta",
								props: {
									property: "og:image",
									content: payload.image,
								}
							}]);
						};
					}
					else {
						set404();
					}

				}
				else{
					set404('notFound')
				}
			}, { excludeApiPath: false });
		}
	}

	setFullScreen(state) {
		let vm = this;
		if (vm.mounted) {
			vm.setState({ galleryFullScreen: state });
		}
	}


	toggleRedirectClicked = () =>{
		this.setState({
			redirectClicked:!this.state.redirectClicked
		})
	}


	render() {
		const { t } = this.props;
		let vm = this;
		let product = vm.state.productData;
		const {reservation,setVehicleToReservation} = this.props;
		const {mobile} = vm.props;

		const breadCrumbItem0 = "pages.detail.breadCrumbItem0.title"
		return (
			<main className={"page detail minoto-ui" + (vm.state.galleryFullScreen ? ' gallery-fullscreen' : '')}>
				<Loader loading={product === false} strict={true} />
				{product !== false &&
					<div>
						{
							!this.state.redirectClicked ? (
									<>
										<section className="section detail-top">
											{!mobile &&
												<div className="top-wrap wrapper" lang="en">
													<Breadcrumbs className="top-breadcrumbs" data={[
														{
															"href": i18next.t(`routes:/`, { lng: i18next.resolvedLanguage }),
															"title":  `${i18next.t(breadCrumbItem0.title)}`
														},
														{
															"href": i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage })+`/${product.breadCrumbs[0].value}`,
															"title": product.breadCrumbs[0].title
														},
														{
															"href": i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage })+`/${product.breadCrumbs[0].value}/${product.breadCrumbs[1].value}`,
															"title": product.breadCrumbs[1].title
														},
														{
															"title": product.breadCrumbs[2].title
														},
														{
															"title": product.breadCrumbs[3].title
														},
														{
															"title": product.breadCrumbs.length > 4 ? product.breadCrumbs[4].title : ''
														},
														/* {
                                                            "href": ListingLink([
                                                                { 'key': 'tip', 'val': 'otomobil' },
                                                                { 'key': 'marka', 'val': 'audi' },
                                                                { 'key': 'model', 'val': 'a3' },
                                                            ]),
                                                            "title": "A3"
                                                        },
                                                        {
                                                            "href": ListingLink([
                                                                { 'key': 'tip', 'val': 'otomobil' },
                                                                { 'key': 'marka', 'val': 'audi' },
                                                                { 'key': 'model', 'val': 'a3' },
                                                                { 'key': 'kasa', 'val': 'a3-sportsback' },
                                                            ]),
                                                            "title": "A3 Sportback"
                                                        },
                                                        {
                                                            "href": ListingLink([
                                                                { 'key': 'tip', 'val': 'otomobil' },
                                                                { 'key': 'marka', 'val': 'audi' },
                                                                { 'key': 'model', 'val': 'a3' },
                                                                { 'key': 'kasa', 'val': 'a3-sportsback' },
                                                            ]),
                                                            "title": "1.8 TDI"
                                                        },
                                                        {
                                                            "href": ListingLink([
                                                                { 'key': 'tip', 'val': 'otomobil' },
                                                                { 'key': 'marka', 'val': 'audi' },
                                                                { 'key': 'model', 'val': 'a3' },
                                                                { 'key': 'kasa', 'val': 'a3-sportsback' },
                                                            ]),
                                                            "title": "Ambiente"
                                                        } */
													]} />
													<div className="top-controls">
														{(product.activeViewers && product.activeViewers > 0) &&
															<span className="controls-viewers" style={{ opacity: '0' }}>{product.activeViewers} {t("pages.detail.fav.title")}</span>
														}
														<span className="controls-date">{product.date}</span>
														<FavBtn className="controls-btn " faved={product.favorited} type="post" id={product.id}> {product.favorited ?  t(`pages.detail.fav.0`) : t("pages.detail.fav.1")}</FavBtn>
														<button className="controls-btn"
																onClick={() => this.setCompareList()}><i
															className="icon-compare"/> {t("pages.detail.fav.2")}
															({this.props.compareList.data.length})
														</button>
														<button className="controls-btn share-button" onClick={() => openModal('share')}><i className="icon-share"></i> {t("pages.detail.fav.3")}</button>
													</div>
												</div>
											}
											{mobile &&
												<DetailTopInfo product={product} mobile={mobile}
															   addVehicleToCompare={this.props.addVehicleToCompare}
															   compareList={this.props.compareList} productData={this.state.productData}
															   history={this.props.history}/>
											}
										</section>
										<section className="section detail-content">
											<div className="content-wrap wrapper">
												<div className="content-left">
													<DetailGallery product={product} mobile={mobile} onFullScreenChange={vm.setFullScreen} fullScreen={vm.state.galleryFullScreen} />
													{mobile &&
														<DetailInfo toggleRedirectClicked={this.toggleRedirectClicked} product={product}  mobile={mobile} reservation={reservation} setVehicleToReservation={setVehicleToReservation} history={this.props.history} user={this.props.user}/>
													}
													<DetailExtras product={product} mobile={mobile} />
												</div>
												{!mobile &&
													<div className="content-right">
														<DetailTopInfo product={product} mobile={mobile} />
														<DetailInfo toggleRedirectClicked={this.toggleRedirectClicked} product={product} mobile={mobile} reservation={reservation} setVehicleToReservation={setVehicleToReservation} history={this.props.history} user={this.props.user}/>
													</div>
												}
											</div>
										</section>
										{!mobile  &&
											<section className="section detail-related" style={{paddingBottom:"0px",marginBottom:"0px"}}>
												<div className="wrapper">
													<div className="related-innerwrap">
														<h2 className="related-title">{t("pages.detail.detailSubscription.heading.2")}</h2>
														{
															product && (
																<DetailRelated postId={product.id} mobile={mobile} />
															)
														}
													</div>
												</div>
											</section>

										}
										{!mobile &&
											<DetailLVP lvpList={this.props.lvpList} currentProduct={product} t={t}/>
										}
										<SubscriptionBar className="detail-subscription" heading={t("pages.detail.detailSubscription.heading.0") + " " + product.brand.title + " " + t("pages.detail.detailSubscription.heading.1")} />
										{/*product.ads &&
							<section className="section detail-banners">
								<div className="wrapper">
									{product.ads.map((ad, nth) => (
										<Link type='a' href={`/ blog / detay / ${ ad.url }`} title={ad.title} key={nth} className={"banners-item x-" + ad.size}>
											<Image src={storageSpace('articles', ad.image)} />
										</Link>
									))}
								</div>
							</section>
						*/}
										{(product.ads || product.youtubeVideo) &&
											<section className="section detail-blogposts">
												<div className="wrapper">
													{product.youtube ?
														<div style={{width: "50%",padding:"1.5rem",margin:"auto"}}>
															<YoutubeWrapper video={{
																'videoId': 'ScMzIvxBSi4',
																'thumbnail': 'http://i3.ytimg.com/vi/4JThtv3d0cc/hqdefault.jpg'
															}}/>
														</div> :
														<ul className="blogposts-list">
															{product.ads.map((ad, nth) => (
																<li className="blogposts-item" key={nth}>
																	<ContentBox
																		type="blogpost"
																		//pretitle={ad.date}
																		title={ad.title}
																		image={ad.image}
																		url="blogDetail"
																		additionsOptional
																		urlParams={{ slug: ad.url }}
																	/>
																</li>
															))}
														</ul>
													}
												</div>
											</section>
										}
									</>
								) :
								<Redirect product={product} toggleRedirectClicked={this.toggleRedirectClicked} />
						}
					</div>
				}
			</main>
		)
	}
}


const mapStateToProps = ({generic, user, compareList, lvpList, reservation}) => {
	return {mobile: generic.mobile, user: user.user, compareList, reservation, lvpList};
};

const mapDispatchToProps = dispatch => {
	return {
		setGaProductData: (data) => dispatch(setProductData(data)),
		setGaDealerData: (data) => dispatch(setDealerData(data)),
		addVehicleToCompare: (data) => dispatch(addVehicleToCompare(data)),
		setVehicleToReservation: (data) => dispatch(setVehicleToReservation(data)),
		addVehicleToLvp: (data) => dispatch(addVehicleToLVP(data)),
	}
};





export  default  connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Detail))



/*
import React from 'react'
// Sections
import SubscriptionBar from 'components/sections/subscriptionbar'
// Partials
import Breadcrumbs from 'components/partials/breadcrumbs'
import Loader from 'components/partials/loader'
import FavBtn from 'components/partials/favbtn'
import ContentBox from 'components/partials/contentbox'
import {DetailGallery} from "../components/partials/detail/DetailGallery";
import {DetailLVP} from "../components/partials/detail/DetailLvp";
import {DetailExtras, DetailInfo, DetailRelated, DetailTopInfo} from 'components/partials/detail'
import Redirect from './redirect'

// Deps
import {set404} from 'controllers/navigator'
import {openModal} from "functions/modals"
import {connect} from "react-redux"
import request from 'controllers/request'
import {setDescription, setHead, setTitle} from 'controllers/head'
import {setDealerData, setProductData} from 'data/store.ga'
import {GA} from 'controllers/ga'
//Functions
import {CompareListService} from '../functions'
//Redux
import {addVehicleToCompare, setVehicleToReservation} from "../actions";
import {addVehicleToLVP} from "../actions/LvpActions";
import YoutubeWrapper from "../components/partials/youtube-wrapper";


// Assets

//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";

class Detail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productData: false,
			loading: true,
			galleryFullScreen: false,
			redirectClicked:false,
			productNo:''
		};

		this.initialize = this.initialize.bind(this);
		this.setFullScreen = this.setFullScreen.bind(this);
		this.mounted = false;
	}

	componentDidMount() {
		this.mounted = true;
		this.initialize();

	}

	componentWillMount() {
		this.setState({
			externalId: this.props.match.params.post.substring(this.props.match.params.post.lastIndexOf('M'))
		});
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		const vm = this;
		let user = this.props.user;
		if (prevProps.user === false && user !== false) {
			this.initialize()
		}

		if (prevProps.match.params.post !== this.props.match.params.post) {
			window.scroll(0, 0);
			this.setState({
				productData: false,
			});
		}

		if (prevState.productData !== false && this.state.productData === false) {
			this.setState({
				externalId: this.props.match.params.post.substring(this.props.match.params.post.lastIndexOf('M'))
			},function () {
				vm.initialize();
			});

		}
	}

	setCompareList(){
		const product = this.state.productData;
		const storedList = this.props.compareList.data;

		const _compareListService = new CompareListService();

		if (!_compareListService.isExist(product,storedList)){
			this.props.addVehicleToCompare(product);
		}
		openModal('compare',{history:this.props.history});

	}

	initialize() {
		const { t } = this.props;
		let vm = this;
		const {externalId} = vm.state;

		if (vm.state.productData === false) {
			request.get(`car-post/${externalId}?language=`  + i18next.resolvedLanguage, {email: this.props.user.email}, function (payload, status) {
				//request.get('/dummy/data/detail.json', { id: vm.props.match.params.id }, function (payload, status) {
				if(payload.status !== '404'){
					if (payload) {
						vm.setState({
							productData: payload
						});
						vm.props.setGaProductData(payload);
						vm.props.setGaDealerData(payload.dealer);
						const _compareListService = new CompareListService();

						if (!_compareListService.isExist(payload, vm.props.lvpList.data)) {
							vm.props.addVehicleToLvp(payload);
						}
						GA.send('productView', payload);
						setTitle(`${payload.title} - ${payload.dealer.title}`);
						setDescription(` ${t("pages.detail.payload.0")} ${payload.title} ${t("pages.detail.payload.1")}${payload.dealer.title} ${t("pages.detail.payload.2")} ${payload.title} ${t("pages.detail.payload.3")}`);

						if (payload.image) {
							setHead([{
								key: "meta",
								props: {
									property: "og:image",
									content: payload.image,
								}
							}]);
						};
					}
					else {
						set404();
					}

				}
				else{
					set404('notFound')
				}
			}, { excludeApiPath: false });
		}
	}

	setFullScreen(state) {
		let vm = this;
		if (vm.mounted) {
			vm.setState({ galleryFullScreen: state });
		}
	}


	toggleRedirectClicked = () =>{
		this.setState({
			redirectClicked:!this.state.redirectClicked
		})
	}


	render() {
		const { t } = this.props;
		let vm = this;
		let product = vm.state.productData;
		const {reservation,setVehicleToReservation} = this.props;
		const {mobile} = vm.props;

		const breadCrumbItem0 = "pages.detail.breadCrumbItem0.title"
		return (
			<main className={"page detail minoto-ui" + (vm.state.galleryFullScreen ? ' gallery-fullscreen' : '')}>
				<Loader loading={product === false} strict={true} />
				{product !== false &&
					<div>
						{
							!this.state.redirectClicked ? (
								<>
									<section className="section detail-top">
										{!mobile &&
											<div className="top-wrap wrapper" lang="en">
												<Breadcrumbs className="top-breadcrumbs" data={[
													{
														"href": "home",
														"title":  `${t(breadCrumbItem0.title)}`
													},
													{
														"href": `/${product.breadCrumbs[0].value}`,
														"title": product.breadCrumbs[0].title
													},
													{
														"href": `/${product.breadCrumbs[0].value}/${product.breadCrumbs[1].value}`,
														"title": product.breadCrumbs[1].title
													},
													{
														"title": product.breadCrumbs[2].title
													},
													{
														"title": product.breadCrumbs[3].title
													},
													{
														"title": product.breadCrumbs.length > 4 ? product.breadCrumbs[4].title : ''
													},
													/!* {
                                                        "href": ListingLink([
                                                            { 'key': 'tip', 'val': 'otomobil' },
                                                            { 'key': 'marka', 'val': 'audi' },
                                                            { 'key': 'model', 'val': 'a3' },
                                                        ]),
                                                        "title": "A3"
                                                    },
                                                    {
                                                        "href": ListingLink([
                                                            { 'key': 'tip', 'val': 'otomobil' },
                                                            { 'key': 'marka', 'val': 'audi' },
                                                            { 'key': 'model', 'val': 'a3' },
                                                            { 'key': 'kasa', 'val': 'a3-sportsback' },
                                                        ]),
                                                        "title": "A3 Sportback"
                                                    },
                                                    {
                                                        "href": ListingLink([
                                                            { 'key': 'tip', 'val': 'otomobil' },
                                                            { 'key': 'marka', 'val': 'audi' },
                                                            { 'key': 'model', 'val': 'a3' },
                                                            { 'key': 'kasa', 'val': 'a3-sportsback' },
                                                        ]),
                                                        "title": "1.8 TDI"
                                                    },
                                                    {
                                                        "href": ListingLink([
                                                            { 'key': 'tip', 'val': 'otomobil' },
                                                            { 'key': 'marka', 'val': 'audi' },
                                                            { 'key': 'model', 'val': 'a3' },
                                                            { 'key': 'kasa', 'val': 'a3-sportsback' },
                                                        ]),
                                                        "title": "Ambiente"
                                                    } *!/
												]} />
												<div className="top-controls">
													{(product.activeViewers && product.activeViewers > 0) &&
														<span className="controls-viewers" style={{ opacity: '0' }}>{product.activeViewers} {t("pages.detail.fav.title")}</span>
													}
													<span className="controls-date">{product.date}</span>
													<FavBtn className="controls-btn " faved={product.favorited} type="post" id={product.id}> {product.favorited ?  t(`pages.detail.fav.0`) : t("pages.detail.fav.1")}</FavBtn>
													<button className="controls-btn"
															onClick={() => this.setCompareList()}><i
														className="icon-compare"/> {t("pages.detail.fav.2")}
														({this.props.compareList.data.length})
													</button>
													<button className="controls-btn share-button" onClick={() => openModal('share')}><i className="icon-share"></i> {t("pages.detail.fav.3")}</button>
												</div>
											</div>
										}
										{mobile &&
											<DetailTopInfo product={product} mobile={mobile}
														   addVehicleToCompare={this.props.addVehicleToCompare}
														   compareList={this.props.compareList} productData={this.state.productData}
														   history={this.props.history}/>
										}
									</section>
								<section className="section detail-content">
									<div className="content-wrap wrapper">
										<div className="content-left">
											<DetailGallery product={product} mobile={mobile} onFullScreenChange={vm.setFullScreen} fullScreen={vm.state.galleryFullScreen} />
											{mobile &&
												<DetailInfo toggleRedirectClicked={this.toggleRedirectClicked} product={product}  mobile={mobile} reservation={reservation} setVehicleToReservation={setVehicleToReservation} history={this.props.history} user={this.props.user}/>
											}
											<DetailExtras product={product} mobile={mobile} />
										</div>
										{!mobile &&
											<div className="content-right">
												<DetailTopInfo product={product} mobile={mobile} />
												<DetailInfo toggleRedirectClicked={this.toggleRedirectClicked} product={product} mobile={mobile} reservation={reservation} setVehicleToReservation={setVehicleToReservation} history={this.props.history} user={this.props.user}/>
											</div>
										}
									</div>
								</section>
						{!mobile  &&
							<section className="section detail-related" style={{paddingBottom:"0px",marginBottom:"0px"}}>
							<div className="wrapper">
							<div className="related-innerwrap">
							<h2 className="related-title">{t("pages.detail.detailSubscription.heading.2")}</h2>
						{
							product && (
							<DetailRelated postId={product.id} mobile={mobile} />
							)
						}
							</div>
							</div>
							</section>

						}
						{!mobile &&
							<DetailLVP lvpList={this.props.lvpList} currentProduct={product} t={t}/>
						}
							<SubscriptionBar className="detail-subscription" heading={t("pages.detail.detailSubscription.heading.0") + " " + product.brand.title + " " + t("pages.detail.detailSubscription.heading.1")} />
						{/!*product.ads &&
							<section className="section detail-banners">
								<div className="wrapper">
									{product.ads.map((ad, nth) => (
										<Link type='a' href={`/ blog / detay / ${ ad.url }`} title={ad.title} key={nth} className={"banners-item x-" + ad.size}>
											<Image src={storageSpace('articles', ad.image)} />
										</Link>
									))}
								</div>
							</section>
						*!/}
						{(product.ads || product.youtubeVideo) &&
							<section className="section detail-blogposts">
							<div className="wrapper">
						{product.youtube ?
							<div style={{width: "50%",padding:"1.5rem",margin:"auto"}}>
							<YoutubeWrapper video={{
							'videoId': 'ScMzIvxBSi4',
							'thumbnail': 'http://i3.ytimg.com/vi/4JThtv3d0cc/hqdefault.jpg'
						}}/>
							</div> :
							<ul className="blogposts-list">
						{product.ads.map((ad, nth) => (
							<li className="blogposts-item" key={nth}>
							<ContentBox
							type="blogpost"
							//pretitle={ad.date}
							title={ad.title}
							image={ad.image}
							url="blogDetail"
							additionsOptional
							urlParams={{ slug: ad.url }}
							/>
							</li>
							))}
							</ul>
						}
							</div>
							</section>
							}
							</>
							) :
							<Redirect product={product} toggleRedirectClicked={this.toggleRedirectClicked} />
						}
					</div>
				}
			</main>
		)
	}
}


const mapStateToProps = ({generic, user, compareList, lvpList, reservation}) => {
	return {mobile: generic.mobile, user: user.user, compareList, reservation, lvpList};
};

const mapDispatchToProps = dispatch => {
	return {
		setGaProductData: (data) => dispatch(setProductData(data)),
		setGaDealerData: (data) => dispatch(setDealerData(data)),
		addVehicleToCompare: (data) => dispatch(addVehicleToCompare(data)),
		setVehicleToReservation: (data) => dispatch(setVehicleToReservation(data)),
		addVehicleToLvp: (data) => dispatch(addVehicleToLVP(data)),
	}
};





export  default  connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Detail))

*/
