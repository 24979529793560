import React from "react";

function BlogPagination(props) {

    return (
        <>
            {props.totalPage > 1 &&
                <div className="pagination-wrapper" style={{paddingTop: "5rem"}}>
                    <div className="pagination-inner-wrapper">
                        <button className="back-arrow disabled"
                                onClick={() => props.changePage(props.onPage - 1)}>&laquo;</button>
                        <div className="pagination">
                            {
                                props.iterateArr.map((i) => {
                                    if (props.page + i <= props.totalPage) {
                                        return (
                                            <button className={(props.onPage === 1 && i === 0) ? 'active': ""} key={i}
                                                    onClick={() => props.changePage(props.page + i)}>{props.page + i}</button>
                                        )
                                    }else {
                                        return  null;
                                    }
                                })
                            }
                        </div>
                        <button className="next-arrow"
                                onClick={() => props.changePage(props.onPage + 1)}>&raquo;</button>
                    </div>
                </div>
            }
        </>
    )
}

export default BlogPagination;
