import React from 'react'
// Deps
import { redirect } from 'controllers/navigator'
// Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";


class BlogRedirect extends React.Component {

    componentDidMount() {
        redirect('blogDetail', { slug: this.props.match.params.slug })
    }

    render() {

        const { t } = this.props;

        return (
            <React.Fragment>{t("pages.blogRedirect.title")}</React.Fragment>
        )
    }
}

export default (withTranslation()(BlogRedirect))
