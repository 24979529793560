import React from 'react'
// Partials
import Image from 'components/partials/image'
import Link from 'components/partials/link'
import ScrollWrap from 'components/partials/scrollwrap'
import SearchBar from 'components/partials/searchbar.js'
import Responsive from 'components/partials/responsive.js'
import Btn from 'components/partials/btn.js'
// Deps
import request from 'controllers/request'
import history from 'controllers/history'
import {openModal} from "functions/modals"
import {blockOverflow, storageSpace} from "functions/helpers"
import {connect} from "react-redux"
import {getUnreadMessageCount} from "data/store.user"
import config from 'data/config'
// Assets
import image_icon_facebook from 'assets/images/icon/facebook.svg'
import image_icon_instagram from 'assets/images/icon/instagram.svg'
import image_icon_youtube from 'assets/images/icon/youtube.svg'
import image_icon_twitter from 'assets/images/icon/twitter2.svg'
import image_icon_linkedin from 'assets/images/icon/linkedin.svg'
import image_logo from 'assets/images/logo2.svg'
import image_logo_primary from 'assets/images/logo-primary.svg'
import image_avatar from 'assets/images/defaults/avatar.svg'
// Multi language
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';


import i18n from "../../i18n";

import i18next from "i18next";


const mapStateToProps = state => {
    return {
        currentPage: state.generic.currentPage,
        user: state.user.user,
        unreadMessageCount: state.user.unreadMessageCount,
        //mobile: state.generic.mobile,
    };

};

/*
const clickHandle = async  lang => {
await	i18n.changeLanguage(lang)

	document.documentElement.setAttribute('lang', lang);
	let elems = history.location.pathname.split("/")
	elems[2] = lang
	history.push(elems.join("/"))


	console.log(history.location)


}
*/


const changeLanguage = (nextLanguage) => {
    window.localStorage.removeItem('lvp')
    window.localStorage.removeItem('compareList')
    if(i18next.language==='de' ) {
        i18next.changeLanguage('de')
    }else{
        i18next.changeLanguage(i18next.resolvedLanguage)
    }
    const routes = i18next.getResourceBundle(i18next.language, 'routes');
    let currentPathname = window.location.pathname.replace(/\/+$/, '');
    currentPathname = currentPathname ? currentPathname : '/'
    const currentRouteKey = Object.keys(routes).find((key) => routes[key] === currentPathname);
    if(!currentRouteKey){
        window.location.replace(i18next.t(`routes:/`, { lng: nextLanguage }));
    }else{
        window.location.replace(i18next.t(`routes:${currentRouteKey}`, { lng: nextLanguage }));
    }

};



class Header extends React.Component {
    change(option) {
        localStorage.setItem('lang', option.target.value);
        window.location.reload()
    }

    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false,
            menuActive: false,
            menuShow: false,
            videoData: false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.menuTimeout = false;
    }

    componentWillUnmount() {
        if (this.menuTimeout) {
            clearTimeout(this.menuTimeout);
            this.menuTimeout = false;
        }
    }

    componentDidMount() {
        let vm = this;
        vm.mounted = true;
        vm.initialize();

        vm.listenerAbort = history.listen(function (e) {
            vm.initialize();
        });

    }

    componentDidUpdate(prevProps, prevState) {
        let vm = this;

        if (prevState.menuOpen !== vm.state.menuOpen) {
            if (vm.menuTimeout !== false) {
                clearTimeout(vm.menuTimeout);
            }
            if (vm.state.menuOpen === true) {
                vm.setState({menuActive: true});
                vm.menuTimeout = setTimeout(function () {
                    vm.setState({menuShow: true});
                    blockOverflow(true, true);
                }, 20);
            } else {
                vm.setState({menuShow: false});
                vm.menuTimeout = setTimeout(function () {
                    vm.setState({menuActive: false});
                    blockOverflow(false, true);
                }, 600);
            }
        }

        if (prevProps.user === false && this.props.user !== false) {
            getUnreadMessageCount();
        }

        if (prevProps.currentPage.key !== vm.props.currentPage.key && vm.state.menuOpen) {
            vm.setState({menuOpen: false});
        }
    }

    initialize() {
        let vm = this;

        if (vm.mounted) {

            vm.setState({results: false});

            request.get('promo-videos', null, function (payload) {
                if (vm.mounted && payload) {
                    vm.setState({
                        videoData: payload,
                    });
                }
            });
        }
    }

    toggleMenu() {
        this.setState({menuOpen: !this.state.menuOpen});
    }

    closeMenu() {
        this.setState({menuOpen: false})
    }


    render() {


        /*	const {pathname, search, hash, location} = useLocation()
            const { t } =useTranslation();*/

        /*const lang = localStorage.getItem('lang') || 'en';*/

        //console.log(this.props)
      /*  const {i18n: {language}} = this.props;*/
        const t = this.props;


        let vm = this;
        let user = vm.props.user;
        let videoData = vm.state.videoData;
        return (
            <header className={"section header" + (vm.state.menuActive ? ' menu-active' : '')}>
                <div className="header-wrap wrapper ">


                    <Link className="header-logo"  href={i18next.t(`routes:/`, { lng: i18next.resolvedLanguage })}>
                        <Image alt={i18n.t("logoText")} src={image_logo}/>
                    </Link>


                    <nav className="header-nav">
                        <Responsive type="only-web">

                            <Link className="nav-link ml-5" navLink
                                  href={i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage })}> {i18n.t("headerItems.0.title")}</Link>

                            {user &&
                                <div className="nav-user">
                                    <Link className="user-item avatar" href="account.profile" title={user.fullname}>
                                        <Image className="item-image" bg
                                               src={(user.avatar ? storageSpace('profile-photos', user.avatar) : image_avatar)}/>
                                        {vm.props.unreadMessageCount > 0 &&
                                            <span
                                                className="item-unread">{vm.props.unreadMessageCount > 9 ? '9+' : vm.props.unreadMessageCount}</span>
                                        }
                                    </Link>

                                </div>
                            }
                            {!user &&
                                <button className="nav-link" onClick={() => {
                                    openModal('register')
                                }}>{i18n.t("headerItems.1.title")}</button>
                            }
                            {!user &&
                                <button className="nav-link" onClick={() => {
                                    openModal('login')
                                }}>{i18n.t("headerItems.2.title")}</button>
                            }


                            <div className={"nav-link "}><Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className={"nav-link-drop"}>
                                    {i18n.t("currentLanguage")}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item className={"nav-link-drop-item"}
                                                   onClick={() => changeLanguage('tr')}>{i18n.t("languages.0")}</Dropdown.Item>
                                    <Dropdown.Item className={"nav-link-drop-item"}
                                                   onClick={() => changeLanguage('de')}> {i18n.t("languages.1")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown></div>


                        </Responsive>

                        <button className={"nav-menubtn" + (vm.state.menuOpen ? ' open' : '')} onClick={vm.toggleMenu}
                                aria-label="menu open icon">
                            <div className="menubtn-inner">
                                <span/>
                                <span/>
                                <span/>
                                <span/>
                            </div>
                        </button>


                        {vm.state.menuActive && (
                            <div className={"nav-menu" + (vm.state.menuShow ? ' show' : '')}>
                                <div className="menu-outerwrap">
                                    <ScrollWrap className="menu-content">
                                        <div className="content-innerwrap">
                                            <Dropdown className={"mb-5"}>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                                 className={"nav-link-drop nav-link-drop-hamburger "}>
                                                    {i18n.t("currentLanguage")}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => changeLanguage('tr')}>{i18n.t("languages.0")}</Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => changeLanguage('de')}> {i18n.t("languages.1")}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <div className="menu-header">

                                                <Responsive type="only-web">
                                                    <Image className="menu-logo " src={image_logo_primary}/>
                                                    <div className={"menu-logo "}></div>
                                                </Responsive>

                                                <div className="menu-social">
                                                    <a href={config.social.instagramURL} target={"_blank"}
                                                       rel="noopener noreferrer"
                                                       title={i18n.t("hamburgerMenu.socials.0.title")}>
                                                        <Image src={image_icon_instagram}
                                                               alt={i18n.t("hamburgerMenu.socials.0.alt")}/>
                                                    </a>
                                                    <a href={config.social.youtubeURL} target={"_blank"}
                                                       rel="noopener noreferrer"
                                                       title={i18n.t("hamburgerMenu.socials.1.title")}>
                                                        <Image src={image_icon_youtube}
                                                               alt={i18n.t("hamburgerMenu.socials.1.alt")}/>
                                                    </a>
                                                    <a href={config.social.facebookURL} target={"_blank"}
                                                       rel="noopener noreferrer"
                                                       title={i18n.t("hamburgerMenu.socials.2.title")}>
                                                        <Image src={image_icon_facebook}
                                                               alt={i18n.t("hamburgerMenu.socials.2.alt")}/>
                                                    </a>
                                                    <a href={config.social.twitterURL} target={"_blank"}
                                                       rel="noopener noreferrer"
                                                       title={i18n.t("hamburgerMenu.socials.3.title")}>
                                                        <Image src={image_icon_twitter}
                                                               alt={i18n.t("hamburgerMenu.socials.3.alt")}/>
                                                    </a>
                                                    <a href={config.social.linkedInURL} target={"_blank"}
                                                       rel="noopener noreferrer"
                                                       title={i18n.t("hamburgerMenu.socials.4.title")}>
                                                        <Image src={image_icon_linkedin}
                                                               alt={i18n.t("hamburgerMenu.socials.4.alt")}/>
                                                    </a>
                                                </div>
                                            </div>

                                            <Responsive type="only-mobile">
                                                <div className="menu-userbar">
                                                    {user &&
                                                        <Link className="userbar-link"
                                                              href={i18n.t("hamburgerMenu.navigationItems.7.link")}>{i18n.t("hamburgerMenu.navigationItems.7.title")}</Link>
                                                    }
                                                    {!user &&
                                                        <button className="userbar-link" onClick={() => {
                                                            vm.toggleMenu();
                                                            openModal('register')
                                                        }}>{i18n.t("headerItems.1.title")}</button>
                                                    }
                                                    {!user &&
                                                        <button className="userbar-link" onClick={() => {
                                                            vm.toggleMenu();
                                                            openModal('login')
                                                        }}>{i18n.t("headerItems.2.title")}</button>
                                                    }
                                                </div>
                                            </Responsive>

                                            <ul className="menu-items">


                                                <li className="menu-item">
                                                    <Link  href={i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage })}>
                                                        <span>{i18n.t("hamburgerMenu.navigationItems.0.title")}</span>
                                                        <span className="item-count"></span>
                                                    </Link>
                                                </li>


                                                <li className="menu-item">
                                                    <Link  href={i18next.t(`routes:about`, { lng: i18next.resolvedLanguage })}>
                                                        <span>{i18n.t("hamburgerMenu.navigationItems.1.title")}</span>
                                                    </Link>
                                                </li>


                                                <li className="menu-item">
                                                    <Link  href={i18next.t(`routes:blogComponent`, { lng: i18next.resolvedLanguage })}>
                                                        <span>{i18n.t("hamburgerMenu.navigationItems.2.title")}</span>
                                                    </Link>
                                                </li>


                                                <li className="menu-item">
                                                    <Link  href={i18next.t(`routes:contact`, { lng: i18next.resolvedLanguage })}>
                                                        <span>{i18n.t("hamburgerMenu.navigationItems.3.title")}</span>
                                                    </Link>
                                                </li>
                                            </ul>

                                            <ul className="menu-sublinks">

                                                {/*<li className="sublinks-item" style={{ display: 'none' }}>
													<Link href={i18n.t("hamburgerMenu.navigationItems.4.link")}>{i18n.t("hamburgerMenu.navigationItems.4.title")}--</Link>
												</li>*/}

                                                <li className="sublinks-item">
                                                    <Link
                                                        href={i18next.t(`routes:faq`, { lng: i18next.resolvedLanguage })}>{i18n.t("hamburgerMenu.faq.title")} </Link>
                                                </li>
                                                <li className="sublinks-item">
                                                    <Link
                                                        href={i18next.t(`routes:dealerApplication`, { lng: i18next.resolvedLanguage })}>{i18n.t("hamburgerMenu.navigationItems.5.title")} </Link>
                                                </li>

                                                {i18next.resolvedLanguage=== "tr" &&

                                                    <li className="sublinks-item">
                                                        <Link
                                                            href={i18next.t(`routes:otv`, { lng: i18next.resolvedLanguage })}>{i18n.t("hamburgerMenu.navigationItems.6.title")}</Link>
                                                    </li>

                                                }


                                            </ul>

                                            {videoData && (
                                                <ul className="menu-thumblinks">
                                                    {/*	videoData.map((video, nth) => (
														<li className={`thumblinks-item ${videoData.length === 1 ? 'full' : ''}`} key={nth}>
															<Link href="/" onClick={() => { openModal('youtube', { url: video.url }); vm.closeMenu(); }}>
																<Image className="thumblinks-image" src={storageSpace('promo-videos', video.image)} />
															</Link>
														</li>
													))*/}
                                                    {/*	<li className="thumblinks-item full">
														<div className="resp-container">
															<iframe className="resp-iframe" title="Minoto Nedir?" src={'https://www.youtube.com/embed/4JThtv3d0cc'} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
														</div>
													</li>*/}
                                                    {false &&
                                                        <li className="thumblinks-item">
                                                            <Link  href={i18next.t(`routes:/`, { lng: i18next.resolvedLanguage })}>
                                                                <Image className="thumblinks-image"
                                                                       src="/dummy/images/sidemenu-thumb.jpg"/>
                                                                VW T-Cross İlk İnceleme
                                                            </Link>
                                                        </li>
                                                    }
                                                </ul>
                                            )}

                                            {false &&
                                                <div className="menu-applinks" style={{opacity: '0'}}>
                                                    <Btn tag="link" className="applinks-link btn block white"
                                                         href={t("hamburgerMenu.app.0.link")} rel="noopener noreferrer"
                                                         icon={t("hamburgerMenu.app.0.icon")}>
                                                        {i18n.t("hamburgerMenu.app.0.title")}
                                                    </Btn>
                                                    <Btn className="applinks-link btn block white"
                                                         href={i18n.t("hamburgerMenu.app.0.link")}
                                                         rel="noopener noreferrer"
                                                         icon={i18n.t("hamburgerMenu.app.1.icon")}>
                                                        {i18n.t("hamburgerMenu.app.1.title")}
                                                    </Btn>
                                                </div>
                                            }
                                        </div>
                                    </ScrollWrap>
                                </div>
                                <button className="menu-overlay" onClick={vm.closeMenu}></button>
                            </div>
                        )}
                    </nav>
                </div>


                {window.location.pathname === '/' ?
                    <></>
                    :
                    (!vm.props.currentPage.data.hideSearchFromHeader && window.location.pathname !== "/" + i18next.resolvedLanguage && window.location.pathname !== "/" + i18next.resolvedLanguage + '/') &&
                    <Responsive type="only-web">
                        <SearchBar className="header-search"/>
                    </Responsive>

                }


            </header>
        )
    }
}


export default connect(mapStateToProps)(withTranslation()(withRouter(Header)))
