import React from "react";
import Image from "./image";
import {storageSpace} from "../../functions";
import PopInfo from "./popinfo";
import Link from '../partials/link.js'
import Btn from "./btn";
// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";
import i18next from "i18next";

 class DealerInfoCard extends React.Component {
    render() {
        const { t } = this.props;
        const {product} = this.props;
        return (
            <div className="info-dealer">
                <div className="dealer-head">
                    <Image className="dealer-image" bg
                           src={storageSpace('dealers', product.dealer.logo)}/>
                    <div className="head-content">

                        <Link href={i18next.t(`routes:dealers`, { lng: i18next.resolvedLanguage })+`/${product.dealer.dealerUrl}`}>
                            <strong className="dealer-title">
                                <span className="dealer-badge">
                                    <PopInfo nowrap upsideDown content={t("partials.dealerInfoCard.popInfo")}
                                             noMarginLeft>
                                        <i className="badge-bg icon-ribbon"/><i className="badge-icon icon-check"/>
                                    </PopInfo>
                                </span>
                                <span>
                                    {product.dealer.title}
                                </span>

                            </strong>
                        </Link>
                        <p className="dealer-info">
                            <span className="info-location">{t("partials.dealerInfoCard.location")}</span>
                            {/*<span className="info-location">{product.dealer.location}</span>
                            {product.dealer.workingHours && (
                                <span className={"info-workinghours " + (product.dealer.open ? 'open' : 'closed')}>
															{product.dealer.workingHours}
                                    <span>|</span>
                                    {(product.dealer.open ? 'Şu an açık' : 'Şu an kapalı')}
														</span>
                            )}*/}

                        </p>
                    </div>
                </div>
               {/* {product.dealer.rep &&
                <div className="dealer-rep">
                    <Image src={product.dealer.rep.image ? product.dealer.rep.image : image_avatar}
                           className="rep-image"/>
                    <strong className="rep-title">{product.dealer.rep.name}</strong>
                    <span className="rep-role">{product.dealer.rep.role}</span>
                </div>
                }*/}
                <div className="dealer-controls">
                    <Link href={ `redirect?postNo=${product.postNo}`}  rel="noopener noreferrer" style={{width:"100%"}}>
                        <Btn uppercase block >{t("partials.dealerInfoCard.button")}</Btn>
                    </Link>
                </div>
            </div>

        )
    }
}
export default (withTranslation()(DealerInfoCard))
