import React from 'react'

// Sections

// Partials
import Image from 'components/partials/image'
import Link from 'components/partials/link'
import Slider from 'components/partials/slider'

// Deps
import { connect } from "react-redux";
// Assets
import image_icon_linkedin from 'assets/images/icon/linkedin.svg'
//import image_icon_facebook from 'assets/images/icon/facebook.svg'
//import image_icon_instagram from 'assets/images/icon/instagram.svg'
//import image_icon_youtube from 'assets/images/icon/youtube.svg'
// Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";

const mapStateToProps = state => {
	return { mobile: state.generic.mobile };
};

class About extends React.Component {

	render() {
		const { t } = this.props;
		let GalleryContainer = (this.props.mobile ? Slider : 'div');
		let galleryProps = (this.props.mobile ? { slides: 2 } : {});
		return (
			<main className="page about">
				<section className="section about-head">
					<h1 className="head-title" style={{ textTransform: 'none' }}>{t("pages.about.title")}</h1>
					<h2 className="head-subtitle">{t("pages.about.subtitle")}</h2>
				</section>
				<section className="section about-intro">
					<div className="wrapper narrow">
						<Image className="intro-image" src={i18next.resolvedLanguage==='tr' ? "/assets/images/HakkımızdaMinoto.jpg" : i18next.resolvedLanguage==='de' ?  "/assets/images/HakkımızdaMinotoDE.jpeg" : ""} />
						<div className="intro-text wysiwyg" style={{ paddingBottom: '0' }}>
							<strong>{t("pages.about.title")}</strong>{t("pages.about.text.0")}
						<br /><br />
							{t("pages.about.text.1")} <strong>{t("pages.about.strongText.0")}&nbsp;</strong>{t("pages.about.text.2")}
						<br /><br />
							<strong>{t("pages.about.strongText.1")}</strong> {t("pages.about.text.3")}&nbsp;
							<strong>{i18next.resolvedLanguage==='tr' ? t("pages.about.title") :''}</strong>&nbsp;
							{t("pages.about.text.4")} <strong>{i18next.resolvedLanguage==='tr' ? t("pages.about.title") :''}</strong>
							&nbsp;{t("pages.about.text.5")}

						</div>
					</div>
				</section>



				<section className="section about-brands">
					<div className="brands-wrap wrapper narrow">
						<Image className="brands-image" src="/dummy/images/about-imgs2.png" mobile="/dummy/images/about-imgs-mobile2.png" />

						<div className="brands-content">
							<h3 className="content-title">{t("pages.about.section2.title")}</h3>

							<div className="content-text wysiwyg">
								<p>{t("pages.about.section2.text.0")} <strong>{t("pages.about.title")}</strong> {t("pages.about.section2.text.1")}
									<strong>Minoto</strong>{t("pages.about.section2.text.2")}</p>
							</div>
						</div>
					</div>
				</section>




				<section className="section about-team">
					<h3 className="team-title">{t("pages.about.section3.title")}</h3>
					<div className="team-wrap wrapper narrow">
						<GalleryContainer className="team-container" {...galleryProps}>
							{/*<div className="team-member" style={{ border: 'none' }}></div>*/}

							<div className="team-member">
								<Image className="member-image" style={{ border: "1px solid #f2f2f2" }} src="/dummy/images/ferhat_albayrak2.JPG" bg contain abs alt={t("pages.about.section3.group.0.subtitle")} />
								<div className="member-content">
									<strong className="member-position">{t("pages.about.section3.group.0.title")}</strong>
									<h4 className="member-name">{t("pages.about.section3.group.0.subtitle")}</h4>

									<div className="member-contact">
										<Link type="a" title={t("pages.about.section3.group.0.linkTitle")}  href={t("pages.about.section3.group.0.link")}  rel="nofollow">
											<Image src={image_icon_linkedin} alt="LinkedIn" />
										</Link>
									</div>
								</div>
							</div>

							<div className="team-member">
								<Image className="member-image" src="/dummy/images/kaan_boyner.png" bg contain abs alt={t("pages.about.section3.group.1.subtitle")}/>
								<div className="member-content">
									<strong className="member-position">{t("pages.about.section3.group.1.title")}</strong>
									<h4 className="member-name">{t("pages.about.section3.group.1.subtitle")}</h4>

									<div className="member-contact">

									</div>
								</div>
							</div>

							<div className="team-member">
								<Image className="member-image" src="/dummy/images/fırat-alpaslan.png" bg abs alt={t("pages.about.section3.group.2.subtitle")} />
								<div className="member-content">
									<strong className="member-position">{t("pages.about.section3.group.2.title")}</strong>
									<h4 className="member-name">{t("pages.about.section3.group.2.subtitle")}</h4>

									<div className="member-contact">

									</div>
								</div>
							</div>

							{/*<div className="team-member" style={{ border: 'none' }}></div>*/}
						</GalleryContainer>
					</div>
				</section>
			</main>

		)
	}
}


export default connect(mapStateToProps)(withTranslation()(About))
