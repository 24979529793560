// import * as client from "./Client";
// import UrlAssembler from "url-assembler";
// import {GET_VEHICLE_DETAIL_ENDPOINT} from "./apiEndpoints";
export class LvpService {
    detail = (product) => {
        return product
    };

    delete = (product) => {
        return product
    };
}
