import React, {Component} from 'react'
import {withTranslation} from 'react-i18next';
//test
// Controllers
import history from './controllers/history'
import Navigator from './controllers/navigator'
import ScrollToTop from "./components/partials/ScrollToTop";

// Deps
import {BrowserRouter as Router} from 'react-router-dom';
import "@babel/polyfill";
import i18next from "i18next";


require('intersection-observer');
//require('es6-object-assign/auto');
//require('es6-promise').polyfill();


class App extends Component {

    componentDidMount() {
        document.documentElement.setAttribute("lang", i18next.resolvedLanguage);
    }

    render() {
        return (
            <>

                <style global jsx>{`
                  .block-overflow {
                    overflow: visible !important;
                    height: 100vh;
                  }
                `}</style>


                <Router history={history}>
                    <ScrollToTop />
                    <Navigator/>
                </Router></>

        );
    }
}


export default withTranslation()(App);
