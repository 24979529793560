import React from 'react'


// Sections

// Partials
//import Link from 'components/partials/link'

// Assets

//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";

class NotFound extends React.Component {
	render () {
		const { t } = this.props;
		return (
			<main className="page notfound">
				<h1>{t("pages.notFound.title")}</h1>
				<h2>{t("pages.notFound.subtitle")}</h2>
			</main>

		)
	}
}
export default (withTranslation()(NotFound))