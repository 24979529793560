import React from 'react'

// Partials
import Loader from 'components/partials/loader'
import ContentBox from 'components/partials/contentbox.js'
import Link from 'components/partials/link.js'

// Deps
//import { connect } from "react-redux"
import request from 'controllers/request'
import { redirect } from 'controllers/navigator'
import {  seoFriendlyUrl } from "functions/helpers";
// Assets
// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";

import i18next from "i18next";

const converter = {
	araclar: 'post',
	bayiler: 'dealer',
	markalar: 'brand',
}

const categoryNames = {
	araclar: "pages.account.favorites.categoryNames.0",
	bayiler: 'pages.account.favorites.categoryNames.1',
	markalar: 'pages.account.favorites.categoryNames.2',
};

class Favorites extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			favorites: false,
		}

		this.updateData = this.updateData.bind(this);
	}

	componentDidMount() {
		if (!this.props.match.params.section) {
			redirect('account.favorites', { section: i18next.t("pages.account.favorites.categoryNames.1") });
		}
		else {
			this.updateData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.section !== this.props.match.params.section) {
			this.updateData();
		}
	}

	updateData() {
		let vm = this;

		vm.setState({ favorites: false });

		request.get(`favorites/${converter[this.props.match.params.section]}`, {}, function (payload) {
			if (payload) {
				vm.setState({ favorites: payload })
			}
		}, { excludeApiPath: false });
	}

	render() {
		const { t } = this.props;
		let favorites = this.state.favorites;
		let section = this.props.match.params.section;
		return (
			<section className="section account-favorites loader-container">
				<nav className="favorites-nav">
					<Link className="nav-link" navLink href="account.favorites" params={{ section: "araclar"}}>{t("pages.account.favorites.navItems.0")}</Link>
					<Link className="nav-link" navLink href="account.favorites" params={{ section: "bayiler" }}>{t("pages.account.favorites.navItems.1")}</Link>
					<Link className="nav-link" navLink href="account.favorites" params={{ section: "markalar" }}>{t("pages.account.favorites.navItems.2")}</Link>
				</nav>
				<Loader loading={favorites === false} />
				{favorites &&
					<div className="wrapper narrow">
						{favorites.length ?
							<ul className="favorites-list">
								{favorites.map((item, nth) => {
									let contents = [];
									switch (section) {
										case i18next.t("pages.account.favorites.categoryNames.1"):
											contents.push(
												<li key={nth} className="list-item">
													<ContentBox
														type="plain"
														className={((item.status === 2 || item.status === 3) ? 'inactive' : '')}
														title={item.title}
														subtitle={item.dealer}
														additionTitle={item.count + t("pages.account.favorites.status.text4")}
														image={item.image}
														labels={item.labels}
														faved={item.favorited}
														//favControls={'/dummy/data/fav/dealer/'+item.id}
														badge={(item.status !== 1 ? false : (item.status === 2 ? { text: t("pages.account.favorites.status.text"), note: t("pages.account.favorites.status.note") } : { text:t("pages.account.favorites.status.text2"), type: 'error' }))}
														bottomNote={(item.currentViewers > 0 ? item.currentViewers + t("pages.account.favorites.status.text3") : false)}
														url={`/${t("pages.account.favorites.categoryNames.1")}/${item.link}`}
													/>
												</li>
											)
											break;
										case i18next.t("pages.account.favorites.categoryNames.2"):
											contents.push(
												<li key={nth} className="list-item">
													<ContentBox
														type="plain"
														className={((item.status === 2 || item.status === 3) ? 'inactive' : '')}
														title={item.title}
														labels={item.labels}
														additionTitle={item.count + t("pages.account.favorites.status.text4")}
														image={item.image}
														faved={item.favorited}
														//favControls={'/dummy/data/fav/dealer/'+item.id}
														url={`/${t("pages.account.favorites.categoryNames.2")}/${item.link}`}
													/>
												</li>
											)
											break;
										default:
											contents.push(
												<li key={nth} className="list-item">
													<ContentBox
														className={((item.status === 2 || item.status === 3) ? 'inactive' : '')}
														title={item.title}
														subtitle={item.dealer}
														image={item.image}
														price={item.price}
														labels={item.labels}
														faved={true}
														badge={(item.status === 1 ? false : (item.status === 2 ? { text: t("pages.account.favorites.status.text"), note: t("pages.account.favorites.status.note") } : { text:t("pages.account.favorites.status.text2"), type: 'error' }))}
														bottomNote={(item.currentViewers > 0 ? item.currentViewers + t("pages.account.favorites.status.text3") : false)}
														url="detail"
														urlParams={{ dealer: seoFriendlyUrl(item.dealerUrl + ' ' + item.dealer), slug: item.slug ? item.slug.substring(0, item.slug.lastIndexOf('-m')) : '', post: item.postNo }}
													/>
												</li>
											)
											break;
									}
									return contents;
								})}
							</ul>
							: false
						}
						{favorites.length === 0 ?
							<div className="favorites-error"><div className="error-message">{t(categoryNames[section])} {t("pages.account.favorites.errorMessage")}</div></div>
							: false
						}
					</div>
				}
			</section>
		)
	}
}
export default (withTranslation()(Favorites))
