import React from 'react'

// Partials
import Link from 'components/partials/link'
import Btn from 'components/partials/btn'
import { apiPath } from 'functions/helpers'
import { InputForm, FormInput } from 'components/partials/forms'
import axios from "axios";

// Deps
//import request from 'controllers/request'
//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";


class Contact extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			formMode: 'generic',
		}

		this.submit = this.submit.bind(this);
		this.changeFormMode = this.changeFormMode.bind(this);

		this.form = React.createRef();
	}

	submit(e) {
		let vm = this;
		vm.setState({ loading: true });
		//post statikte 404 verdiği için dummy olarak get
		//request.post('/dummy/data/contactform.json', serializeArray(e.target), function(response){
		//..

		let record = {
			name: e.target.elements.name.value,
			email: e.target.elements.email.value,
			phone: e.target.elements.phone.value,
			company: e.target.elements.company.value,
			body: e.target.elements.body.value,
		};


		//console.log(serializeArray(e.target));

		axios.post(apiPath('mail/contact'), record).then(res => {
			setTimeout(function () {
				vm.setState({ loading: false, complete: true });

				if (vm.form.current) {
					vm.form.current.reset();
				}

				setTimeout(function () {
					vm.setState({ loading: false, complete: false });
				}, 1000);
			}, 1000);
		})


		//request.get('/dummy/data/contactform.json', serializeArray(e.target), function (response) {
		/* request.post('mail/contact', serializeArray(e.target), function (response) {
			if (response !== false) {
				// Dummy timeout etc..
				setTimeout(function () {
					vm.setState({ loading: false, complete: true });

					setTimeout(function () {
						vm.setState({ loading: false, complete: false });
					}, 1000);
				}, 1000);
			}
			else {
				vm.setState({ loading: false });
			}
		}); */
	}

	changeFormMode(e) {
		this.setState({ formMode: e.target.value });
	}

	render() {

		const { t } = this.props;
		return (
			<main className="page contact">
				<section className="section contact-map">
					<iframe title={'google'} src={t('pages.contact.src')} width="100%" height="100%" style={{border:"0"}} allowFullScreen='' loading='lazy' referrerPolicy='no-referrer-when-downgrade'></iframe>
					{/*<GoogleMap
						className="map-container"
						markers={[{
							html: false,
							lat: 41.07704,
							lng: 29.02681
						}]}

						settings={{
							maxZoom: 16,
						}}
					/>*/}
				</section>
				<section className="section contact-info">
					<div className="info-wrap wrapper medium">
						<ul className="info-section info-details">
							<li className="details-item">
								<strong className="item-heading"> {t("pages.contact.titles.0")}</strong>
								<span className="item-content">{t("pages.contact.texts.0")}</span>
							</li>
						{/*	<li className="details-item" style={{ display: 'none' }}>
								<strong className="item-heading">Ticari Sicil No</strong>
								<span className="item-content">742942</span>
							</li>*/}
							<li className="details-item">
								<strong className="item-heading">{t("pages.contact.titles.1")}</strong>
								<span className="item-content"><Link type="a" href={t("pages.contact.texts.1.link")}>{t("pages.contact.texts.1.text")}</Link></span>
							</li>
						{/*	<li className="details-item" style={{ display: 'none' }} >
								<strong className="item-heading">Mersis No</strong>
								<span className="item-content">69486208399</span>
							</li>*/}
							<li className="details-item">
								<strong className="item-heading">{t("pages.contact.titles.2")}</strong>
								<span className="item-content">{t("pages.contact.texts.2")}</span>
							</li>
						</ul>

						<InputForm className="info-section info-form" onSubmit={this.submit} ref={this.form}>
							<div className="form-typeselection">
								<div className="typeselection-opt">
									<input
										id="typeselection_generic"
										type="radio"
										name="contact-method"
										value="generic"
										defaultChecked={this.state.formMode === 'generic'}
										onChange={this.changeFormMode}
										className="opt-input" />
									<label htmlFor="typeselection_generic">{t("pages.contact.form.title")}</label>
								</div>
								{/*
								<div className="typeselection-opt" style={{ display: 'none' }}>
									<input
										id="typeselection_dealer"
										type="radio"
										name="contact-method"
										value="dealer"
										defaultChecked={this.state.formMode === 'dealer'}
										onChange={this.changeFormMode}
										className="opt-input" />
									<label htmlFor="typeselection_dealer">Bayi İletişim</label>
								</div>
								*/}
							</div>
							<div className="form-contentwrap">
								{this.state.formMode === 'generic' &&
									<div className="form-section">
										<FormInput
											popLabel
											name="name"
											placeholder={t("pages.contact.form.formItems.0.placeholder")}
											type="text"
											validation={{
												fullName: true
											}}
											className="form-input" />
									</div>
								}
								{this.state.formMode === 'dealer' &&
									<div className="form-section x2">
										<FormInput
											popLabel
											name="dealer"
											placeholder={t("pages.contact.form.formItems.0.placeholder")}
											type="text"
											validation={{
												required: t("pages.contact.form.formItems.0.validations.required"),
												minLength: [ t("pages.contact.form.formItems.0.validations.minLength"), 4]
											}}
											className="form-input" />
									</div>
								}
								<div className="form-section">
									<FormInput
										popLabel
										name="email"
										placeholder={t("pages.contact.form.formItems.1.placeholder")}
										type="email"
										validation={{
											email: true
										}}
										className="form-input" />
								</div>
								<div className="form-section">
									<FormInput
										popLabel
										name="phone"
										placeholder={t("pages.contact.form.formItems.2.placeholder")}
										type="text"
										mask="(199) 999 99 99"
										validation={{
											required: t("pages.contact.form.formItems.2.validations.required"),
											minLength: [ t("pages.contact.form.formItems.2.validations.minLength"), 15]
										}}
										className="form-input" />
								</div>
								{this.state.formMode === 'generic' &&
									<div className="form-section">
										<FormInput
											popLabel
											name="company"
											placeholder={t("pages.contact.form.formItems.3.placeholder")}
											type="text"
											className="form-input" />
									</div>
								}
								<div className="form-section x2">
									<FormInput
										popLabel
										name="body"
										placeholder={t("pages.contact.form.formItems.4.placeholder")}
										type="textarea"
										validation={{
											required: t("pages.contact.form.formItems.4.validations.required"),
											minLength: [ t("pages.contact.form.formItems.4.validations.minLength"), 15]
										}}
										className="form-input" />
								</div>
								<div className="form-controls">
									<Btn wide loading={this.state.loading} type="submit" status={(this.state.complete ? 'check-thin' : false)} disabled={this.state.loading}>{t("pages.contact.form.button")}</Btn>
								</div>
							</div>
						</InputForm>
					</div>
				</section>
			</main>
		)
	}
}
export default (withTranslation()(Contact))
