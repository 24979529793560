import React from 'react'

// Partials
import Btn from 'components/partials/btn'
import Image from 'components/partials/image'
import {InputForm, FormInput} from 'components/partials/forms'
import MyAvatar from 'components/partials/avatar-editor'

// Deps
import {connect} from "react-redux"
import {openModal} from 'functions/modals'
import {storageSpace, serializeArray} from "functions/helpers";
import {logout, updateUserToken} from "data/store.user"
import request from 'controllers/request'


// Assets
import image_avatar from 'assets/images/defaults/avatar.svg';
// Multi language
import {withTranslation} from 'react-i18next';
import "../../i18n";


const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
};


class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            submitting: false,
            userData: false,
        }

        this.saveData = this.saveData.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.initialize = this.initialize.bind(this);
    }

    initialize() {
        let vm = this;


        request.get(`users/profile/${vm.props.user.email}`, null, function (payload, status) {
            if (payload) {
                vm.setState({
                    userData: payload,
                    loading: false,
                });
            }
        });

    }

    componentDidMount() {
        let vm = this;
        vm.initialize();
    }


    deleteAccount() {
        const {t} = this.props;
        openModal('confirm', {
            title: t("pages.account.profile.modal.title"),
            question: t("pages.account.profile.modal.question"),
            onConfirm: () => {
                //console.log('silindi..');
            },
            reverse: true,
        });
    }

    saveData(e) {

        let vm = this;


        // Form Data:
        let record = {
            email: vm.props.user.email,
            name: e.target.elements.name.value,
            phone: e.target.elements.phone.value,
            gender: e.target.elements.gender.value,
            workStatus: e.target.elements.workStatus.value,
            maritalStatus: e.target.elements.maritalStatus.value,
            city: e.target.elements.city.value,
            educationStatus: e.target.elements.educationStatus.value,
            birthDate: e.target.elements.birthDate.value
        };


        this.setState({
            submitting: true,
        });


        request.post(`users/profile/update`, record, function (payload) {
            setTimeout(function () {
                vm.setState({submitting: true});
                setTimeout(function () {
                    vm.setState({submitting: false});
                }, 100);
            }, 100);
        })
    }

    render() {
        const {t} = this.props;
        let vm = this;
        let user = vm.props.user;
        let userData = vm.state.userData

        return (
            <React.Fragment>
                {
                    userData && (
                        <section className="section account-profile">
                            <div className="profile-wrap wrapper narrow">
                                <aside className="profile-sum">
                                    <Image className="sum-image" bg
                                           src={(user.avatar ? storageSpace('profile-photos', user.avatar) : image_avatar)}/>
                                    <div className="sum-content">
                                        <div className="content-bio">
                                            <h1 className="bio-name">{user.name}</h1>
                                            {user.location &&
                                                <p className="bio-location"><i className="icon-marker"></i> {user.location}
                                                </p>
                                            }
                                        </div>
                                        {userData.profile.profileCompletion &&
                                            <div className="content-completion">
                                                <div className="completion-bar">
                                                    <div className="bar-progress"
                                                         style={{width: userData.profile.profileCompletion + '%'}}></div>
                                                </div>

                                                <div
                                                    className="completion-status">&nbsp;{t("pages.account.profile.accountProfile.statusText.0")}&nbsp;<span>%{userData.profile.profileCompletion}</span> {t("pages.account.profile.accountProfile.statusText.1")}
                                                </div>
                                            </div>
                                        }
                                        <button className="content-logout"
                                                onClick={logout}>{t("pages.account.profile.accountProfile.button")}</button>
                                    </div>
                                </aside>

                                <div className="profile-form">
                                    <InputForm className="form-section" onSubmit={vm.saveData}>
                                        <div className="form-group">
                                            <h2 className="form-title">{t("pages.account.profile.accountProfile.formItems1.title")}</h2>
                                            <FormInput
                                                name="name"
                                                disabled={vm.state.submitting}
                                                value={user.name}
                                                label={t("pages.account.profile.accountProfile.formItems1.0.placeholder")}
                                                validation={{
                                                    required: t("pages.account.profile.accountProfile.formItems1.0.validations.required"),
                                                    minLength: [t("pages.account.profile.accountProfile.formItems1.0.validations.minLength"), 5]
                                                }}
                                                className="form-inputwrap"/>
                                            <FormInput name="email" className="form-inputwrap"
                                                       disabled={vm.state.submitting} value={user.email}
                                                       label={t("pages.account.profile.accountProfile.formItems1.1.placeholder")}/>
                                            <FormInput
                                                name="phone"
                                                disabled={vm.state.submitting}
                                                label={t("pages.account.profile.accountProfile.formItems1.2.placeholder")}
                                                mask="(199) 999 99 99"

                                                className="form-inputwrap"
                                                value={userData.profile.phone}/>

                                            <Btn className="form-submit" type="submit" wide light text uppercase big
                                                 status={vm.state.submitting && 'loading'} disabled={vm.state.submitting}>
                                                {t("pages.account.profile.accountProfile.formItems1.button")}
                                            </Btn>
                                        </div>

                                        <div className="form-group">
                                            <h2 className="form-title">{t("pages.account.profile.accountProfile.formItems2.title")}</h2>
                                            <FormInput
                                                name="gender"
                                                type="select"
                                                className="form-inputwrap"
                                                disabled={vm.state.submitting}
                                                label={t("pages.account.profile.accountProfile.formItems2.0.label")}
                                                value={userData.profile.gender ? {
                                                    value: userData.profile.gender.value,
                                                    label: userData.profile.gender.label
                                                } : ''}
                                                options={userData.genders}
                                                placeholder={t("pages.account.profile.accountProfile.formItems2.0.placeholder")}/>

                                            <FormInput
                                                name="workStatus"
                                                type="select"
                                                className="form-inputwrap"
                                                disabled={vm.state.submitting}
                                                label={t("pages.account.profile.accountProfile.formItems2.1.label")}
                                                value={userData.profile.workState ? {
                                                    value: userData.profile.workState.value,
                                                    label: userData.profile.workState.label
                                                } : ''}
                                                options={userData.workStates}
                                                placeholder={t("pages.account.profile.accountProfile.formItems2.1.placeholder")}/>

                                            <FormInput
                                                name="maritalStatus"
                                                type="select"
                                                className="form-inputwrap"
                                                disabled={vm.state.submitting}
                                                label={t("pages.account.profile.accountProfile.formItems2.2.label")}
                                                value={userData.profile.maritalState ? {
                                                    value: userData.profile.maritalState.value,
                                                    label: userData.profile.maritalState.label
                                                } : ''}
                                                options={userData.maritalStates}
                                                placeholder={t("pages.account.profile.accountProfile.formItems2.2.placeholder")}/>

                                            <FormInput
                                                name="city"
                                                type="select"
                                                className="form-inputwrap"
                                                disabled={vm.state.submitting}
                                                label={t("pages.account.profile.accountProfile.formItems2.3.label")}
                                                value={userData.profile.city ? {
                                                    value: userData.profile.city.value,
                                                    label: userData.profile.city.label
                                                } : ''}
                                                options={userData.cities}
                                                placeholder={t("pages.account.profile.accountProfile.formItems2.3.placeholder")}/>

                                            <FormInput
                                                name="educationStatus"
                                                type="select"
                                                className="form-inputwrap"
                                                disabled={vm.state.submitting}
                                                label={t("pages.account.profile.accountProfile.formItems2.4.label")}
                                                value={userData.profile.educationState ? {
                                                    value: userData.profile.educationState.value,
                                                    label: userData.profile.educationState.label
                                                } : ''}
                                                options={userData.educationStates}
                                                placeholder={t("pages.account.profile.accountProfile.formItems2.4.placeholder")}/>

                                            <FormInput
                                                name="birthDate"
                                                type="date"
                                                className="form-inputwrap"
                                                disabled={vm.state.submitting}
                                                label={t("pages.account.profile.accountProfile.formItems2.5.label")}
                                                value={userData.profile.birthDate ? userData.profile.birthDate : ''}
                                                placeholder={t("pages.account.profile.accountProfile.formItems2.5.placeholder")}/>

                                            <Btn
                                                className="form-submit"
                                                type="submit"
                                                wide light text uppercase big
                                                status={vm.state.submitting && 'loading'}
                                                disabled={vm.state.submitting}

                                            >
                                                {t("pages.account.profile.accountProfile.formItems2.button")}
                                            </Btn>
                                        </div>
                                    </InputForm>

                                    {false &&
                                        <PasswordForm/>}

                                    <InputForm className="form-section">
                                        <div className="form-group">
                                            <h2 className="form-title">{t("pages.account.profile.accountProfile.formItems3.title")}</h2>
                                            <MyAvatar/>
                                        </div>

                                        {/*<button className="form-delete" type="button" onClick={vm.deleteAccount}>Hesabımı sil</button>*/}
                                    </InputForm>
                                </div>
                            </div>
                        </section>
                    )
                }
            </React.Fragment>
        )
    }
}

class PasswordForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            message: false,
            submitting: false,
        }

        this.saveData = this.saveData.bind(this);

        this.form = React.createRef();
    }

    saveData(e) {
        let vm = this;

        vm.setState({
            submitting: true,
        });

        request.post(`user/change-password`, serializeArray(e.target), function (payload) {
            let message = false;
            let error = false;

            if (payload) {
                error = !payload.success;
                message = payload.message;

                if (payload.success && vm.form.current) {
                    vm.form.current.reset();

                    updateUserToken(payload.auth_token);
                }
            } else {
                error = true;
                message = 'Şifreniz değiştirilirken bir hata ile karşılaşıldı. Lütfen daha sonra tekrar deneyin.';
            }

            vm.setState({submitting: false, error: error, message: message});
        })
    }

    render() {
        const {t} = this.props;
        let vm = this;
        return (
            <InputForm className="form-section" onSubmit={vm.saveData} ref={vm.form}>
                <div className="form-group">
                    <h2 className="form-title">{t("pages.account.profile.title")}</h2>
                    {vm.state.message &&
                        <div className={'form-message ' + (vm.state.error ? 'error' : 'success')}>
                            <span>{vm.state.message}</span>
                        </div>
                    }
                    <FormInput
                        name="password"
                        type="password"
                        className="form-inputwrap"
                        disabled={vm.state.submitting}
                        validation={{
                            required: t("pages.account.profile.form.0.validations.required"),
                            minLength: [t("pages.account.profile.form.0.validations.minLength"), 6]
                        }}
                        label={t("pages.account.profile.form.0.placeholder")}/>

                    <FormInput
                        name="new_password"
                        type="password"
                        id="register-password"
                        className="form-inputwrap"
                        disabled={vm.state.submitting}
                        validation={{
                            required: t("pages.account.profile.form.1.validations.required"),
                            minLength: [t("pages.account.profile.form.1.validations.minLength"), 6],
                            "compare": [t("pages.account.profile.form.0.validations.compare"), "#register-password-repeat"]
                        }}
                        label={t("pages.account.profile.form.1.placeholder")}/>

                    <FormInput
                        name="new_password_repeat"
                        type="password"
                        id="register-password-repeat"
                        className="form-inputwrap"
                        disabled={vm.state.submitting}
                        validation={{
                            required: t("pages.account.profile.form.2.validations.required"),
                            minLength: [t("pages.account.profile.form.2.validations.minLength"), 6],
                            "compare": [t("pages.account.profile.form.1.validations.compare"), "#register-password"]
                        }}
                        label={t("pages.account.profile.form.2.placeholder")}/>

                    <Btn
                        className="form-submit"
                        type="submit"
                        wide light text uppercase big
                        status={vm.state.submitting && 'loading'}
                        disabled={vm.state.submitting}>
                        {t("pages.account.profile.button")}
                    </Btn>
                </div>
            </InputForm>
        )
    }
}

export default connect(mapStateToProps)(withTranslation()(Profile));

