import React from 'react'
//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";


const text = "pages.gdprPolicy.text"


class GdprPolicy extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <main className="page content">
                <section className="section contentpage">
                    <div className="contentpage-wrap wrapper narrow">
                        <div className="contentpage-content">
                            <h1 className="content-title">
                                {t("pages.gdprPolicy.title")}
                            </h1>
                            <div className="section contentpage-textarea wysiwyg">
                                <p className="indent">    {t(text)}</p>

                                <h3 className="text-center"> {t("pages.gdprPolicy.contents.0.title")}</h3>
                                <p className="indent">{t("pages.gdprPolicy.contents.0.text")}</p>

                                <h3 className="text-center"> {t("pages.gdprPolicy.contents.1.title")}</h3>
                                <p className="indent">{t("pages.gdprPolicy.contents.1.text")}</p>

                                <h3 className="text-center"> {t("pages.gdprPolicy.contents.2.title")}</h3>
                                <p className="indent">{t("pages.gdprPolicy.contents.2.text")}</p>

                                <h3 className="text-center"> {t("pages.gdprPolicy.contents.3.title")}</h3>
                                <p className="indent">{t("pages.gdprPolicy.contents.3.text")}</p>

                                <h3 className="text-center"> {t("pages.gdprPolicy.contents.4.title")}</h3>
                                <p className="indent">{t("pages.gdprPolicy.contents.4.text")}</p>

                                <ol>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.0")}</li>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.1")}</li>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.2")}</li>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.3")}</li>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.4")}</li>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.5")}</li>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.6")}</li>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.7")}</li>
                                    <li> {t("pages.gdprPolicy.contents.4.listItems.8")}</li>
                                </ol>


                                <h3 className="text-center">{t("pages.gdprPolicy.bottom.title")}</h3>
                                <p className="indent"> {t("pages.gdprPolicy.bottom.texts.0")}</p>
                                <p className="indent">{t("pages.gdprPolicy.bottom.texts.1")}</p>
                                <p className="indent noMargin">
                                    <strong>{t("pages.gdprPolicy.bottom.texts.2")}</strong>{t("pages.gdprPolicy.bottom.texts.3")}
                                </p>
                                <p className="indent noMargin">
                                    <strong>{t("pages.gdprPolicy.bottom.texts.4")}</strong> {t("pages.gdprPolicy.bottom.texts.5")}
                                </p>
                                <p className="indent noMargin"><small><em>{t("pages.gdprPolicy.bottom.texts.6")}</em></small></p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        )
    }
}
export default (withTranslation()(GdprPolicy))