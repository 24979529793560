import React from 'react'

// Partials
import Link from 'components/partials/link'
import Loader from 'components/partials/loader'

// Deps
import request from 'controllers/request'
import { updateUserData } from "data/store.user.js"


// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";

class ConfirmForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			submitting: false,
			complete: false,
			error: false,
		}
	}

	componentDidMount() {
		let vm = this;

		request.get('users/control-token/registration/' + vm.props.email + '/' + vm.props.token, {}, function (payload) {
			if (payload && payload.success) {

				vm.setState({
					loading: false,
					complete: true,
				});

				if (payload.userData) {
					setTimeout(function () {
						updateUserData(payload);
					}, 3000); /// Dummy Delay

				}
			}
			else {
				vm.setState({
					loading: false,
					error: true,
				});
			}
		});
	}

	render() {
		const { t } = this.props;

		let vm = this;


		if (vm.state.complete) {
			return (
				<div className="section loginform type-self">
					<h2 className="loginform-title">{t("partials.formConfirm.title")}</h2>

					<div className="loginform-info wysiwyg">
						<p><strong>{t("partials.formConfirm.info.0")}</strong></p>
						<p>{t("partials.formConfirm.info.1")}</p>
					</div>
					<div className="loginform-nav center">
						<Link href="home" className="nav-btn">{t("partials.formConfirm.navs.0")}</Link>
						<Link href="account" className="nav-btn">{t("partials.formConfirm.navs.1")}</Link>
					</div>
				</div>
			)
		}
		else {
			return (
				<div className="section loginform loader-container type-self">
					<Loader loading={vm.state.loading} strict />
					<h2 className="loginform-title">{t("partials.formConfirm.form.title")}</h2>

					{vm.state.error ?
						<React.Fragment>
							<div className="loginform-message error">
								<span>{t("partials.formConfirm.form.errorMessage")}</span>
							</div>
							<div className="loginform-nav center">
								<Link href="home" className="nav-btn"><i className="icon-arrow-left"></i> {t("partials.formConfirm.navs.0")}</Link>
							</div>
						</React.Fragment>
						:
						<div className="loginform-message">
							<span>{t("partials.formConfirm.form.message")}</span>
						</div>
					}

				</div>
			)
		}
	}
}
export default (withTranslation()(ConfirmForm))
