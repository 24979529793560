import React from 'react'

// Sections

// Partials
//import Link from 'components/partials/link'
import Btn from 'components/partials/btn'

// Deps
import { openModal } from 'functions/modals'
//import request from 'controllers/request'

// Assets

// Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";


class Home extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dummyProduct: false,
		}
	}

	componentDidMount(){
		//let vm = this;

		/*request.get('/dummy/data/detail.json', { id: 1}, function(payload, status){
			if(payload){
				vm.setState({
					dummyProduct: payload
				});

				openModal('youtube', { url: payload });
			}
		}, { excludeApiPath: true });*/
		openModal('youtube', { url: 'https://www.youtube.com/watch?v=blu0u0y4-0A' });
	}

	render() {
		const { t } = this.props;

		return (
			<main className="page sitemap">
				<div className="wrapper">
					<h1>{t("pages.siteMap.0.title")}</h1>

					<section>
						<h2>{t("pages.siteMap.0.subtitle")}</h2>
						<Btn tag="link" className="btn" dark href="home" />
						<Btn tag="link" className="btn" dark href="search" />
						<Btn tag="link" className="btn" dark href="detail" params={{ id: "5", slug: "fullback-rock-4x2-m-t-2-4-150-hp-m1561662303" }} />
						<Btn tag="link" className="btn" dark href="brands" />
						<Btn tag="link" className="btn" dark href="brand" params={{ id: "audi" }} />
						<Btn tag="link" className="btn" dark href="dealers" />
						<Btn tag="link" className="btn" dark href="dealer" params={{ id: "13", slug: "asf" }} />
						<Btn tag="link" className="btn" dark href="branch" params={{ id: "10", slug: "asf-kartal" }} />
						<Btn tag="link" className="btn" dark href="listprices" />
						<Btn tag="link" className="btn" dark href="account" />
						<Btn tag="link" className="btn" dark href="notfound" />
						<Btn tag="link" className="btn" dark href="about" />
						<Btn tag="link" className="btn" dark href="reservation.info" params={{ id: "432943312" }} />
						<Btn tag="link" className="btn" dark href="reservation.payment" params={{ id: "432943312" }} />
						<Btn tag="link" className="btn" dark href="reservation.sum" params={{ id: "A5011CMinoto" }} />
						<Btn tag="link" className="btn" dark href="faq" />
						<Btn tag="link" className="btn" dark href="privacy" />
						<Btn tag="link" className="btn" dark href="contact" />
						<Btn tag="link" className="btn" dark href="blog" />
						<Btn tag="link" className="btn" dark href="blogDetail" params={{ slug: "lorem-ipsum-dolor-sit-amet" }}>{t("pages.siteMap.0.detailText")}</Btn>
						<Btn tag="link" className="btn" dark href="dealerApplication" />
					</section>

					<section>
						<h2>{t("pages.siteMap.1.items.title")}</h2>
						<h3>{t("pages.siteMap.1.items.subtitle")}</h3>
						<button className="btn" type="button" onClick={() => openModal('login')}>{t("pages.siteMap.1.items.0")}</button>
						<button className="btn" type="button" onClick={() => openModal('register')}>{t("pages.siteMap.1.items.1")}</button>
						<button className="btn" type="button" onClick={() => openModal('recovery')}>{t("pages.siteMap.1.items.2")}</button>
						<button className="btn" type="button" onClick={() => openModal('consent')}>{t("pages.siteMap.1.items.3")}</button>
						<button className="btn" type="button" onClick={() => openModal('share', { url: 'http://www.thinkerfox.com/123343535234390856465464' })}>{t("pages.siteMap.1.items.4")}</button>
						<button className="btn" type="button" onClick={() => openModal('options', {
							question: t("pages.siteMap.question"),
							title: "Soru", opts: [
								{
									text: t("pages.siteMap.answers.5"),
									onClick: () => { alert(t("pages.siteMap.answers.3")) },
									className: "primary"
								},
								{ text: t("pages.siteMap.answers.4") }
							]
						})}>{t("pages.siteMap.buttons.0")}</button>
						<button className="btn" type="button" onClick={() => openModal('confirm', {
							question: t("pages.siteMap.question"),
							title: t("pages.siteMap.buttons.1"),
							onConfirm: () => { alert(t("pages.siteMap.answers.0")) },
							onDeny: () => { alert(t("pages.siteMap.answers.1"))}
						})}>{t("pages.siteMap.buttons.1")}</button>
						<button className="btn" type="button" onClick={() => openModal('map', {
							markers: [{
								html: false,
								lat: 41.109604,
								lng: 29.022052
							}]
						})}>{t("pages.siteMap.buttons.2")}</button>
						<button className="btn" type="button" onClick={() => openModal('bid', { advert: this.state.dummyProduct })}>{t("pages.siteMap.buttons.3")}</button>
						<h3>{t("pages.siteMap.1.buttons.title")}</h3>
						<Btn primary>{t("pages.siteMap.1.buttons.0")}</Btn>
						<Btn dark>{t("pages.siteMap.1.buttons.1")}</Btn>
						<Btn white>{t("pages.siteMap.1.buttons.2")}</Btn>
						<Btn primary hollow>{t("pages.siteMap.1.buttons.3")}</Btn>
						<Btn dark hollow>{t("pages.siteMap.1.buttons.4")}</Btn>
						<Btn white hollow>{t("pages.siteMap.1.buttons.5")}</Btn><br />
						<Btn status="loading">{t("pages.siteMap.1.buttons.6")}</Btn>
						<Btn icon="check">{t("pages.siteMap.1.buttons.7")}</Btn>
						<Btn rightIcon="check">{t("pages.siteMap.1.buttons.8")}</Btn>
						<Btn note={t("pages.siteMap.note")}>{t("pages.siteMap.1.buttons.9")}</Btn>

					</section>
				</div>
			</main>

		)
	}
}
export default (withTranslation()(Home))
