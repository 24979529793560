import React from 'react'
import parse from "html-react-parser";
import OtvCalculator from "../components/partials/otv-calculator";
//Multi language
import { withTranslation} from 'react-i18next';
import "../i18n";
import i18next from "i18next";

class Otv extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <main className="page content otv-calculator">
                <section className="section contentpage">
                    <div className="contentpage-wrap wrapper narrow">
                        <div className="contentpage-content">
                            <h1 className="content-title">{t("pages.otv.title")}</h1>
                            <OtvCalculator t={t}/>
                            {/*  Content  Wrapper Begin */}
                            <div className="row contents">
                                <div className="col">
                                    {contents &&
                                        <Contents contents={contents} t={t}/>
                                    }
                                </div>
                            </div>
                            {/*  Content  Wrapper End */}
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}
export default (withTranslation()(Otv))

function Contents(props) {



    const {contents} = props;
    return (
        contents.map((content, nth) => (
            <div key={nth}>
                <h2>{i18next.t(content.title)} </h2>
                <p>{parse(i18next.t(content.description))}</p>
            </div>
        ))
    )
}

const contents = [
    {
        title: "pages.otv.contents.0.title",
        description: 'pages.otv.contents.0.description'
    },
    {
        title:'pages.otv.contents.1.title',
         description: 'pages.otv.contents.1.description'
    },
    {
        title: 'pages.otv.contents.2.title',
         description: 'pages.otv.contents.2.description'
    },
    {
        title: 'pages.otv.contents.3.title',
          description: 'pages.otv.contents.3.description'
    },
]



