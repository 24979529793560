import React from 'react'

// Pages
import Login from 'pages/account/login'
import Register from 'pages/account/register'
import Recovery from 'pages/account/recovery'
import ConfirmEmail from 'pages/account/confirm-email'
import Profile from 'pages/account/profile'
import Notifications from 'pages/account/notifications'
import Favorites from 'pages/account/favorites'
import Messages from 'pages/account/messages'
import MessageConversation from 'pages/account/messages-conversation'
import Reservations from 'pages/account/reservations'

// Sections

// Partials
//import Image from 'components/partials/image'
import Link from 'components/partials/link'
import Loader from 'components/partials/loader'

// Deps
import {connect} from "react-redux"
import {checkLoginStatus} from "data/store.user"
import isEqual from 'lodash/isEqual';
import {renderRoutes, redirect} from 'controllers/navigator'

// Multi language
import {withTranslation} from 'react-i18next';
import "../i18n";
import i18next from "i18next";


const pageRegistry = {
    Login: Login,
    Register: Register,
    Recovery: Recovery,
    ConfirmEmail: ConfirmEmail,
    Profile: Profile,
    Notifications: Notifications,
    Favorites: Favorites,
    Messages: Messages,
    MessageConversation: MessageConversation,
    Reservations: Reservations,
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
        currentPage: state.generic.currentPage,
    };
};

class Account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: (props.user ? false : true)
        }

        this.checkLogin = this.checkLogin.bind(this);
    }

    componentDidMount() {
        this.checkLogin();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentPage.fullKey !== this.props.currentPage.fullKey) {
            this.checkLogin();
        } else if (this.props.user !== false && this.state.loading && !isEqual(prevProps.user, this.props.user)) {
            this.setState({loading: false});
        }
    }

    checkLogin() {
        let vm = this;

        if (vm.props.currentPage.data.requiresLogin) {
            checkLoginStatus(function (status) {
                if (!status) {
                    redirect('account.login');
                    vm.setState({loading: false});
                } else {
                    vm.setState({loading: false});
                }
            })
        } else {
            vm.setState({loading: false});
        }
    }

    render() {

        let vm = this;
        return (
            <main className="page account loader-container">
                <Loader loading={vm.state.loading}/>
                {(!vm.state.loading && !(!vm.props.user && vm.props.currentPage.data.requiresLogin)) &&
                    <div className="section account-wrap">

                        <nav className="section account-nav">
                            <div className="nav-innerwrap">
                                <Link className="nav-item" navLink
                                      href={i18next.t("paths.profile")}>{i18next.t("pages.account.navItems.0")}</Link>
                                <Link className="nav-item" navLink
                                      href={i18next.t("routes.pages.favorites.accountPath")}>{i18next.t("pages.account.navItems.1")}</Link>
                                <Link className="nav-item" navLink href={i18next.t("paths.messages")}
                                      exact={false}>{i18next.t("pages.account.navItems.2")}</Link>
                                <Link className="nav-item" navLink
                                      href={i18next.t("paths.reservations")}>{i18next.t("pages.account.navItems.3")}</Link>
                            </div>
                        </nav>

                 {/*       {vm.props.currentPage.data.requiresLogin &&
                            <nav className="section account-nav">
                                <div className="nav-innerwrap">
                                    <Link className="nav-item" navLink
                                          href={i18next.t("routes.pages.profile.path")}>{i18next.t("pages.account.navItems.0")}</Link>
                                    <Link className="nav-item" navLink
                                          href={i18next.t("routes.pages.favorites.path")}>{i18next.t("pages.account.navItems.1")}</Link>
                                    <Link className="nav-item" navLink href={i18next.t("routes.pages.messages.path")}
                                          exact={false}>{i18next.t("pages.account.navItems.2")}</Link>
                                    <Link className="nav-item" navLink
                                          href={i18next.t("routes.pages.reservations.path")}>{i18next.t("pages.account.navItems.3")}</Link>
                                </div>
                            </nav>
                        }*/}


                        {renderRoutes({group: 'account', registry: pageRegistry, catchRedirect: 'account.profile'})}
                    </div>
                }
                {!(!vm.state.loading && !(!vm.props.user && vm.props.currentPage.data.requiresLogin)) &&
                    <span>no</span>
                }
            </main>
        )
    }
}

export default connect(mapStateToProps)(withTranslation()(Account))
