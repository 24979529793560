import React from 'react'

// Partials
import { InputForm, FormInput } from 'components/partials/forms'
import Btn from 'components/partials/btn'
import Link from 'components/partials/link'

// Deps
import { openModal, closeModal } from 'functions/modals'
import { redirect } from 'controllers/navigator'
import { register } from "data/store.user"
// Multi language
import { withTranslation } from 'react-i18next';
import "../../i18n";
import i18next from "i18next";


class RegisterForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			message: false,
			success: false,
			touched: false,
			promoChecked: false,
			email: "-",
			hasError: false,
		}

		//this._registerUser = this._registerUser.bind(this);
		this.submit = this.submit.bind(this);
	}

	submit(e) {
		let vm = this;

		vm.setState({
			loading: true,
		});

		register(e.target, function (payload) {
			if(payload.success === true){
				vm.setState({
					success: payload.success,
					loading: false,
					message: payload.message,
					email: e.target.elements.email.value,
				});
			}
			else {
				vm.setState({
					loading: false,
					message: payload.message,
					hasError: true
				});
			}


			/*if (payload.success) {
				setTimeout(function () {
					closeModal();
				}, 1000);
			}*/
		});
	}

	render() {
		const { t } = this.props;
		let vm = this;
		return (
			<div className={"section loginform type-" + vm.props.type}>
				{vm.state.success ?
					<React.Fragment>
						<h2 className="loginform-title">{t("partials.formRegister.title")}</h2>
						<div className="loginform-info wysiwyg">
							<p>{t("partials.formRegister.text.0")} <strong>{vm.state.email}</strong> {t("partials.formRegister.text.1")}</p>
							<p><strong>{t("partials.formRegister.text.2")}</strong></p>
							<ul>
								<li>{t("partials.formRegister.text.3")}</li>
								<li>{t("partials.formRegister.text.4")}</li>
								<li>{t("partials.formRegister.text.5")}</li>
							</ul>
						</div>
						<div className="loginform-nav center">
							<button type="button" className="nav-btn" onClick={closeModal}>{t("partials.formRegister.close")}</button>
						</div>
					</React.Fragment>
					:
					<React.Fragment>
						<h2 className="loginform-title">{t("partials.formRegister.formItems.title")}</h2>
						<InputForm className="loginform-form" onSubmit={this.submit} onTouch={() => { vm.setState({ touched: true }) }}>
							{vm.state.message &&
								<div className={"loginform-message " + (vm.state.success ? 'success' : 'error')}>
									<span>{vm.state.message}</span>
								</div>
							}
							<FormInput
								name="name"
								disabled={vm.state.loading}
								type="text"
								label={t("partials.formRegister.formItems.0.placeholder")}
								autoComplete="name"
								validation={{ required: 		t("partials.formRegister.formItems.0.validations.required"), minLength: [t("partials.formRegister.formItems.0.validations.minLength"), 5] }}
								className="form-field" />
							<FormInput
								name="email"
								disabled={vm.state.loading}
								type="email"
								label={t("partials.formRegister.formItems.1.placeholder")}
								autoComplete="email"
								validation={{ required: t("partials.formRegister.formItems.1.validations.required"), email: true }}
								className="form-field" />
							<FormInput
								name="password"
								id="register-password"
								disabled={vm.state.loading}
								type="password"
								label={t("partials.formRegister.formItems.2.placeholder")}
								autoComplete="new-password"
								validation={{ required: t("partials.formRegister.formItems.2.validations.required"), minLength: [t("partials.formRegister.formItems.2.validations.minLength"), 6], "compare": [t("partials.formRegister.formItems.2.validations.compare"), "#register-password-repeat"] }}
								className="form-field" />
							<FormInput
								name="password_repeat"
								id="register-password-repeat"
								disabled={vm.state.loading}
								type="password"
								label={t("partials.formRegister.formItems.3.placeholder")}
								autoComplete="new-password"
								validation={{ required: t("partials.formRegister.formItems.3.validations.required"), minLength: [t("partials.formRegister.formItems.3.validations.minLength"), 6], "compare": [t("partials.formRegister.formItems.3.validations.compare"), "#register-password"] }}
								className="form-field" />
							<FormInput
								name="agreement"
								value="1"
								disabled={vm.state.loading}
								type="checkbox"
								validation={{ required: t("partials.formRegister.formItems.4.validations.required") }}
								className="form-field">
								{t("partials.formRegister.formItems.4.text.0")}
								<Link className="field-link text-minoto"
									  href={i18next.t(`routes:privacy`, { lng: i18next.resolvedLanguage })}
									  rel="noopener noreferrer">{t("partials.formRegister.formItems.4.text.1")}
								</Link>{t("partials.formRegister.formItems.4.text.2")}
							</FormInput>
							<FormInput
								name="promotions"
								value="1"
								disabled={vm.state.loading}
								type="checkbox"
								onChange={(e) => { vm.setState({ promoChecked: e }) }}
								className="form-field">
								{t("partials.formRegister.formItems.5")}
							</FormInput>
							{vm.state.touched && !vm.state.promoChecked &&
								<div className="form-fakeerror inputwrap">
									<div className="input-error">
										{t("partials.formRegister.formItems.6")}
									</div>
								</div>
							}
							<Btn
								className="form-field"
								type="submit"
								disabled={vm.state.loading}
								loading={vm.state.loading}
								block uppercase light>{t("partials.formRegister.formItems.title")}</Btn>
						</InputForm>
						<div className="loginform-nav">
							<span>
									{t("partials.formRegister.formItems.7.text")} <button type="button" className="nav-btn" onClick={() => { vm.goToLogin() }}>	{t("partials.formRegister.formItems.7.button")} </button>
							</span>
						</div>
					</React.Fragment>
				}
			</div>
		)
	}

	goToLogin() {
		if (this.props.type === "self") {
			redirect('account.login');
		}
		else {
			openModal('login')
		}
	}
}

RegisterForm.defaultProps = {
	type: "self"
}
export default (withTranslation()(RegisterForm))
