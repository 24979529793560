import React from 'react'

// Partials
import { InputForm, FormInput } from 'components/partials/forms'
import Btn from 'components/partials/btn'

// Deps
import request from 'controllers/request'
import { openModal } from 'functions/modals'
import isEqual from "lodash/isEqual";
//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";

import i18next from "i18next";




const agreement = "pages.dealerApplication.texts.agreement.0"

class Faq extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			submitting: false,
			cities: false,
			districts: false,
			brands: false,
			selectedBrands: [],
			availableBrands: [],
			touched: false,
			selectedDistrict: false,
			selectedCity: false
		}

		this.changeCity = this.changeCity.bind(this);
		this.changeDistrict = this.changeDistrict.bind(this);
		this.saveData = this.saveData.bind(this);
		this.addBrand = this.addBrand.bind(this);
		this.removeBrand = this.removeBrand.bind(this);

		this.form = React.createRef();
	}

	componentDidMount() {
		let vm = this;
		request.get(`cities/lookup?language=${i18next.resolvedLanguage}`, {}, function (payload) {
			if (payload) {
				vm.setState({
					cities: payload
				});
			}
		}, { excludeApiPath: false });

		request.get(`brands/lookup/all?language=${i18next.resolvedLanguage}`, {}, function (payload) {
			if (payload) {
				vm.setState({
					brands: payload,
				});
			}
		}, { excludeApiPath: false });
	}

	componentDidUpdate(prevProps, prevState) {
		let vm = this;
		if (!isEqual(prevState.brands, vm.state.brands) || !isEqual(prevState.selectedBrands, vm.state.selectedBrands)) {
			vm.setState({
				availableBrands: vm.state.brands.reduce(function (filtered, brand) {
					if (!vm.state.selectedBrands.filter(sBrand => (sBrand.value === brand.value)).length) {
						filtered.push(brand);
					}
					return filtered;
				}, [])
			});
		}
	}

	saveData(e) {
		let vm = this;
		// Form Data:
		if (vm.state.selectedBrands.length) {
			this.setState({
				submitting: true,
			});

			/*	let record;
			record = i18next.resolvedLanguage === 'de' ? {
				dealer_name: e.target.elements.name.value,
				dealer_representative: e.target.elements.manager.value,
				email: e.target.elements.email.value,
				mobile: e.target.elements.phone.value,
				phone: e.target.elements.phone_land.value,
				address: e.target.elements.address.value,
				brand_list: vm.state.selectedBrands,
				note: e.target.elements.note.value,
				tax_no: e.target.elements.taxnumber.value,
				tax_office: e.target.elements.taxoffice.value

			} : {
				dealer_name: e.target.elements.name.value,
				dealer_representative: e.target.elements.manager.value,
				email: e.target.elements.email.value,
				mobile: e.target.elements.phone.value,
				phone: e.target.elements.phone_land.value,
				address: e.target.elements.address.value,
				brand_list: vm.state.selectedBrands,
				city_id: vm.state.selectedCity,
				district_id: vm.state.selectedDistrict,
				note: e.target.elements.note.value,
				tax_no: e.target.elements.taxnumber.value,
				tax_office: e.target.elements.taxoffice.value
			};*/

			/*		request.post('dealers/apply', record, function (payload) {
				if (payload.success) {
					setTimeout(function () {
						vm.setState({ loading: false, complete: true });

						if (vm.form.current) {
							vm.form.current.reset();
						}

						vm.props.history.push(`/bayi-basvuru-durumu/${payload.applicationNo}`);


					}, 1000);
				}
			}, { excludeApiPath: false });*/
		}
	}

	changeCity(city) {
		let vm = this;

		vm.setState({ districts: false, selectedCity: city });

		if (city) {
			request.get(`districts/lookup/${city}`, { id: city }, function (payload) {
				if (payload) {
					vm.setState({
						districts: payload
					});
				}
			}, { excludeApiPath: false });
		}


	}

	changeDistrict(district) {
		let vm = this;

		vm.setState({ districts: false, selectedDistrict: district });
	}

	addBrand(selected) {
		if (selected) {
			let newSelected = this.state.selectedBrands.concat(this.state.availableBrands.filter(brand => { return brand.value === selected; }));
			this.setState({
				selectedBrands: newSelected
			});
		}
	}

	removeBrand(id) {
		this.setState({
			selectedBrands: this.state.selectedBrands.filter(brand => (brand.value !== id))
		});
	}

	render() {

		const { t } = this.props;

		return (
			<main className="page content">
				<section className="section contentpage">
					<div className="contentpage-wrap wrapper narrow">
						<div className="contentpage-content">
							<h1 className="content-title">{t("pages.dealerApplication.title")}</h1>
							<p className="content-subtitle">
								{t("pages.dealerApplication.subtitle.0")}<br></br>	{t("pages.dealerApplication.subtitle.1")}
							</p>
							<InputForm className="section contentpage-form grid-container" ref={this.form} onSubmit={this.saveData}>
								<div className="grid-row">
									<div className="grid-col x6 m-x12">
										<FormInput
											type="text"
											name="name"
											placeholder={t("pages.dealerApplication.form.formItems.0.placeHolder")}
											validation={t("pages.dealerApplication.form.formItems.0.validation")}
											popLabel />
									</div>
									<div className="grid-col x6 m-x12">
										<FormInput
											type="text"
											name="manager"
											placeholder={t("pages.dealerApplication.form.formItems.1.placeHolder")}
											validation={t("pages.dealerApplication.form.formItems.1.validation")}
											popLabel />
									</div>
								</div>
								{
									i18next.resolvedLanguage==='de' ?
										<div className="x12">
											<FormInput
												type="text"
												name="handelsregisterNummer"
												placeholder={t("pages.dealerApplication.form.formItems.2.placeHolder")}
												validation={t("pages.dealerApplication.form.formItems.2.validation")}
												popLabel />
										</div>
										:
										<div className="grid-row">
											<div className="grid-col x6 m-x12">
												<FormInput
													type="text"
													name="taxoffice"
													placeholder={t("pages.dealerApplication.form.formItems.2.placeHolder")}
													validation={t("pages.dealerApplication.form.formItems.2.validation")}
													popLabel />
											</div>
											<div className="grid-col x6 m-x12">
												<FormInput
													type="number"
													name="taxnumber"
													placeholder={t("pages.dealerApplication.form.formItems.3.placeHolder")}
													mask="00000000000"
													validation={t("pages.dealerApplication.form.formItems.3.validation")}
													popLabel />
											</div>
										</div>

								}


								<div className="grid-row">
									<div className="grid-col x6 m-x12">
										<FormInput
											name="phone_land"
											disabled={this.state.submitting}
											placeholder={t("pages.dealerApplication.form.formItems.4.placeHolder")}
											mask="(199) 999 99 99"
											validation={{

												required: t("pages.dealerApplication.form.formItems.4.validation.required"),
												minLength: [t("pages.dealerApplication.form.formItems.4.validation.minLength"), 15]
											}}
											popLabel />
									</div>
									<div className="grid-col x6 m-x12">
										<FormInput
											name="phone"
											disabled={this.state.submitting}
											placeholder={t("pages.dealerApplication.form.formItems.5.placeHolder")}
											mask="(199) 999 99 99"
											validation={{
												required:t("pages.dealerApplication.form.formItems.5.validation.required"),
												minLength: [t("pages.dealerApplication.form.formItems.5.validation.minLength"), 15]
											}}
											popLabel />
									</div>
								</div>

								<div className="grid-row">
									<div className="grid-col x12">
										<FormInput
											disabled={this.state.submitting}
											placeholder={t("pages.dealerApplication.form.formItems.6.placeHolder")}
											validation={{
												required:t("pages.dealerApplication.form.formItems.6.validation.required"),
												email: true
											}}
											name="email"
											type="text" />
									</div>
								</div>
								<div className="grid-row">
									<div className="grid-col x12">
										<FormInput
											type="textarea"
											name="address"
											placeholder={t("pages.dealerApplication.form.formItems.7.placeHolder")}
											validation={t("pages.dealerApplication.form.formItems.7.validation.required")}
											popLabel />
									</div>
								</div>
								{i18next.resolvedLanguage ==='tr' && 	<div className="grid-row">
									<div className={"grid-col x6 m-x12 inputwrap" + ((!this.state.selectedCity && this.state.touched) ? ' error' : '')}>
										<FormInput
											className="high city"
											type="select"
											name="city"
											placeholder={t("pages.dealerApplication.form.selectBox.0.placeHolder")}
											options={(this.state.cities ? this.state.cities : undefined)}
											disabled={!this.state.cities}
											onChange={this.changeCity}
											value={null}
											popLabel />
										{(!this.state.selectedCity && this.state.touched) &&
											<div className="input-error">{t("pages.dealerApplication.form.selectBox.0.text")}.</div>
										}
									</div>
									<div className={"grid-col x6 m-x12 inputwrap" + ((!this.state.selectedDistrict && this.state.touched) ? ' error' : '')}>
										<FormInput
											className="high district"
											type="select"
											name="district"
											placeholder={t("pages.dealerApplication.form.selectBox.1.placeHolder")}
											options={(this.state.districts ? this.state.districts : undefined)}
											disabled={!this.state.districts}
											onChange={this.changeDistrict}
											value={null}
											popLabel />
										{(!this.state.selectedDistrict && this.state.touched) &&
											<div className="input-error">{t("pages.dealerApplication.form.selectBox.1.text")}.</div>
										}
									</div>
								</div>  }

								<div className="grid-row">
									<div className="grid-col x12">
										<FormInput
											type="textarea"
											name="note"
											placeholder={t("pages.dealerApplication.form.selectBox.2.placeHolder")}
											required={false}
											popLabel />
									</div>
								</div>
								<div className="grid-row">
									<div className={"grid-col x6 m-x12 inputwrap" + ((!this.state.selectedBrands.length && this.state.touched) ? ' error' : '')}>
										<FormInput
											className="high brands"
											type="select"
											name="brand-selector"
											placeholder={t("pages.dealerApplication.form.selectBox.3.placeHolder")}
											options={(this.state.availableBrands ? this.state.availableBrands : undefined)}
											disabled={!this.state.availableBrands}
											value={null}
											onChange={this.addBrand}
											popLabel />
										{(!this.state.selectedBrands.length && this.state.touched) &&
											<div className="input-error">{t("pages.dealerApplication.form.selectBox.3.text")}.</div>
										}
									</div>
								</div>
								<div className="grid-row">
									<div className="grid-col x12 form-opts">
										<div className="opts-inner">
											{this.state.selectedBrands.map((brand, nth) => (
												<span className="opts-item" key={nth}>
													<input type="hidden" name="brands[]" value={brand.value} />
													{brand.label}
													<button type="button" onClick={() => { this.removeBrand(brand.value) }}><i className="icon-close"></i></button>
												</span>
											))}
										</div>
									</div>
								</div>
								<div className="grid-row">
									<div className="grid-col x12" >
										<FormInput
											type="checkbox"
											className="form-agreement"
											name="agreement"
											value={this.state.agreementSelected}
											validation={t("pages.dealerApplication.form.formItems.8.validation")}
											onChange={this.agreementChanged}>
											{t("pages.dealerApplication.form.formItems.8.texts.0")}<button type="button" className="check-link" onClick={() => { openModal('text', { content: t(agreement), title: i18next.t("pages.dealerApplication.title") }) }}>
											&nbsp;{t("pages.dealerApplication.form.formItems.8.texts.1")} </button>	{t("pages.dealerApplication.form.formItems.8.texts.2")}
												</FormInput>
									</div>
								</div>
								<div className="grid-row">
									<div className="grid-col x12 center">
										<Btn
											type="submit"
											uppercase
											block
											disabled={this.state.submitting}
											status={this.state.submitting && 'loading'}
											onClick={() => { this.setState({ touched: true }) }}
											className="form-submitbtn">
											{t("pages.dealerApplication.form.button")}
											</Btn>
									</div>
								</div>
							</InputForm>
						</div>
					</div>
				</section>
			</main>

		)
	}
}
export default (withTranslation()(Faq))
