import React from 'react'

// Sections
import Listing2 from 'components/sections/listing2'
import ListingFilters from 'components/sections/listing-filters.js'

// Partials
import Loader from 'components/partials/loader'
import Image from 'components/partials/image'

import Link from 'components/partials/link'
import FavBtn from 'components/partials/favbtn'
import Responsive from 'components/partials/responsive'

// Deps
import debounce from 'lodash/debounce'
import extend from 'lodash/extend'
import isEqual from 'lodash/isEqual'
import { setTitle, setDescription } from 'controllers/head'
import request from 'controllers/request'
import { redirect } from 'controllers/navigator'

import { formatNumber } from 'functions/helpers'
import Breadcrumbs from "../components/partials/breadcrumbs";
import {set404} from "../controllers/navigator";
import axios from "axios";
// Multi language
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import "../i18n";
import i18next from "i18next";

// Assets

class Brand extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            brandData: false,
            loading: true,
            searchText: '',
            listingQuery: false,
            listingData: false,
            branchNames:[],
            finalNames:[],
            filters:{}
        }

        this.initialize = this.initialize.bind(this);
        this.changeSearch = this.changeSearch.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.setAnyFilter = this.setAnyFilter.bind(this);
        this.updateSearch = debounce(this.updateSearch.bind(this), 50);
    }

    componentDidMount() {

        this.initialize();
        this.getFilters();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevState.brandData, this.state.brandData) || prevState.searchText !== this.state.searchText) {
            this.updateSearch();
        }

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.initialize();
            window.scroll(0, 0);
        }
    }

    initialize() {
        let vm = this;
        //request.get('/dummy/data/brand.json', { id: vm.props.match.params.id }, function (payload) {
        request.get(`brands/${vm.props.match.params.id}?language=` + i18next.resolvedLanguage, {}, function (payload) {
            if(payload.status !== '404'){
                if (payload) {

                    vm.setState({
                        brandData: payload
                    })

                    setTitle(`${payload.title} ${i18next.t("pages.brand.titles.0")} ${payload.title}  ${i18next.t("pages.brand.titles.1")} `);
                    setDescription(` ${i18next.t("pages.brand.descriptions.0")} ${payload.title}  ${i18next.t("pages.brand.descriptions.1")} ${payload.title}   ${i18next.t("pages.brand.descriptions.2")}`);
                }
                else {
                    redirect("notfound");
                }
            }
            else{
                set404()
            }
        }, { excludeApiPath: false })
    }

    changeSearch(e) {
        this.setState({ searchText: e });
    }

    updateSearch() {
        if (this.state.brandData) {
            let query = { dealer: this.state.brandData.id };

            if (this.state.searchText !== '') {
                query.search = this.state.searchText;
            }

            this.setState({ listingQuery: query });
        }
    }

    updateFilters(newQuery) {
        newQuery = extend({}, newQuery, {
            id: this.state.brandData.id,
        });

        this.setState({
            listingQuery: newQuery
        })
    }

    async getFilters(){
        const baseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://api.minoto.com"
        const query = this.props.location.search
        try{
            const response = await axios.get(`${baseUrl}/v1/shared/item-filters?marka=${this.props.match.params.id}&${query && query.slice(1)}&language=` +i18next.resolvedLanguage )
            const data = await response.data
            this.setState({filters:data})
        }
        catch(err){
            console.log(err)
        }
    }

    setAnyFilter = (filterObject) => {
        let filterString = '';
        Object.keys(filterObject).forEach(function (key, index) {
            if (index === 0) {
                filterString += `?${key}=${filterObject[key]}`
            } else {
                filterString += `&${key}=${filterObject[key]}`
            }
        });
        window.location.replace(window.location.pathname+filterString);
        //i18next.resolvedLanguage === "tr" ?   window.location.href = window.location.pathname + filterString :  this.props.history.push(window.location.pathname.substring(3)+filterString);
        //window.location.href = `/${window.location.href}` + filterString ;
        return null;
    }

    render() {
        const {t} = this.props;
        let vm = this;
        let brand = vm.state.brandData;

        return (
            <main className="page brand">
                <Loader loading={brand === false} strict={true} />
                <div className="wrapper">
                    {brand &&
                        <section className="section brand-detail">
                            <aside className="detail-info">
                                <div className="info-sum">
                                    <FavBtn className="sum-favbtn" faved={brand.favorited} type="brand" id={brand.id} />
                                    <Image className="sum-logo" bg title={brand.title} src={brand.logo} />

                                    <h1 className="sum-title">{brand.title}</h1>

                                    <div className="sum-numbers">
                                        <span
                                            className="numbers-item">{formatNumber(brand.dealerCount)} {t("pages.brand.options.0")}</span>
                                        <span
                                            className="numbers-item">{formatNumber(brand.listingCount)} {t("pages.brand.options.1")}</span>
                                    </div>

                                    {/*{brand.priceListYear &&
								<Btn className="sum-pricelist" low wide tag="a" href={`/${brand.priceListYear}-${brand.slug}-fiyat-listesi`} >{brand.priceListYear} Fiyat Listesi</Btn>
								}*/}

                                </div>

                                {/*<InputForm className="info-search">
									<FormInput placeholder="Marka Ara" value={vm.state.searchText} onChange={vm.changeSearch} />
									<button type="submit" className="search-submit"><i className="icon-search"></i></button>
								</InputForm>*/}

                                <div className="info-dealers">
                                    <h2 className="dealers-title">{t("pages.brand.title")}</h2>
                                    <ul className="dealers-list">
                                        {
                                            brand.dealers.map((dealer) => (
                                                <li className="list-branch" key={dealer.id}>
                                                    <Link
                                                        href={i18next.t(`routes:dealers`, { lng: i18next.resolvedLanguage })+`/${dealer.slug}`}>
                                                        {dealer.title}
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                </div>

                                {(vm.state.filters) &&
                                    <ListingFilters listing2Data={vm.state.filters} setAnyFilter={this.setAnyFilter} loading={!this.listingQuery} showMoreBrands={true} />
                                }
                            </aside>

                            <div className="detail-right">

                                {window.location.pathname.length < 30 &&
                                    <React.Fragment>
                                        <Responsive type="only-web">
                                            <Image className="brand-cover" bg src={brand.coverImage}/>
                                        </Responsive>

                                        <Breadcrumbs className="top-breadcrumbs" data={[
                                            {
                                                "href":  i18next.t(`routes:/`, { lng: i18next.resolvedLanguage }),
                                                "title": t("pages.brand.breadCrumbTitle.0")
                                            },
                                            {
                                                "href":  i18next.t(`routes:brands`, { lng: i18next.resolvedLanguage }),
                                                "title": t("pages.brand.breadCrumbTitle.1")
                                            },
                                            {
                                                "href": brand.slug,
                                                "title": brand.title
                                            },
                                        ]}/>
                                    </React.Fragment>

                                }



                                {vm.state.listingQuery &&
                                    <Listing2
                                        className="brand-listing"
                                        //urlBinding={false}
                                        filters={false}
                                        search={this.props.location.search}
                                        source={`filters/${ this.props.match.params.slug ? this.props.match.params.id+'&model='+this.props.match.params.slug : this.props.match.params.id}`}
                                        showAds={false}
                                        key="search-brand"
                                        title={`${brand.title} Modelleri`}
                                    />
                                }
                            </div>
                        </section>
                    }
                </div>
            </main>

        )
    }
}
export default withRouter(withTranslation()(Brand))
