import React from 'react'

// Sections
import Listing from 'components/sections/listing.js'

// Partials
//import Link from 'components/partials/link'

// Assets
import axios from "axios";

//Multi language
import { withTranslation } from 'react-i18next';
import "../i18n";
import i18next from "i18next";

class Brands extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			brands:{},
			filterString:'',
		}
		this.getBrands = this.getBrands.bind(this);
		this.setAnyFilter = this.setAnyFilter.bind(this);
	}

	async getBrands(filterStringVar){
		const endpoint = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "https://api.minoto.com"
		let url = '';

		if(filterStringVar){
			url=endpoint+`/v1/shared/brands?siralama=date_desc&sayfa=1&pageOrder=first${filterStringVar}&language=` + i18next.resolvedLanguage
		}else{
			url=endpoint+`/v1/shared/brands?siralama=date_desc&sayfa=1&pageOrder=first&language=` + i18next.resolvedLanguage
		}

		const response = await axios.get(url)
		const data = await response.data.payload
		this.setState({
			brands:data
		})
	}

	setAnyFilter(a){
		let filterStringVar = '';
		if(Object.keys(a).length === 0){
			this.setState({
				filterString:''
			})
			return;
		}else{
			for(let key in a){
				filterStringVar+=`&${key}=${a[key]}`
			}
			this.setState({
				filterString:filterStringVar
			})
		}
		this.getBrands(filterStringVar);
	}

	componentDidMount() {
		//console.log('mounted isteği')
		//this.getBrands()
	}


	render() {


		return (
			<main className="page brands">
				<h1 className="seoElement">{i18next.t("pages.brands.title")}</h1>
				<div className="wrapper">
					<Listing
						className="section brands-listing"
						//listingData={this.state.brands}
						//setAnyFilter={this.setAnyFilter}
						showAds={false}
						topSection={false}
						source={'brands'}
						key="search-brands"
						title={i18next.t("pages.brands.title")}/>
				</div>
			</main>

		)
	}
}
export default (withTranslation()(Brands))
