//export const languages = Object.entries(resources).map(([lang]) => lang)
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import trRoutes from './locales/tr/routes.json'
import deRoutes from './locales/de/routes.json'

import trTranslation from "./locales/tr/translition.json"
import deTranslation from "./locales/de/translition.json"

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';


const resources = {
    tr: {
        routes:trRoutes,
        translation:trTranslation
    },
    de: {
        routes:deRoutes,
        translation:deTranslation
    }
}


i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        whitelist: ['tr', 'de'],
        fallbackLng: 'tr',
        resources,
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
            checkWhitelist: true
        },
    });


export default i18n;